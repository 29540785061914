﻿import { NgModule }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { CurrencyPipe } from '@angular/common';

// used to create fake backend
import { fakeBackendProvider } from './_helpers/index';
import { MockBackend, MockConnection } from '@angular/http/testing';
import { BaseRequestOptions } from '@angular/http';
import { CookieService } from 'ngx-cookie-service';

import { AppComponent }  from './app.component';
import { routing }        from './app.routing';

import { ProgressComponent } from './_components/index';
import { AlertComponent, EqualValidator, HostnameValidator, HostIdValidator, ExpirationValidator, ExternalEmailValidator, DndDirective } from './_directives/index';
import { AuthGuard, WizardAuthGuard } from './_guards/index';
import { AlertService, PortalService, UserService, ElectronIPC, EventBusService } from './_services/index';
import { HomeComponent, PortalComponent, ManageComputersComponent, ManageLicensesComponent,
         RunApplicationsComponent, DownloadComponent, ActivateHostComponent } from './portal/index';
import { LoginComponent, UnsupportedBrowserComponent } from './login/index';
import { RegisterComponent, ActivateAccountComponent, AwaitActivationComponent,
         ForgotPasswordComponent, ChangePasswordComponent } from './register/index';
import { WebsiteComponent } from './website/index';
import { BreadcrumbComponent } from './portal/breadcrumb.component';
import { SupportFormComponent, SupportFormEditorComponent } from './support_form/index';
import { WizardPreWelcome, WizardWelcome, WizardShowAccounts, WizardShowLicenses, WizardActivate, WizardComplete, WizardLogin, WizardRegister, WizardForgotPassword, WizardLicenseAgreement, WizardActivateAccount, WizardAwaitActivation } from './wizard/index';

import { ResourcesComponent } from './resources/index';
import { DemoComponent } from './demo/index';
import { CloudLicenseAdminComponent } from './cloudLicenseAdmin/index';
import { ConfirmDialogModule } from './confirm-dialog/confirm-dialog.module';

import { OrderFormComponent } from './order_form/index';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        HttpModule,
        HttpClientModule,
        NgbModule,
        ConfirmDialogModule,
        routing
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        DndDirective,
        ProgressComponent,
        HomeComponent,
        LoginComponent,
        RegisterComponent,
        ActivateAccountComponent,
        AwaitActivationComponent,
        ForgotPasswordComponent,
        ChangePasswordComponent,
        WebsiteComponent,
        HostnameValidator,
        HostIdValidator,
        ExpirationValidator,
        EqualValidator,
        ExternalEmailValidator,
        BreadcrumbComponent,
        HomeComponent,
        PortalComponent,
        ManageComputersComponent,
        ManageLicensesComponent,
        RunApplicationsComponent,
        DownloadComponent,
        UnsupportedBrowserComponent,

        ResourcesComponent,

        DemoComponent,

        CloudLicenseAdminComponent,

        SupportFormComponent,
        SupportFormEditorComponent,

        OrderFormComponent,

        ActivateHostComponent,
		WizardPreWelcome,
		WizardWelcome,
		WizardShowAccounts,
		WizardShowLicenses,
		WizardActivate,
		WizardComplete,
		WizardLogin,
		WizardRegister,
		WizardForgotPassword,
		WizardLicenseAgreement,
		WizardActivateAccount,
		WizardAwaitActivation
    ],
    providers: [
        AuthGuard,
        AlertService,
        PortalService,
        CookieService,
        UserService,
        CurrencyPipe,
        ElectronIPC,
        EventBusService,
		    WizardAuthGuard

        // providers used to create fake backend
        //fakeBackendProvider,
        //MockBackend,
        //BaseRequestOptions

    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
