import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export class EventData {
    type: string;
    value: any;
    constructor(type:string, value:any) {
        this.type = type;
        this.value = value;
    }
}

@Injectable()
export class EventBusService {
    private bus:Subject<EventData> = new Subject<EventData>();

    constructor() { }

    emit(event:EventData) {
        this.bus.next(event);
    }

    on(type:string, action:any):Subscription {
        return this.bus.pipe(
            filter( (e: EventData) => e.type === type),
            map( (e: EventData) => e["value"])).subscribe(action);
    }
}
