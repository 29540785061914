﻿import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';
@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('currentUser')) {
            // logged in so return true
            var currentUser = JSON.parse(localStorage.getItem('currentUser'));
            var signedInAs = document.getElementById("signed_in_as");
            if ( signedInAs ) {
              // because it might be removed if we're in an iframe
              signedInAs.style.display='block';
            }
            var username = document.getElementById("signed_in_username");
            if ( username != undefined ) {
              username.innerHTML = currentUser.email;
              if ( currentUser.accountInfo && currentUser.accountInfo.account && currentUser.accountInfo.account.name ) {
                if ( currentUser.accountInfo.account.name != 'GO-GLOBAL TRIALS')
                {
                  username.innerHTML += '<br/>' + currentUser.accountInfo.account.name;
                }
              }
            }
            if ( document.getElementById('signOut') == undefined ) {
              var signInLink = document.createElement("a");
              signInLink.setAttribute('href', environment.gatewayUrl + '/signin');
              signInLink.setAttribute('class', 'cmsmasters_button header_button');
              signInLink.appendChild(document.createTextNode("SIGN OUT"));
              var signInButton = document.createElement("div");
              signInButton.setAttribute('id', 'signOut');
              signInButton.appendChild(signInLink);
              var signInDiv = document.getElementById("sign_in_section");
              if ( signInDiv ) {
                signInDiv.appendChild(signInButton);
              }
            }
            return true;
        }

        // not logged in so redirect to login page with the return url
        console.log("not logged in, redirecting to signin");
        this.router.navigate(['/signin'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
