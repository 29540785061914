import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { AlertService, PortalService, ElectronIPC } from '../_services/index';
import { TextDecoder } from 'text-encoding';

@Component({
    moduleId: module.id,
    templateUrl: 'wizardActivate.component.html'
})

export class WizardActivate {
    model: any = {};
    loading = false;
	licenseID: string;
	licenseName: string;
	hostName: string;
	hardwareID: string;
	EULAgreed = false;
	dialogWait = false;
	heading: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private portalService: PortalService,
        private alertService: AlertService,
        private ipc: ElectronIPC) {		
			this.heading = localStorage.getItem('currentHeading');
			this.licenseID = localStorage.getItem('currentLicense');
			this.licenseName = localStorage.getItem('currentLicenseName');
			this.hostName = localStorage.getItem('currentHost');
			this.hardwareID = localStorage.getItem('currentHardware');			
		}

    submitRequest() {	
		this.loading = true;	
		this.dialogWait = true;
		
		if (this.licenseID) {				
			this.portalService.activateProduct(this.licenseID)
				.subscribe(
					data => {
						console.log("activateProduct:register() got data = %o", data);
						if ( data != 0 ) {
							var configRoot = JSON.parse(localStorage.getItem('currentConfig'));					
							var host = configRoot.config.host;
							
							host.displayname = this.hostName;
							host.hostaddress = this.hostName;
							
							var cloudserver = {
								_id: data._smiAddress,
								smiaddress: data._smiAddress,
								smitransport: data._smiTransport,
								smiport: data._smiPort,
								hostid: data._id,
								uuid: data._uuid,
								license: this.licenseName};
								
							configRoot.config = {host, cloudservers: {cloudserver}, _version: "5.0"};
							
							var X2JS = require('../../node_modules/node-x2js/dist/X2JS.min.js');
							var x2js = new X2JS();		
							
							var xml = x2js.json2xml_str(configRoot);						
						
							this.ipc.on('writeConfigCallback', (function(event: any, message: string, error: any) {			
								if ((error) && (typeof error === 'object')) {	
									var errorMessage = "Error writing configuration file: ";
									
									if (error.syscall != undefined) {
										errorMessage += error.syscall + " ";
									}
									
									if (error.path != undefined) {
										errorMessage += error.path + " ";
									}
									
									if (error.code != undefined) {
										errorMessage += error.code + " ";
									}
									
									if (error.errno != undefined) {
										errorMessage += error.errno + " ";
									}						
									
									this.alertService.error(errorMessage);
									this.loading = false;
									this.dialogWait = false;
								}
								else {								
									console.log("writeConfigCallback: " + message);	
									this.dialogWait = false;
									this.router.navigate(['/wizard/complete']);
								}
							}).bind(this));	
													
							this.ipc.send('writeConfig', 'config.xml', xml);	
							this.loading = false;
							this.dialogWait = false;								
						}
					},
					error => {
						this.alertService.error(error);	
						this.loading = false;
						this.dialogWait = false;
					});
		}
    }
	
	goback() {
		this.router.navigate(['/wizard/licenseAgreement']);
	}
	
	closeapp() {
		this.portalService.logout();		
		this.ipc.send('closeApp');
	}
}
