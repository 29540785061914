<div class="wizard">	
	<div class="overlay" *ngIf="loading"></div>
	
	<div class='container' style="width: 100vw; padding: 5px; border-bottom: 1px solid #4472c4;">
		<div class='row'>
			<div class="col-xs-12" style="text-align: center">
				<a href="javascript:void(0)" (click)="goback()" class="backbutton navigation">&larr;</a>
				<img src="logo.png" width="150" />
				
			</div>
		</div>
	</div>
	
	<alert></alert>

	<div class="wrapper">
		<div class="container">
			<div class="row vertical-align">
				<div class="col-xs-12">			
					<h2>{{heading}}</h2>
					<h4>License Agreement</h4>
					<pre class="eula">
Please read the Terms and Conditions of this License Agreement ("Agreement") carefully before using the software ("Software") and related Documentation ("Documentation," and collectively with the Software, "Licensed Products.") This is a legal and enforceable contract between the Licensee and GraphOn Corporation ("GraphOn") for the Licensed Products. Licensee agrees that this Agreement is enforceable like any written negotiated agreement signed by licensee. 

"Licensee" refers to the collective of authorized employees, contractors, consultants, or users which comprise the end user entity, and acceptance of this license agreement constitutes acceptance of this agreement on behalf of the end user entity.

GraphOn is willing to license this Licensed Product to Licensee, only on the condition that Licensee accepts all of the Terms and Conditions of this Agreement. Licensee is authorized to download and install the relevant Software components on all computers which comprise the Licensee infrastructure. By downloading, installing, or otherwise using the Licensed Products, Licensee agrees to be bound by the Terms and Conditions of this Agreement. If Licensee does not agree to the Terms and Conditions of this Agreement, Licensee is not allowed to download, install, copy, and otherwise use the Licensed Products. 

The Licensed Products are protected by Copyrights and other Intellectual Property Rights. This Agreement is a License Agreement and not an agreement for sale or for transfer of title. Title to the Licensed Products and all related intellectual property rights is and shall remain at all times with GraphOn. 

This Agreement is the entire agreement between GraphOn and Licensee regarding the Licensed Products and supersedes all prior oral or written proposals, agreements, or communications regarding its subject matter.

1.  License Grant:  Subject to the terms, covenants, conditions, limitation and terms contained in this Agreement, GraphOn hereby grants to Licensee a non-exclusive, non-transferable, limited right and license ("License") to use the downloaded Software only in machine-readable object code form. GraphOn also grants to Licensee a non-exclusive, personal, non-transferable, limited right and license, to use the Documentation in any form that GraphOn may deliver to Licensee based on this Agreement in connection with Licensee's use of the Licensed Products in accordance with this Agreement, all on the terms and conditions contained herein.

2.  Restrictions: This license does not include use of the source code for the Licensed Products. Licensee agrees not to assign, sell, sublicense, lease, transfer, pledge, rent or share Licensee's rights under this Agreement. Licensee agrees not to modify, adapt, translate, create derivative works, disassemble, decompile or otherwise reverse engineer or attempt to discover the source code of the Licensed Products. All rights not specifically granted to the Licensee are expressly reserved for GraphOn.

3.  Proprietary Rights:  Licensee acknowledges that the Licensed Products constitute and incorporate confidential and proprietary information and trade secrets of GraphOn and its suppliers. Licensee agrees to safeguard the confidentiality thereof, and not to use the Licensed Products other than as permitted under this Agreement, nor to publish, disclose, or provide access to the Licensed Products to any person outside the Licensee end user entity, who have agreed to be bound by these confidentiality obligations. Licensee further agrees to keep confidential and protect from unauthorized disclosure all other information that GraphOn may designate as confidential or proprietary. Licensee may not remove any copyright, trade secret, or other intellectual property notices on any Licensed Products, reports, or copies thereof.

4.  Audit Rights: GraphOn reserves the right to audit the compliance of Licensee's use of the Licensed Products with the terms of this Agreement. Licensee agrees to cooperate with any audit request and provide GraphOn with all reasonable assistance and access to information.

5.  Warranty Disclaimer: GraphOn does not warrant that the Licensed Products will meet Licensee's requirements or that operation of the Licensed Products will be uninterrupted or that the Licensed Products will be error-free. THE LICENSED PRODUCTS ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND. ALL EXPRESS OR IMPLIED REPRESENTATIONS AND WARRANTIES, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE, OR NON-INFRINGEMENT ARE HEREBY EXCLUDED. 

6.  Limitation of Liability: Licensee bears the entire risk as to the quality and performance of the Licensed Products. GraphOn's cumulative liability to Licensee or any other party for any loss or damages resulting from any claims, demands, or actions arising out of or relating to this Agreement, shall not exceed the lesser of (i) GraphOn's list price for the Licensed Products as of the date of Licensee's purchase, and (ii) the License Fee. IN NO EVENT WILL GRAPHON OR ITS RESELLERS BE LIABLE IN CONTRACT, TORT OR OTHERWISE, FOR ANY CONSEQUENTIAL, INDIRECT, INCIDENTAL, EXEMPLARY, PUNITIVE OR SPECIAL DAMAGES, INCLUDING ANY LOST PROFITS, LOST DATA OR LOST SAVINGS, EVEN IF A GRAPHON REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. Some U.S. states do not allow the limitation or exclusion of liability for incidental or consequential damages, so the above limitation or exclusion may not apply to Licensee.

7.  Indemnification: Licensee agrees to hold GraphOn and its successors, assigns, officers, directors, representatives, employees and agents, harmless from and against any claim, suit, loss, liability, penalty or damage (including  incidental and consequential damages), costs and expenses (including reasonable attorneys' fees and expenses), arising out of Licensee's breach of this Agreement.

8.  Termination: This Agreement is effective upon acceptance and shall continue until terminated. This Agreement will terminate immediately with notice from GraphOn if Licensee fails to comply with any provision of this Agreement.�Upon termination of this Agreement for compliance, the License will be disabled and become inoperable and Licensee must cease using the Licensed Product immediately and uninstall Licensed Product from all computers and/or virtual host locations.�GraphOn, at its sole discretion, for any reason, may terminate the Agreement at any time with minimum 30 days' notice to Licensee. All provisions of this Agreement which must survive in order to give effect to its meaning will survive termination of this Agreement.

9. Export Regulations: Licensee shall comply with all applicable laws and regulations of the United States and/or any foreign countries with respect to the use of the Licensed Products outside of the United States, including without limitation import and export restrictions, obtaining any necessary consents and licenses and registering or filing any document. Licensee hereby certifies that Licensee will not knowingly transfer, export, re-export, transship, or use, directly or indirectly, the Licensed Products or related information or the direct product of such data, media, or products in violation of United States laws and regulations (including without limitation, the U.S. Export Administration Regulations), unless prior written authorization is obtained from GraphOn and the appropriate United States government agency. None of the Licensed Products may be downloaded or otherwise exported or re-exported (i) into (or to a national or resident of) any country to which the U.S. has embargoed goods; or (ii) to anyone on the U.S. Treasury Department's list of Specially Designated Nationals or the U.S. Commerce Department's Table of Denial Orders. By downloading or using the Licensed Products, Licensee is agreeing to the foregoing and Licensee is representing and warranting that Licensee is not located in, under the control of, or a national or resident of any such country or on any such list.

10. U.S. Government Restricted Rights: Use, duplication, or disclosure by the Government is subject to restrictions set forth in subparagraphs (a) through (d) of the Commercial Computer-Restricted Rights clause at FAR 52.227-19 when applicable, or in sub-paragraph (c) (1) (ii) of the Rights in Technical Data and Computer Software clause at DFARS 252.227-7013, and in similar clauses in the NASA FAR Supplement. Contractor/manufacturer is GraphOn Corporation, 6 Loudon Road, Suite 200, Concord NH 03301, USA. 

11. Governing Law: This Agreement shall be governed by the laws of the State of New Hampshire, USA, excluding the application of its conflicts of law rules. 

12. No Assignment: This Agreement shall bind and inure to the benefits of the parties hereto and their respective heirs, successors and permitted assigns. Licensee may not assign this Agreement or any of the rights or obligations created hereunder without the express prior written consent of GraphOn. Nothing in this Agreement is intended to or shall confer any rights or remedies on any person other than the parties hereto, their respective successors and assigns. 

13. Specific Performance: Because a breach of the provisions of this Agreement (including without limitation unauthorized use of the License Products or breach of the confidentiality provision) would cause irreparable harm and significant injury which would be difficult to ascertain and which would not be compensable by damages alone, Licensee agrees that GraphOn has the right to enforce those provisions by injunction (without the necessity of posting bond), specific performance or other equitable relief without prejudice to any other rights or remedies GraphOn may have.

14. Notice: Any notice, request or other communication required or permitted to be delivered hereunder shall be in writing, and shall be deemed effective (a) as of the date it is delivered to the party to be charged thereby by hand, or (b) one (1) business day after it is delivered to that party by a reputable overnight courier and properly addressed to that party.

15. Miscellaneous: This Agreement may not be modified except by in writing, executed by GraphOn and Licensee.  If any provision is or becomes, at any time or for any reason, unenforceable or invalid, the remaining provisions shall continue with the same effect as if such unenforceable or invalid provisions was not inserted herein, provided that the ability of either party to obtain substantially the bargained-for performance of the other shall not have thereby been impaired.  

16.  Terms and Conditions of a Perpetual License Purchase
License Fee. In consideration for the licenses granted under this Agreement, Licensee shall pay to GraphOn or its authorized resellers the respective license fee ("License Fee") for the Licensed Products. Unless otherwise agreed in writing, License Fee includes one (1) year of Support, which includes, technical support, license support, and software maintenance.�Bank fees and credit card fees may not be deducted from the payment. Unless otherwise agreed, Licensee shall be responsible for all applicable taxes (withholding tax, sales tax, services tax, value-added tax (VAT), goods and services tax (GST), etc.), or duties imposed by any government entity or collecting agent.

Refunds. All Licensed Product purchases are final and non-refundable. 

Licensee Information. Licensee must provide the following information, either directly to GraphOn, or through an authorized GraphOn reseller partner: Company Name (Licensee entity), Company address, Company country location, Company website URL, Key contact name, and Key contact email address.

Perpetual License Files. Licensee will receive a Product Code to activate a perpetual license key.  License keys identify the number of seats, version of Licensed Product, host name, and host ID, and are node-locked to a host computer. License files can be installed on only one (1) computer hard drive or virtual machine host instance on a host computer ('License Location'). License files cannot be duplicated and installed in more than one location whether it be a hard drive or virtual host instance. License files cannot be duplicated and installed on multiple instances of virtual host machine identified with the same MAC address. Violation of this condition, at GraphOn's discretion, may result in VOIDING the license, rendering it inoperable.  

Rehosts. Upon request, licenses on Support can be rehosted (moved from one host instance to another) a maximum of 4 times per 12 month period. Licensee agrees to remove the original license file(s) from use, and to delete them. Additional rehosts may be granted, at GraphOn's discretion, and may include a fee up to 25% of the original license price.

Upgrades. Upon request, licenses on Support can be upgraded to support new releases of GO-Global. Licensee agrees to remove the original license file(s) from use, and to delete them.

Multiple Licenses. Multiple license keys installed and in active use on the same computer or virtual instance must all be on Support in order to obtain technical support services, or GraphOn, at its discretion, may deny technical support services. Licensee can optionally authorize GraphOn to disable the Support expired license keys, rendering them inoperable. Disabled licenses will not be re-enabled for use until a Support renewal is completed. 

Support Renewal. The initial term of (1) year of Support is included, beginning on the date the Product Code is generated. Support is optional in subsequent years, but must be purchased annually so long as Support is desired. Renewal of Support must be completed prior to the Support expiration anniversary. License renewals after the support expiration anniversary will be need to renew retroactive to expiration, one full year forward, and will be subject to reactivation and late fees. Continuation of Support can be obtained by renewing through GraphOn or an authorized GraphOn partner.  

17. Support Contract. The most recent version of the Support Contract is available in the GraphOn customer portal at GraphOn.com. GraphOn reserves the right to update and change the Support Contract, at any time and without notice. Unless otherwise agreed, first line technical support and license support for licenses purchased through an authorized GraphOn partner will be provided by the GraphOn partner.

18. The most recent version of this Agreement is available in the GraphOn customer portal at GraphOn.com. GraphOn reserves the right to update and change, at any time and without notice, this License Agreement and all documents incorporated by reference.
					</pre>					
                    <form name="form" (ngSubmit)="f.form.valid && submitRequest()" #f="ngForm" novalidate>
						<div class="form-group text-right buttons">	
							<button [disabled]="loading" class="btn btn-primary">Accept</button>
							<a href="javascript:void(0)" (click)="closeapp()" class="btn btn-link">Cancel</a>
						</div>
                    </form>
				</div>     
			</div>
		</div>
	</div>
</div>

