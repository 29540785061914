﻿import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';

import { User } from '../_models/index';

@Injectable()
export class UserService {
    private port: number;

    constructor(private http: Http) { 
        //this.port = 3000;
        this.port = 3005;
    }

    getAll() {
        return this.http.get(window.location.protocol + '//' + 
                             window.location.hostname + ':' + this.port + '/users', this.jwt()).map((response: Response) => response.json());
    }

    getById(id: number) {
        return this.http.get(window.location.protocol + '//' + 
                             window.location.hostname + ':' + this.port + '/users/' + id, this.jwt()).map((response: Response) => response.json());
    }

    create(user: User) {
        return this.http.post(window.location.protocol + '//' + 
                              window.location.hostname + ':' + this.port + '/users', user, this.jwt()).map((response: Response) => response.json());
    }

    update(user: User) {
        return this.http.put(window.location.protocol + '//' + 
                             window.location.hostname + ':' + this.port + '/users/' + user.id, user, this.jwt()).map((response: Response) => response.json());
    }

    delete(id: number) {
        return this.http.delete(window.location.protocol + '//' + 
                                window.location.hostname + ':' + this.port + '/users/' + id, this.jwt()).map((response: Response) => response.json());
    }

    // private helper methods

    private jwt() {
        // create authorization header with jwt token
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
            let headers = new Headers({ 'Authorization': 'Bearer ' + currentUser.token });
            return new RequestOptions({ headers: headers });
        }
    }
}