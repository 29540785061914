<style scoped>
  b.red { color: #ff0000; }
</style>

<div class="menu">
  <div class="second-row" style="flex-grow: unset; width: 100%; padding-right: 0;">
    <h2 style="margin-bottom: 0;">On-Premises Trial License Request</h2>
    <div *ngIf="loading == true">
      <div style="position: absolute; top: 0; left: 0; width: 100%; height:100%; background: #000000; opacity: 0.8; z-index:9998;">
        <div style="position: absolute; top: 50%; left: 50%; margin: -50px 0px 0px -50px; opacity: 1.0;">
          <figure style="overflow: visible" id="spinner"><div class="spinner"></div></figure>
          <h2 style="color:#ffffff; margin: 0px 0px 0px 35px; font-family: 'Quicksand', Arial, Helvetica, 'Numbus Sans L', sans-serif;">PROCESSING...</h2>
        </div>
      </div>
    </div>
    <div *ngIf="model.lockedout==false && model.stage==1">
        <form class="form-inline" name="form" (ngSubmit)="f.form.valid && request_trial()" #f="ngForm" novalidate>
        <table class="form-table">
          <!-- <tr><td style="vertical-align: top;"><h3>Select type:</h3></td>
            <!- <div class="form-group" [ngClass]="{ 'has-error': f.submitted && licensetype.valid }"> ->
            <td style="vertical-align: top;">
              <div class="form-group">
                <div class="btn-group btn-group-toggle" ngbRadioGroup name="licensetype" [(ngModel)]="model.licensetype">
                  <label ngbButtonLabel class="btn-primary btn-type">
                    <input ngbButton type="radio" value="default"> DEFAULT REQUEST
                  </label>&nbsp;
                  <label ngbButtonLabel class="btn-primary btn-type">
                    <input ngbButton type="radio" value="custom"> CUSTOM REQUEST
                  </label>
                </div>
                <!- <div *ngIf="f.submitted && !licensetype.valid" class="help-block">License type is required.</div> ->
              </div>
            </td>
          </tr> -->
          <tr>
            <td>
              <b>Please note that all fields with an <b class="red">*</b> are required.</b>
            </td>
          </tr>
          <tr>
            <td>
              <div class="form-group">
                <div class="input-group">
                  Requested By:
                </div>
              </div>
            </td>
            <td>
              <div class="form-group" style="vertical-align: top;">
                <div class="input-group" style="vertical-align: top;">
                  <table>
                    <tr><td style="vertical-align: top;">
                      {{ currentUser.firstname }} {{ currentUser.lastName }} &lt;{{ currentUser.email }}&gt;<br/>
                      {{ model.accountName }}
                    </td><td style="vertical-align: top;">
                      <div style="margin-left: 25px;">
                        <b>Auto-approved Trial Limits</b><br/>
                        Users: {{ model.approvedusers }}<br/>
                        Days Per Trial: {{ model.approvedtotaldays }}<br/>
                        Days Per License: {{ model.approveddays }}
                      </div>
                    </td></tr>
                  </table>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              NOTE: To request changes to the trial limits above, please email a request to <a href="mailto:presales@graphon.com">presales@graphon.com</a>.
            </td>
          </tr>
          <tr *ngIf="deliverto.errors?.externalvalid">
            <td></td><td>
              <div class="form-group"><div class="input-group">
                <div class="help-block">
                  {{ deliverto.errors.externalvalid }}
                </div>
              </div></div>
            </td>
          </tr>
          <tr *ngIf="!deliverto.valid && (deliverto.dirty || deliverto.touched || f.submitted) && !deliverto.errors?.externalvalid">
            <td></td><td>
              <div class="form-group"><div class="input-group">
              <div class="help-block">
                To continue, please enter a valid e-mail address.
              </div>
              </div></div>
            </td>
          </tr>

          <tr *ngIf="model.validRecipient=='denied-sameuser'" class="help-block">
            <td></td><td>
              <div class="form-group"><div class="input-group">
              <div class="help-approval">
                This license request is to yourself and will require approval.
              </div>
              </div></div>
            </td>
          </tr>

          <tr *ngIf="model.validRecipient=='denied-sameaccount'">
            <td></td><td>
              <div class="form-group"><div class="input-group">
              <div class="help-approval">
                This user is in your company and will require approval.
              </div>
              </div></div>
            </td>
          </tr>

          <tr *ngIf="model.validRecipient=='denied-wrongchannel'">
            <td></td><td>
              <div class="form-group"><div class="input-group">
              <div class="help-approval">
                This user is not in your sales channel and will require approval.
              </div>
              </div></div>
            </td>
          </tr>

          <tr>
            <td class="label">End-User Contact<b class="red">*</b>:</td>
            <td>
              <div class="form-group">
                <div class="input-group">
                  <div style="width: 100%">
                    <input type="text" name="deliverto" class="form-control" style="width:455px;max-width:455px;" (blur)="validate_recipient(deliverto)" [(ngModel)]="model.deliverto"
                        #deliverto="ngModel" externalEmail autofocus required email placeholder="Enter End-User E-mail Address">
                  </div>
                  <div *ngIf="model.validRecipient == 'false'">
                    <table class="form-table">
                      <tr *ngIf="model.validRecipient == 'false' && (firstname.invalid || lastname.invalid || endusercompany.invalid || endusercountry.invalid)">
                        <td colspan="2">
                          <div class="form-group">
                            <div class="input-group">
                              <div class="help-block">
                                You have entered an unknown email address.  To continue, please enter the contact details for this person.
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="!firstname.valid && (firstname.dirty || firstname.touched || f.submitted)">
                        <td></td><td>
                          <div class="form-group"><div class="input-group">
                          <div *ngIf="firstname.errors.required" class="help-block">
                            To continue, please enter the first name.
                          </div></div></div>
                        </td>
                      </tr>
                      <tr>
                        <td class="label">First Name<b class="red">*</b>:</td>
                        <td>
                          <div class="form-group">
                            <div class="input-group">
                              <input style="width: 455px;max-width:455px;" type="text" name="firstname" class="form-control" [(ngModel)]="model.firstname" #firstname="ngModel" firstname required placeholder="Contact First Name">
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="!lastname.valid && (lastname.dirty || lastname.touched || f.submitted)">
                        <td></td><td>
                          <div class="form-group"><div class="input-group">
                          <div *ngIf="lastname.errors.required" class="help-block">
                            To continuue, please enter the last name.
                          </div></div></div>
                        </td>
                      </tr>
                      <tr>
                        <td class="label">Last Name<b class="red">*</b>:</td>
                        <td>
                          <div class="form-group">
                            <div class="input-group">
                              <input style="width: 455px;max-width:455px;" type="text" name="lastname" class="form-control" [(ngModel)]="model.lastname" #lastname="ngModel" required placeholder="Contact Last Name">
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="!endusercompany.valid && (endusercompany.dirty || endusercompany.touched || f.submitted)">
                        <td></td><td>
                          <div class="form-group"><div class="input-group">
                          <div *ngIf="endusercompany.errors.required" class="help-block">
                            To continue, please enter the End-User Company Name.
                          </div></div></div>
                        </td>
                      </tr>
                      <tr>
                        <td class="label">End User Company<b class="red">*</b>:</td>
                        <td>
                          <div class="form-group">
                            <div class="input-group">
                              <input style="width: 455px;max-width:455px;" type="text" name="endusercompany" class="form-control" [(ngModel)]="model.endusercompany" #endusercompany="ngModel" required placeholder="Contact End-User Company Name">
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="!endusercountry.valid && (endusercountry.dirty || endusercountry.touched || f.submitted)">
                        <td></td><td>
                          <div class="form-group"><div class="input-group">
                          <div *ngIf="endusercountry.errors.required" class="help-block">
                            To continue, please enter the End User Country.
                          </div></div></div>
                        </td>
                      </tr>
                      <tr>
                        <td class="label">Country<b class="red">*</b>:</td>
                        <td>
                          <div class="form-group">
                            <div class="input-group">
                              <select style="width: 455px;" class="form-control" name="endusercountry" #endusercountry="ngModel" [(ngModel)]="model.endusercountry" required>
                                <option *ngFor="let v of countries" [value]="v.id">{{v.name}}</option>
                              </select>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div *ngIf="model.validRecipient && model.validRecipient != 'false'" style="width: 100%;">
                    <table class="form-table">
                      <tr>
                        <td class="label">First Name:</td>
                        <td>
                          <div class="form-group">
                            <div class="input-group">
                              <input style="width: 455px; max-width: 455px;" readonly type="text" class="form-control" value="{{ model.firstname }}" />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="label">Last Name:</td>
                        <td>
                          <div class="form-group">
                            <div class="input-group">
                              <input style="width: 455px; max-width: 455px;" readonly type="text" class="form-control" value="{{ model.lastname }}" />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="label">End User Company:</td>
                        <td>
                          <div class="form-group">
                            <div class="input-group">
                              <input style="width: 455px; max-width: 455px;" readonly type="text" class="form-control" value="{{ model.company }}" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="f.submitted && model.hostname==undefined">
            <td></td><td>
              <div class="form-group"><div class="input group"><div class="help-block">
                To continue, please enter a valid hostname.
              </div></div></div>
            </td>
          </tr>
          <tr *ngIf="!hostname.valid && (hostname.dirty || hostname.touched || f.submitted)">
            <td></td><td>
              <div class="form-group"><div class="input group"><div class="help-block">
                {{ hostname.errors.invalid }}
              </div></div></div>
            </td>
          </tr>
          <tr *ngIf="model.hostid != null && model.hostid.indexOf('+') > -1 && model.hostname != null && model.hostname.indexOf('+') == -1">
            <td></td><td>
              <div class="form-group"><div class="input group"><div class="help-block">
                Redundant host names must be specified if redundant host IDs are specified.
              </div></div></div>
            </td>
          </tr>
          <tr>
            <td class="label">License Hostname<b class="red">*</b>:</td>
            <td>
              <div class="form-group">
                <div class="input-group">
                  <input type="text" name="hostname" class="form-control" style="width:455px;max-width:455px;" [(ngModel)]="model.hostname" #hostname="ngModel" required
                  autocomplete="off"
                  hostname placeholder="Enter the Host Name of the trial computer">
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="f.submitted && model.hostid==undefined">
            <td></td><td>
              <div class="form-group"><div class="input group"><div class="help-block">
                To continue, please enter a valid host ID.
              </div></div></div>
            </td>
          </tr>
          <tr *ngIf="!hostid.valid && (hostid.dirty || hostid.touched || f.submitted)">
            <td></td><td>
              <div class="form-group"><div class="input group"><div class="help-block">
                {{ hostid.errors.invalid }}
              </div></div></div>
            </td>
          </tr>
          <tr *ngIf="model.hostname != null && model.hostname.indexOf('+') > -1 && model.hostid != null && model.hostid.indexOf('+') == -1">
            <td></td><td>
              <div class="form-group"><div class="input group">
              <div class="help-block">
                Redundant host IDs must be specified if redundant host names are specified.
              </div></div></div>
            </td>
          </tr>
          <tr *ngIf="model.hasPerm">
            <td></td><td>
              <div class="form-group">
                <div class="input-group">
                  <div class="help-approval">
                    An active permanent license is using this host ID and will require approval.
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="label">License Host ID<b class="red">*</b>:</td>
            <td>
              <div class="form-group">
                <div class="input-group">
                  <input type="text" name="hostid" class="form-control" style="width:455px;max-width:455px;"
                  [(ngModel)]="model.hostid" #hostid="ngModel" required autocomplete="off"
                  hostid placeholder="Enter the Host ID (MAC) of trial computer"
                  (keyup)="fix_hostid()" (blur)="validate_hostid_version()" />
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="!version.valid && (version.dirty || version.touched ||f.submitted)">
            <td></td><td>
              <div class="form-group"><div class="input group"><div class="help-block">
                To continue, please select a version.<br />
                {{ version.errors.invalid }}
              </div></div></div>
            </td>
          </tr>
          <tr>
            <td class="label">Requested Version<b class="red">*</b>:</td>
            <td>
              <div class="form-group">
                <div class="input-group">
                  <select style="width: 455px;" class="form-control" name="version" #version="ngModel" (blur)="validate_hostid_version()" [(ngModel)]="model.version" required>
                    <option *ngFor="let v of versions" [value]="v.id">{{v.name}}</option>
                  </select>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="model.licensetype=='default' && model.hostid!=undefined && model.version!=undefined && model.version!='' && +model.defaultDays==0">
            <td></td><td>
              <div class="form-group">
                <div class="input-group">
                  <div class="help-block" style="width: 100%">
                    The maximum remaining days for this trial is zero.<br/>
                    Use "Custom Trial" above for this request.
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="model.licensetype=='default' && model.hostid!=undefined && model.version!=undefined && model.version!='' && +model.defaultDays > 0 && +model.defaultDays < +model.accountDefaultDays">
            <td></td><td>
              <div class="form-group">
                <div class="input-group">
                  <div class="help-block" style="width: 100%">
                    The maximum remaining days for this trial is {{ model.defaultDays }}.<br />
                    For a longer trial period, choose "Custom Trial" above.
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="model.licensetype=='custom' && +model.expiration_days<=0">
            <td></td><td>
              <div class="form-group"><div class="input group"><div class="help-block">
                To continue, specify a date in the future.
              </div></div></div>
            </td>
          </tr>
          <tr *ngIf="model.licensetype=='custom' && +model.expiration_days%1!=0">
            <td></td><td>
              <div class="form-group"><div class="input-group">
                <div class="help-block">
                  To continue, please enter a whole number of days.
                </div>
              </div></div>
            </td>
          </tr>
          <!-- expiration_days = requested days, approveddays = transaction limit, allowedDays = remaining trial limit
          < !-- if requested days is greater than the license limit -->
          <tr *ngIf="model.licensetype=='custom' && +model.expiration_days > +model.approveddays && +model.expiration_days <= +model.allowedDays">
            <td></td><td>
              <div class="form-group"><div class="input-group">
              <div class="help-approval">
                Maximum individual license limit ({{ model.approveddays }} days) is exceeded and will require approval.
              </div>
              </div></div>
            </td>
          </tr>
          <!-- if requested days is greater than the remaining trial limit -->
          <tr *ngIf="model.licensetype=='custom' && +model.expiration_days > +model.allowedDays && +model.expiration_days <= +model.approveddays">
            <td></td><td>
              <div class="form-group"><div class="input-group">
              <div class="help-approval">
                Maximum trial limit ({{ model.allowedDays }} days remaining) is exceeded and will require appoval.
              </div>
              </div></div>
            </td>
          </tr>
          <!-- if requested days is greater than both -->
          <tr *ngIf="model.licensetype=='custom' && +model.expiration_days > +model.allowedDays && +model.expiration_days > +model.approveddays">
            <td></td><td>
              <div class="form-group"><div class="input-group">
              <div class="help-approval">
                Maximum individual license limit ({{ model.approveddays }} days) and trial limit ({{ model.allowedDays }} days remaining) are exceeded and will require approval.
              </div>
              </div></div>
            </td>
          </tr>
          <tr>
            <td *ngIf="model.licensetype=='custom'" class="label">Requested Expiration<b class="red">*</b>:<br/>(enter calendar date<br/>OR days requested)</td>
            <td *ngIf="model.licensetype=='default'" class="label">Expiration:</td>
            <td *ngIf="model.licensetype=='default'">
              <div class="form-group">
                <div class="input-group">
                  <input name="expiration-readonly" class="form-control-plaintext" style="width:415px;max-width:415px;"
                  readonly value="{{ model.defaultDate }}">
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input name="expiration_days_readonly" class="form-control-plaintext" style="width: 215px; max-width:215px;"
                         readonly value="{{ model.defaultDays }}">
                  <span style="padding-left: 5px; padding-top: 10px;"> days</span>
                </div>
              </div>
            </td>
            <td *ngIf="model.licensetype=='custom'">
              <div class="form-group">
                <div class="input-group">
                  <input name="expiration" class="form-control" style="width: 415px;max-width:415px;" [firstDayOfWeek]="7"
                      [(ngModel)]="model.expiration" ngbDatepicker #expiration="ngbDatepicker"
                      required placeholder="YYYY-mm-dd" expirationLimit
                      value="" (ngModelChange)="update_expiration_days()" />
                  <div class="input-group-append" (click)="expiration.toggle()">
                    <button class="btn btn-outline-secondary btn-cal" type="button">
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input name="expiration_days" [(ngModel)]="model.expiration_days" #expiration_days
                         class="form-control" style="width: 215px; max-width:215px;" (ngModelChange)="update_expiration()"
                         value="{{ model.daysrequested }}" placeholder="Enter days">
                  <span style="padding-left: 5px; padding-top: 10px;"> days</span>
                  <input type="hidden" name="allowedDays" [(ngModel)]="model.allowedDays" #allowedDays value="{{ model.allowedDays }}">
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="model.licensetype=='custom' && +model.users%1!=0">
            <td></td><td>
              <div class="form-group"><div class="input-group"><div class="help-block">
                To continue, please enter an integer number of users.
              </div></div></div>
            </td>
          </tr>
          <tr *ngIf="model.licensetype=='custom' && model.users!='' && +model.users <= 0">
            <td></td><td>
              <div class="form-group"><div class="input-group"><div class="help-block">
                To continue, please enter a number of users.
              </div></div></div>
            </td>
          </tr>
          <tr *ngIf="model.licensetype!='default' && +model.users > +model.approvedusers">
            <td></td><td>
              <div class="form-group"><div class="input-group"><div class="help-approval">
                Maximum users ({{ model.approvedusers }}) is exceeded and will require approval.
              </div></div></div>
            </td>
          </tr>
          <tr *ngIf="model.licensetype=='custom' && (!users.valid) && (users.dirty || users.touched || f.submitted)">
            <td></td><td>
              <div class="form-group"><div class="input-group">
              <div class="help-block">
                To continue, please enter a number of users.
              </div>
              </div></div>
            </td>
          </tr>
          <tr>
            <td *ngIf="model.licensetype=='custom'" class="label">Requested Users<b class="red">*</b>:</td>
            <td *ngIf="model.licensetype=='default'" class="label">Users:</td>
            <td *ngIf="model.licensetype=='default'">
              <div class="form-group">
                <div class="input-group">
                  <input type="text" name="users-readonly" class="form-control-plaintext" style="width:455px;max-width:455px;" readonly value="{{model.defaultUsers}}"/>
                </div>
              </div>
            </td>
            <td> <!-- *ngIf="model.licensetype=='custom'"> -->
              <div class="form-group">
                <div class="input-group">
                  <input type="text" name="users" class="form-control" style="width:455px;max-width:455px;" [(ngModel)]="model.users" #users="ngModel" required
                          autocomplete="off" placeholder="Number of users for this trial license">
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="(model.hasPerm || model.validRecipient == 'denied-sameuser' || model.validRecipient=='denied-sameaccount' || model.validRecipient=='denied-wrongchannel'
            || (model.licensetype=='custom' && +model.users > +model.approvedusers) ||
            (model.licensetype=='custom' && (+model.expiration_days > +model.allowedDays || +model.expiration_days > +model.approveddays))) && (model.purpose==undefined || model.purpose=='' || purpose.errors?.minlength)">
            <td></td><td>
              <div class="form-group"><div class="input-group">
              <div class="help-block">
                To continue, please enter a reason for this trial explaining why it should be approved.
              </div>
              </div></div>
            </td>
          </tr>
          <tr>
            <td class="label">Purpose<b class="red" *ngIf="(model.hasPerm || model.validRecipient == 'denied-sameuser' || model.validRecipient=='denied-sameaccount' || model.validRecipient=='denied-wrongchannel'
              || (model.licensetype=='custom' && +model.users > +model.approvedusers) ||
              (model.licensetype=='custom' && (+model.expiration_days > +model.allowedDays || +model.expiration_days > +model.approveddays)))">*</b>:</td>
            <td>
              <div class="form-group">
                <div class="input-group">
                  <textarea rows="5" cols="50" name="purpose" minlength="5" [(ngModel)]="model.purpose" #purpose="ngModel" placeholder="Enter reason for trial license request"></textarea>
                </div>
              </div>
            </td>
          </tr>
          <tr><td></td><td><button [disabled]="loading" class="btn btn-primary">REQUEST TRIAL</button></td></tr>
        </table>
      </form>
    </div>
    <div *ngIf="model.lockedout != false && model.stage==1" class="alert alert-danger">
      Your access to this function has been blocked.  Please <a target="_top" href="https://www.graphon.com/contact/">contact us</a> for assistance.
    </div>
    <div *ngIf="model.stage==2">
      Submitting Trial Request...
    </div>
    <div *ngIf="model.stage==3">
      <div *ngIf="model.tlr_name=='ERROR'">
        An error occurred submitting your trial request.  Please try again.
      </div>
      <div *ngIf="model.tlr_name!='ERROR' && model.approval_reason == ''">
        <p>
          Trial License Request {{ model.tlr_name }} was processed and emailed to:
          {{ model.username }}<span *ngIf="model.accountcc != ''">, {{ model.accountcc }}</span>
          <span *ngIf="model.channelcc != ''">, {{ model.channelcc }}</span>, {{ model.deliverto }}.
        </p>
        <p>
          Check your SPAM or Junk Mail folder if you do not receive the license within 10 minutes.
        </p>
      </div>
      <div *ngIf="model.tlr_name!='ERROR' && model.approval_reason != ''">
        Trial License Request {{ model.tlr_name }} has been submitted for approval.<br/>
        This request will require approval for the following reason(s):
        <pre>
{{ model.approval_reason }}
        </pre>
        Approval is generally performed within 1 business day.
      </div>
    </div>
    <!--
      <hr/>
    <pre style="font-size:10px; line-height: 10px;">
model: {{model|json}}
    </pre>
    -->
  </div>
</div>
