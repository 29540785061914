import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { AlertService, PortalService, ElectronIPC } from '../_services/index';

@Component({
    moduleId: module.id,
    templateUrl: 'wizardActivateAccount.component.html'
})

export class WizardActivateAccount {
    model: any = {};
    loading = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private portalService: PortalService,
        private alertService: AlertService,
        private ipc: ElectronIPC) { 
            this.route.params.subscribe((params) => {
                this.model.key = params['key'];
                console.log("subscription updated key to " + this.model.key);
            });
            let params = new URLSearchParams(window.location.search);
            if ( params.paramsMap.get('?ak') ) {
                this.model.key = params.paramsMap.get('?ak');
                console.log('activation key initialized to ' + this.model.key);
            }
        }

    activate() {
        this.loading = true;
        console.log("activating using %o", this.model);
        this.portalService.activateAccount(this.model.key)
            .subscribe(
                data => {
                    if ( data ) {
                        this.alertService.success('Account Activated.', true);
                        this.router.navigate(['/wizard/wizardLogin']);
                    }
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
	
	goback() {
		this.portalService.logout();
		this.router.navigate(['/wizard/wizardLogin']);
	}
	
	closeapp() {
		this.portalService.logout();		
		this.ipc.send('closeApp');
	}
}
