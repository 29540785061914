import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import * as moment from 'moment';

@Directive({
  selector: '[expirationLimit]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: ExpirationValidator, multi: true}
  ]
})

export class ExpirationValidator implements Validator {
  validate(c: AbstractControl): { [key: string]: any } {
    if ( !c.value ) {
      return { valid: false };
    }
    console.log("c.value=%o", c.value);
    let expiration = ('0000' + c.value.year).slice(-4) + "-" +
                     ('00' + c.value.month).slice(-2) + "-" +
                     ('00' + c.value.day).slice(-2);
    let now = moment();
    let exp_date = moment(expiration);
    console.log("validating: expiration = %o now = " + now.format() +" exp_date=" + exp_date.format(), expiration)
    let diff = Math.ceil(moment.duration(exp_date.diff(now)).asDays());
    try {
      let limit = parseInt(c.root.get("allowedDays").value);
      console.log("validating: diff: " + diff + " limit: " + limit);
      if ( diff > limit ) {
        return null; // or { valid: false }; if we want to prevent submissions with extended durations.
      }
    } catch (TypeError) {
      //
    }
    return null;
/*
    if ( diff > )

    if ( hostname == null ) return;
    let invalidGroups = hostname.match(/[^A-Za-z0-9\-_.]+/g);
    if ( invalidGroups ) {
      return { invalid: "The following characters are not valid for hostname: " + invalidGroups.join(', ') };
    }
    if ( hostname.charAt(0) == "." || hostname.charAt(0) == "_" || hostname.charAt(0) == "-" ) {
      return { invalid: "Hostname cannot start with '" + hostname.charAt(0) + '"' };
    }
*/
  }
}
