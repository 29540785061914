<div class="wizard">	
	<div class="overlay" *ngIf="loading"></div>
	
	<div class='container' style="width: 100vw; padding: 5px; border-bottom: 1px solid #4472c4;">
		<div class='row'>
			<div class="col-xs-2" style="text-align: center">
			
			</div>
			<div class="col-xs-8" style="text-align: center">
				<a href="javascript:void(0)" (click)="goback()" class="backbutton navigation">&larr;</a>
				<img src="logo.png" width="150" />
				
			</div>
		</div>
	</div>
	
	<alert></alert>
	
	<div class="wrapper">
		<div class="container">
			<div class="row vertical-align">

				<div class="col-xs-6" style="min-width: 400px;">
					<h2>Create an account or <a [routerLink]="['/wizard/wizardLogin']">sign in</a></h2>
					
					<form name="form" (ngSubmit)="f.form.valid && register()" #f="ngForm" novalidate>
						<div class="form-group" [ngClass]="{ 'has-error': f.submitted && !firstname.valid }">
							<!-- <label for="firstname">First Name</label> -->
							<input type="text" class="form-control" name="firstname" [(ngModel)]="model.firstname" #firstname="ngModel" required placeholder="First name" />
							<div *ngIf="f.submitted && !firstname.valid" class="help-block">First Name is required</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': f.submitted && !lastname.valid }">
							<!-- <label for="lastname">Last Name</label> -->
							<input type="text" class="form-control" name="lastname" [(ngModel)]="model.lastname" #lastname="ngModel" required placeholder="Last name" />
							<div *ngIf="f.submitted && !lastname.valid" class="help-block">Last Name is required</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': f.submitted && !emailaddress.valid }">
							<!-- <label for="emailaddress">E-mail address</label> -->
							<input type="text" class="form-control" name="emailaddress" [(ngModel)]="model.emailaddress" #emailaddress="ngModel" required email placeholder="E-mail address" />
							<div *ngIf="f.submitted && !emailaddress.valid" class="help-block">E-mail is required</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': f.submitted && !company.valid }">
							<!-- <label for="company">Company name (optional)</label> -->
							<input type="text" class="form-control" name="company" [(ngModel)]="model.company" required #company="ngModel" placeholder="Company name" />
							<div *ngIf="f.submitted && !company.valid" class="help-block">Company name is required</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': (f.submitted || password.dirty || passwordConfirm.dirty) && !password.valid }">
							<!-- <label for="password">Password</label> -->
							<input type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" required equal="passwordConfirm" placeholder="Password" />
							<div *ngIf="(password.dirty || passwordConfirm.dirty) && !password.valid" class="help-block">Password is required and must match</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': (f.submitted || password.dirty || passwordConfirm.dirty) && !passwordConfirm.valid }">
							<!-- label for="passwordConfirm">Confirm password</label> -->
							<input type="password" class="form-control" name="passwordConfirm" [(ngModel)]="model.passwordConfirm" #passwordConfirm="ngModel" required equal="password" placeholder="Confirm password" />
							<div *ngIf="(password.dirty || passwordConfirm.dirty) && !passwordConfirm.valid" class="help-block">Password is required and must match</div>
						</div>
						<div class="form-group">
							<label for="isCustomer">I work for an existing GO-Global customer:</label>&nbsp;
							<input type="checkbox" name="isCustomer" [(ngModel)]="model.isCustomer" #isCustomer="ngModel" />
						</div>
						<div *ngIf="model.isCustomer" class="form-group" [ngClass]="{ 'has-error': isCustomer.checked && !customerInfo.valid }">
							<!-- label for="customerInfo">Customer information:</label> -->
							<textarea rows="4" class="form-control" name="customerInfo" [(ngModel)]="model.customerInfo" #customerInfo="ngModel" required placeholder="To help us assign you to the correct account, please enter the company name and, if available, the product code or license ID of a GO-Global license."></textarea>
						</div>
						<div style="text-align: right" class="form-group buttons">
							<button [disabled]="loading" class="btn btn-primary">Create Account</button>
							<a [routerLink]="['/wizard/wizardLogin']" class="btn btn-link">Cancel</a>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
