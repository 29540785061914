<div *ngIf="loading == true">
    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: #000000; opacity: 0.8; z-index: 9998;">
        <div style="position: absolute; top: 50%; left: 50%; margin: -50px 0 0 -50px; opacity: 1.0;">
            <figure style="overflow: visible;" id="spinner"><div class="spinner"></div></figure>
            <h2 style="color: #ffffff; margin: 0 0 0 35px; font-family: 'Work Sans', Arial, Helvetica, sans-serif;">PROCESSING...</h2>
        </div>
    </div>
</div>

<div class="menu" style="text-align: center;">
  <div class="second-row" style="flex-grow: unset; width: 32vw; margin: auto;">
    <h1 style="color: #0f3b80;">Create a GraphOn account</h1><div style="font-size: 1em; margin-bottom: 10px;">or <span><a [routerLink]="['/signin']">
        sign in</a></span></div>
    <form name="form" (ngSubmit)="f.form.valid && register()" #f="ngForm" novalidate>
      <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !firstname.valid }">
          <!-- <label for="firstname">First Name</label> -->
          <input type="text" class="form-control" name="firstname" [(ngModel)]="model.firstname" #firstname="ngModel" autofocus required placeholder="First name *" />
          <div *ngIf="f.submitted && !firstname.valid" class="help-block">To continue, please enter a first name.</div>
      </div>
      <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !lastname.valid }">
          <!-- <label for="lastname">Last Name</label> -->
          <input type="text" class="form-control" name="lastname" [(ngModel)]="model.lastname" #lastname="ngModel" required placeholder="Last name *" />
          <div *ngIf="f.submitted && !lastname.valid" class="help-block">To continue, please enter a last name.</div>
      </div>
      <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !emailaddress.valid }">
          <!-- <label for="emailaddress">E-mail address</label> -->
          <input type="text" class="form-control" name="emailaddress" [(ngModel)]="model.emailaddress" #emailaddress="ngModel" required email placeholder="E-mail address *" />
          <div *ngIf="f.submitted && !emailaddress.valid" class="help-block">To continue, please enter a valid email address.</div>
      </div>
      <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !company.valid }">
          <!-- <label for="company">Company name (optional)</label> -->
          <input type="text" class="form-control" name="company" [(ngModel)]="model.company" required #company="ngModel" placeholder="Company name *" />
          <div *ngIf="f.submitted && !company.valid" class="help-block">To continue, please enter a company name.</div>
      </div>
      <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || password.dirty || passwordConfirm.dirty) && !password.valid }">
          <!-- <label for="password">Password</label> -->
          <input type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" required equal="passwordConfirm" placeholder="Password *" />
          <div *ngIf="(password.dirty || passwordConfirm.dirty || f.submitted) && !password.valid" class="help-block">To continue, please enter and confirm a password.</div>
      </div>
      <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || password.dirty || passwordConfirm.dirty) && !passwordConfirm.valid }">
          <!-- label for="passwordConfirm">Confirm password</label> -->
          <input type="password" class="form-control" name="passwordConfirm" [(ngModel)]="model.passwordConfirm" #passwordConfirm="ngModel" required equal="password" placeholder="Confirm password *" />
          <div *ngIf="(password.dirty || passwordConfirm.dirty || f.submitted) && !passwordConfirm.valid" class="help-block">To continue, please enter and confirm a password.</div>
      </div>
      <div class="form-group">
          <input type="checkbox" name="isCustomer" [(ngModel)]="model.isCustomer" #isCustomer="ngModel" />
          <label for="isCustomer">I work for an existing GO-Global customer.</label>&nbsp;
      </div>
      <div *ngIf="model.isCustomer" class="form-group" [ngClass]="{ 'has-error': isCustomer.checked && !customerInfo.valid }">
          <!-- label for="customerInfo">Customer information:</label> -->
          <textarea rows="6" class="form-control" name="customerInfo" [(ngModel)]="model.customerInfo" #customerInfo="ngModel" required placeholder="To help us assign you to the correct account, please enter the company name and, if available, the product code or license ID of a GO-Global license."></textarea>
          <div *ngIf="(f.submitted && customerInfo.invalid)" class="help-block">To continue, please enter your GO-Global customer details.</div>
      </div>
      <div class="form-group">
          <input type="checkbox" name="agree" [(ngModel)]="model.agree" #agree="ngModel" required />
          <label for="agree">I agree to the GraphOn <a href="https://www.graphon.com/privacy/">Privacy Policy</a>.*</label>&nbsp;
          <div *ngIf="(f.submitted && agree.invalid)" class="help-block">To continue, please agree to the Privacy Policy.</div>
      </div>
      <div style="margin-top: 5px;" class="form-group">
          <button [disabled]="loading" class="btn btn-primary">CREATE ACCOUNT</button>
          <a [routerLink]="['/login']"><button type="button" class="btn" style="margin-left: 10px;">CANCEL</button></a>
      </div>
    </form>
  </div>
</div>
