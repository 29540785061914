<div class="wizard">
	<div class="overlay" *ngIf="loading"></div>
	
	<div class='container' style="width: 100vw; padding: 5px; border-bottom: 1px solid #4472c4;">
		<div class='row'>
			<div class="col-xs-12" style="text-align: center">
				<a href="javascript:void(0)" (click)="goback()" class="backbutton navigation">&larr;</a>
				<img src="logo.png" width="150" />
			</div>		
		</div>
	</div>
	
	<alert></alert>
	
	<div class="wrapper">
		<div class="container">
			<div class="row vertical-align">
				<div class="col-xs-12">
					<h2>{{heading}}</h2>
					<h4>Select the license you would like this computer to use.</h4>
					<!-- <h6>Account: {{account}}</h6> -->
				</div>
			</div>
				
			<ng-container *ngIf="showResults">
				<div *ngIf="!loading" class="form-group text-right licensegrid">
				
					<!-- List the trial licenses -->
					<ng-container *ngIf="trialEligible">
						<div *ngFor="let license of triallicenses" class="tile license trial" (click)="trialRequest(license.id, license.account._id, license.name)">
							<div class="container">
								<div class="row vertical-align">
									<div class="col-xs-12" style="height:49px; padding:0px">
										<h6>Start New Trial</h6>
									</div>
								</div>
								<div class="row vertical-align">
									<div class="col-xs-4" style="text-align:left; padding:0px">
										<p style="font-weight: 600">
										<ng-container *ngIf="license.reason">{{license.reason}}</ng-container><br/>
										&nbsp;
										</p>
									</div>
									<div class="col-xs-8" style="text-align:right; padding:0px">
										<p>
											Seats: {{license.concurrent_use_limit}}<br/>
											Expires: {{license.renewal_period.replace('d', ' days')}}
										</p>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
					
					<ng-container *ngIf="!showValidLicenses">
						<ng-container *ngIf="!trialEligible">
							<div *ngFor="let license of triallicenses" class="tile license selected" (click)="showInvalidLicenseInfo(license)">
								<div class="container">
									<div class="row vertical-align">
										<div class="col-xs-12" style="height:49px; padding:0px">
											<h6>Start New Trial</h6>
										</div>
									</div>
									<div class="row vertical-align">
										<div class="col-xs-4" style="text-align:left; padding:0px; color:#e00000;">
											<p style="font-weight: 600">
											<ng-container *ngIf="license.reason">{{license.reason}}</ng-container><br/>
											&nbsp;
											</p>
										</div>
										<div class="col-xs-8" style="text-align:right; padding:0px">
											<p>
												Seats: {{license.concurrent_use_limit}}<br/>
												Expires: {{license.renewal_period.replace('d', ' days')}}
											</p>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
					</ng-container>
					
					<!-- List the valid licenses -->
					<div *ngFor="let license of licenses" class="tile license" [className]="licenseName == license.name ? 'tile license selected' : 'tile license'" (click)="activateRequest(license.id, license.name, licenseName)">
						<div class="container">
							<div class="row vertical-align">
								<div class="col-xs-12" style="height:49px; padding:0px; overflow: hidden;">
									<h6>{{license.description}}</h6>
								</div>
							</div>
							<div class="row vertical-align" style="overflow-x: visible;">
								<div class="col-xs-6" style="text-align:left; padding:0px; overflow-x: visible;">
									<div *ngIf="licenseName == license.name">
										<p>
											Selected License<br/>
											{{license.name}}
										</p>
									</div>
									<div *ngIf="licenseName != license.name">
										<p>
											<br/>
											{{license.name}}
										</p>
									</div>
								</div>
								<div class="col-xs-6" style="text-align:right; padding:0px">
									<p>
										Seats: {{license.concurrent_use_limit}}<br/>
										Expires: {{license.expiration_date | date:'mediumDate'}}
									</p>
								</div>
							</div>
						</div>
					</div>
					
					<!-- List the invalid licenses -->
					<ng-container *ngIf="!showValidLicenses">
						<div *ngFor="let license of invalidlicenses" class="tile license selected" (click)="showInvalidLicenseInfo(license)">
							<div class="container">
								<div class="row vertical-align">
									<div class="col-xs-12" style="height:49px; padding:0px; overflow: hidden;">
										<h6>{{license.description}}</h6>
									</div>
								</div>
								<div class="row vertical-align" style="overflow-x: visible;">
									<div class="col-xs-6" style="text-align:left; padding:0px; overflow-x: visible; color:#e00000;">
										<div *ngIf="licenseName == license.name">
											<p style="font-weight: 600">
												{{license.reason}}<br/>
												{{license.name}}
											</p>
										</div>
										<div *ngIf="licenseName != license.name">
											<p style="font-weight: 600">
												{{license.reason}}<br/>
												{{license.name}}
											</p>
										</div>
									</div>
									<div class="col-xs-6" style="text-align:right; padding:0px">
										<p>
											Seats: {{license.concurrent_use_limit}}<br/>
											Expires: {{license.expiration_date | date:'mediumDate'}}
										</p>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</ng-container>
			
			<div class="form-group text-right buttons">	
				<input type="checkbox" [checked]="showValidLicenses" (change)="showValidLicenses = !showValidLicenses" /><b> Show only licenses that may be used on this computer</b>
			</div>
			
			<div class="dialogOuter" *ngIf="dialogDescription">
				<div class="dialogInner">
					<h3>Please specify a description for this license:</h3>
					<form name="form" (ngSubmit)="g.form.valid && trialRequestDialog()" #g="ngForm" novalidate>
						<div class="container">
							<div class="row vertical-align">
								<div class="col-xs-12" style="text-align:left; padding:0px">
									<div class="form-group" [ngClass]="{ 'has-error': g.submitted }" style="padding:0px">
										<input type="text" class="form-control inputDialog" name="licenseDescription" [(ngModel)]="licenseDescription" required placeholder="{{licenseDescription}}" />
									</div>
								</div>
							</div>
							<div class="row vertical-align">	
								<div class="col-xs-6" style="text-align:left;">
									<div *ngIf="g.submitted" class="help-block">Description is required.</div>	
								</div>						
								<div class="col-xs-6" style="text-align:right;">
									<div class="form-group text-right" style="padding:0px">											
										<button class="btn btn-primary">OK</button>
										<a href="javascript:void(0)" (click)="closeDialog()" class="btn btn-link">Cancel</a>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			
			<div class="dialogOuter" *ngIf="dialogSelection">
				<div class="dialogInner">
					<h4>This computer is already configured to use this license.</h4>
					<form name="form" (ngSubmit)="g.form.valid && trialRequestDialog()" #g="ngForm" novalidate>
						<div class="container">
							<div class="row vertical-align">				
								<div class="col-xs-12" style="text-align:center;">
									<div class="form-group text-right" style="padding-top:45px">											
										<button class="btn btn-primary" (click)="closeDialog()">OK</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			
			<div class="dialogOuter" *ngIf="dialogLicenseInvalid">
				<div class="dialogInner">
					<div *ngIf="invalidLicense.reason == 'Expired'">
						<h4>This license is expired.</h4>
					</div>
					<div *ngIf="invalidLicense.reason == 'Revoked'">
						<h4>This license has been revoked.</h4>
					</div>
					<div *ngIf="invalidLicense.reason == 'Ineligible'">
						<p>GO-Global has already been activated on this computer using trial license {{invalidLicense.otherLicense}}. GO-Global may only be activated on a computer with one trial license. If trial license {{invalidLicense.otherLicense}} is not expired, you may activate GO-Global using {{invalidLicense.otherLicense}}.</p>
					</div>
					<p>For further assistance, contact GraphOn at <a href="javascript:void(0)" (click)="externalLink('https://www.graphon.com/contact')">https://www.graphon.com/contact</a></p>
					<form name="form" (ngSubmit)="g.form.valid && trialRequestDialog()" #g="ngForm" novalidate>
						<div class="container">
							<div class="row vertical-align">				
								<div class="col-xs-12" style="text-align:center;">
									<div class="form-group text-right" style="padding-top:5px">											
										<button class="btn btn-primary" (click)="closeDialog()">OK</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
						
			<div class="dialogOuter" *ngIf="dialogNoValidLicenses">
				<div class="dialogInner">
					<h4>GO-Global cannot be activated on this computer with any of the available licenses.</h4>
					<p>For further assistance, contact GraphOn at <a href="javascript:void(0)" (click)="externalLink('https://www.graphon.com/contact')">https://www.graphon.com/contact</a></p>
					<form name="form" (ngSubmit)="g.form.valid && trialRequestDialog()" #g="ngForm" novalidate>
						<div class="container">
							<div class="row vertical-align">				
								<div class="col-xs-12" style="text-align:center;">
									<div class="form-group text-right" style="padding-top:15px">											
										<button class="btn btn-primary" (click)="closeDialog()">OK</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			
		</div>
	</div>
</div>
