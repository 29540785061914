import { Directive, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[hostid]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: HostIdValidator, multi: true}
  ]
})

export class HostIdValidator implements Validator {
  constructor ( @Attribute('hostid') public hostid: string) {}

  validateHostId(hostid: string) : string {
    if ( hostid == null ) return "";
    if ( hostid.match(/^0+$/) != null ) {
      // all zeros is not valid
      return "'" + hostid + "' is not a valid host ID.";
    }
    if ( hostid.match(/^ID_STRING=[A-Fa-f0-9\-]+(_.*)?$/) != null ) {
      // HP-style host ID
      return "";
    }
    if ( hostid.length % 2 != 0 ) {
      return "'" + hostid + "' does not consist of an even number of digits.";
    }
    if ( hostid.match(/^[A-Fa-f0-9\-]+$/) != null ) {
      // is valid host id
      return "";
    }
    return "'" + hostid + "' is not a valid host ID.";
  }

  validate(c: AbstractControl): { [key: string]: any } {
    if ( c.value == null ) return;
    if ( c.value == undefined || c.value == "" ) return { 'invalid' : 'The host ID is required.' };
    let hostids = String(c.value).replace(/ /g, '').split("+");
    let retval = "";

    if ( hostids.length != 1 && hostids.length != 3 ) {
      return { "invalid" : "Specify one, or three host IDs." };
    }

    hostids.forEach((hostid: string) => {
      let result = this.validateHostId(hostid);
      if ( result != "" ) {
        retval += result + "\n";
      }
    });
    return retval == "" ? null : { 'invalid' : retval};
  }
}
