/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./download.component";
var styles_DownloadComponent = [];
var RenderType_DownloadComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DownloadComponent, data: {} });
export { RenderType_DownloadComponent as RenderType_DownloadComponent };
export function View_DownloadComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["placeholder for downloads"]))], null, null); }
export function View_DownloadComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_DownloadComponent_0, RenderType_DownloadComponent)), i0.ɵdid(1, 49152, null, 0, i1.DownloadComponent, [], null, null)], null, null); }
var DownloadComponentNgFactory = i0.ɵccf("ng-component", i1.DownloadComponent, View_DownloadComponent_Host_0, {}, {}, []);
export { DownloadComponentNgFactory as DownloadComponentNgFactory };
