

<div id="top" class="container">
  <div *ngIf="model.orderconfirmation" class="second-row" style="flex-grow: unset; width: 100%; padding-right: 0; font-size: 18px; font-weight: bold;">
    YOUR ORDER HAS BEEN SUBMITTED TO GRAPHON.  THANK YOU!
  </div>
  <div #orderFormRef id="orderFormRef"></div>
  <div *ngIf="!model.orderconfirmation" class="second-row" style="flex-grow: unset; width: 100%; padding-right: 0;">
    <h2>GO-Global
      <span *ngIf="model.mode=='standard'">Perpetual License</span>
      <span *ngIf="model.mode=='subscription'">Subscription</span>
      <span *ngIf="model.mode=='maint_renew'">Perpetual License Support Renewal</span>
      Order Form</h2>
    <div *ngIf="loading == true">
      <div style="position: absolute; top: 0; left: 0; width: 100%; height:100%; background: #000000; opacity: 0.8; z-index:9998;">
        <div style="position: absolute; top: 50%; left: 50%; margin: -50px 0px 0px -50px; opacity: 1.0;">
          <figure style="overflow: visible" id="spinner"><div class="spinner"></div></figure>
          <h2 style="color:#ffffff; margin: 0px 0px 0px 35px; font-family: 'Quicksand', Arial, Helvetica, 'Numbus Sans L', sans-serif;">PROCESSING...</h2>
        </div>
      </div>
    </div>

    <form #f="ngForm" name="form" (ngSubmit)="submitForm(f)" novalidate autocomplete="off">
      <div *ngIf="model.mode == 'unknown'">
        <h3 *ngIf="model.enabled_modes!=''">Select Order Type</h3>
        <div *ngIf="model.enabled_modes.includes('S')" class="form-check">
          <label class="form-check-label">
            <input id="mode_sub" type="radio" class="form-check-input" [(ngModel)]="model.mode"
              name="mode" value="subscription" #mode="ngModel" required>
            Subscription License Order
          </label>
        </div>
        <div *ngIf="model.enabled_modes.includes('P')" class="form-check">
          <label class="form-check-label">
            <input id="mode_std" type="radio" class="form-check-input" [(ngModel)]="model.mode"
              name="mode" value="standard" #mode="ngModel" required>
            Perpetual License Order
          </label>
        </div>
        <div *ngIf="model.enabled_modes.includes('P')" class="form-check">
          <label class="form-check-label">
            <input id="mode_maint_renew" type="radio" class="form-check-input" [(ngModel)]="model.mode"
              name="mode" value="maint_renew" #mode="ngModel" required>
            Perpetual License Support Renewal Order
          </label>
        </div>
        <div *ngIf="!model.enabled_modes.includes('P') && !model.enabled_modes.includes('S')">
          <h3>This account is not configured for online ordering.</h3>
          Please contact <a href="mailto:orders@graphon.com">orders@graphon.com</a> for assistance.
        </div>
      </div>

      <div *ngIf="f.submitted && !f.valid" class="help-block">
        Please correct the errors shown in red below.
        <br/>
        <div class="help-block">
          {{ f.errors }}
        </div>
      </div>

      <table *ngIf="model.mode != 'unknown'" cellspacing="1" style="width: 100%; padding-top: 25px;">
        <style scoped>
          .heading { color: white; background: #0f3b80; font-weight: bold; padding: 4px !important; font-size: 16px; text-align: center !important; }
          td { vertical-align: top; white-space: nowrap; }
          input.number { text-align: right; }
          input.form-check-input { margin-top: 0; }
          input { height: 24px !important; padding: 8px 8px !important; font-size: 15px; }
          input[type=button]{ line-height: 24px; }
          select { height: 24px !important; padding: 0 !important; width: auto !important;}
          table { border: 0; border-spacing: 0; border-collapse: collapse; }
          .product_rows { text-align: center; }
          .notice { color: #00c000; }
          .dropzone {
            width: 450px;
            height: 200px;
            padding: 2rem;
            text-align: center;
            border: dashed 1px #979797;
            position: relative;
            margin: 0 auto;
          }
          .dropzone input {
            opacity: 0;
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }
          /*
          .dropzone label {
            color: white;
            width: 183px;
            height: 44px;
            border-radius: 21.5px;
            background-color: #0f3b80;
            padding: 8px 10px;
          }
          */
          .dropzone h3 {
            font-size: 20px;
            font-weight: 600;
            color: #38424c;
          }
          .fileover {
            background: rgba(20, 20, 255, 0.5);
          }
          b.red { color: #ff0000; }
          b.indent { margin-left: 5vw; }
        </style>
        <tbody>
          <tr style="display: table-row;">
            <td style="vertical-align: top; display: table-cell;" class="heading" colspan="2">
              <span *ngIf="model.show_readme" (click)="toggleReadme()" tabindex="0" style="font-size:18px;"><i class="fas fa-caret-down"></i></span>
              <span *ngIf="!model.show_readme" (click)="toggleReadme()" tabindex="0" style="font-size:18px;"><i class="fas fa-caret-right"></i></span>
              <span style="padding-left: 15px;">
                <span *ngIf="model.mode=='subscription'">Notes / Readme</span>
                <span *ngIf="model.mode!='subscription'">Email Communications and Processing Times</span>
              </span>
            </td>
          </tr>
          <tr *ngIf="model.show_readme">
            <td style="white-space: normal;" colspan="2">
              <ol>
                <li>All fields designated with an <font color="#f00">*</font> are mandatory.</li>
                <li>After you submit your order, GraphOn will email an order confirmation to the
                  Purchasing Contact, Technical Contacts, and additional contacts you specify below.
                  Order confirmation emails are generally sent within out hour after you submit your
                  order during GraphOn Business Hours (M-F, 8 am to 5 pm EST).</li>
                <li *ngIf="model.mode=='subscription'">When subscription licenses are issued
                  (generally within 1 business day of payment being received),
                  GraphOn will email subscription notifications to the contacts that receive
                  the order confirmation plus any emails included in the Subscription License Notification
                  section.</li>
                <li *ngIf="model.mode=='standard'">When perpetual licenses are issued
                  (generally within 1 business day of payment being received),
                  GraphOn will email perpetual license notifications to the contacts that
                  receive the order confirmation plus any emails included in the Product Code Delivery
                  section.
                </li>
                <li>Please add <a href="mailto:orders@graphon.com">orders@graphon.com</a> to your email
                  contact list to ensure delivery of all emails related to your order.</li>
                <li>Please email us at <a href="mailto:orders@graphon.com">orders@graphon.com</a>
                  if you do not receive an email with your order confirmation 1 hour after submission
                  and use "Did Not Receive Confirmation" as the subject line.</li>
                <li>Payment terms orders are processed 1 business day after your order is confirmed
                  via email.</li>
                <li>If you are paying by credit card, you will receive an email containing a link to
                  a secure payment site to submit your credit card information.  Additional "Invoice To"
                  contacts you listed on your order will also receive the email.</li>
                <li>Credit card orders are processed 1 business day after payment is completed.</li>
                <li>GraphOn will process prepaid wire orders 1 business day after wire payment is deposited
                  into GraphOn's bank account.</li>
              </ol>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="heading">
              General Information
            </td>
          </tr>
          <tr>
            <td><b>Order Date<b class="red">*</b>:</b></td>
            <td>
              <input id="order_date" [firstDayOfWeek]="7" type="text" size="40"
                [(ngModel)]='model.order_date' ngbDatepicker name="order_date"
                #expiration="ngbDatepicker" required placeholder="YYYY-mm-dd"
                (focus)="expiration.open()" readonly (ngModelChange)="confirmOrderDate()"
                >
              <div *ngIf="model.warnOrderDate" class="notice">
                {{ model.warnOrderDate }}
              </div>
            </td>
          </tr>
          <tr>
            <td><b>Purchase Order Number:</b></td>
            <td><input id="po_num" type="text" size="40" [(ngModel)]='model.po_num'
              name="po_num" value="">
            </td>
          </tr>
          <tr>
            <td><b>Quote Number:</b></td>
            <td><input id="quote_num" type="text" size="40" [(ngModel)]='model.quote_num'
              name="quote_num" value="">
            </td>
          </tr>
          <tr>
            <td><b>Additional Reference No:</b></td>
            <td><input id="ref_num" type="text" size="40" [(ngModel)]='model.ref_num'
              name="ref_num" value="">
            </td>
          </tr>
          <tr><td colspan="2"><hr></td></tr>
          <tr>
            <td><b>Purchasing Contact:</b></td>
            <td><input id="billto_contact_name" type="text" size="40" [(ngModel)]="model.billto_contact_name"
              name="billto_contact_name" value="{{model.user.firstname}} {{model.user.lastname}}"
              #billto_contact_name="ngModel" disabled="disabled">
            </td>
          </tr>
          <tr>
            <td><b>Purchasing Contact E-mail:</b></td>
            <td><input id="billto_contact_email" type="text" size="40" [(ngModel)]="model.billto_contact_email"
              name="billto_contact_email" value="{{model.user.username}}"
              #billto_contact_email="ngModel" disabled="disabled">
            </td>
          </tr>
          <!--
          <tr>
            <td><b>Telephone:</b></td>
            <td>
              <input id="billto_phone" type="text" size="40" [(ngModel)]="model.billto_phone"
                name="billto_phone" #billto_phone="ngModel">
            </td>
          </tr>
          -->
          <tr><td colspan="2"><em>(Optional) Additional contacts to receive order confirmation and subscription notification
            emails:</em></td></tr>
          <tr>
            <td><b>Email&nbsp;1:</b></td>
            <td>
              <input id="order_cc1" type="text" size="40" [(ngModel)]="model.order_cc1"
                name="order_cc1" (blur)="checkContact($event)" (keyup)="updateContact($event)"
                email externalEmail #order_cc1="ngModel" placeholder="E-mail address">
              <div *ngIf="order_cc1.errors?.externalvalid" class="help-block">
                {{ order_cc1.errors.externalvalid }}
              </div>
              <div class="help-block" *ngIf="!order_cc1.valid && (order_cc1.dirty
                || order_cc1.touched || f.submitted) && !order_cc1.errors?.externalvalid">
                To continue, please provide a valid email address.
              </div>
              <span *ngIf="order_cc1.valid && model.notfound.order_cc1 && !model.duplicateContact.order_cc1">
                <b>Name<b class="red">*</b>:</b>&nbsp;
                <input id="order_cc1_firstname" type="text" size="25" [(ngModel)]="model.order_cc1_firstname"
                  name="order_cc1_firstname" #order_cc1_firstname="ngModel"
                  placeholder="Enter new contact first name" required>&nbsp;
                <input id="order_cc1_lastname" type="text" size="25" [(ngModel)]="model.order_cc1_lastname"
                  name="order_cc1_lastname" #order_cc1_lastname="ngModel"
                  placeholder="Enter new contact last name" required>
                <br/>
                <div *ngIf="!order_cc1_firstname.valid || !order_cc1_lastname.valid" class="help-block">
                  This e-mail address is not on file. To continue, please enter a name for this new contact.
                </div>
                <div *ngIf="order_cc1_firstname.valid && order_cc1_lastname.valid" class="notice">
                  This e-mail address is not on file. A new contact will be created.
                </div>
              </span>
              <span *ngIf="model.found.order_cc1 != undefined">
                <b>Name:</b>&nbsp;
                <input id="order_cc1_firstname" type="text" size="25" [(ngModel)]="model.found.order_cc1.firstname"
                  name="order_cc1_firstname" disabled="disabled">&nbsp;
                <input id="order_cc1_lastname" type="text" size="25" [(ngModel)]="model.found.order_cc1.lastname"
                  name="order_cc1_lastname" disabled="disabled">
              </span>
              <span *ngIf="model.duplicateContact.order_cc1 != undefined">
                <b>Name:</b>&nbsp;
                <input id="order_cc1_firstname" type="text" size="25" [(ngModel)]="model.duplicateContact.order_cc1_firstname"
                  name="order_cc1_firstname" disabled="disabled">&nbsp;
                <input id="order_cc1_lastname" type="text" size="25" [(ngModel)]="model.duplicateContact.order_cc1_lastname"
                  name="order_cc1_lastname" disabled="disabled">
              </span>
            </td>
          </tr>
          <tr>
            <td><b>Email&nbsp;2:</b></td>
            <td>
              <input id="order_cc2" type="text" size="40" [(ngModel)]="model.order_cc2"
                name="order_cc2" (blur)="checkContact($event)" (keyup)="updateContact($event)"
                email externalEmail #order_cc2="ngModel" placeholder="E-mail address">
              <div class="help-block" *ngIf="order_cc2.errors?.externalvalid">
                {{ order_cc2.errors.externalvalid }}
              </div>
              <div class="help-block" *ngIf="!order_cc2.valid && (order_cc2.dirty
                || order_cc2.touched || f.submitted) && !order_cc2.errors?.externalvalid">
                To continue, please provide a valid e-mail address.
              </div>
              <span *ngIf="order_cc2.valid && model.notfound.order_cc2 && !model.duplicateContact.order_cc2">
                <b>Name: (*)</b>&nbsp;
                <input id="order_cc2_firstname" type="text" size="25" [(ngModel)]="model.order_cc2_firstname"
                  name="order_cc2_firstname" #order_cc2_firstname="ngModel" required
                  placeholder="Enter new contact first name">&nbsp;
                <input id="order_cc2_lastname" type="text" size="25" [(ngModel)]="model.order_cc2_lastname"
                  name="order_cc2_lastname" #order_cc2_lastname="ngModel" required
                  placeholder="Enter new contact last name">
                <br/>
                <div *ngIf="!order_cc2_firstname.valid || !order_cc2_lastname.valid" class="help-block">
                  This e-mail address is not on file. To continue, please enter a name for this new contact.
                </div>
                <div *ngIf="order_cc2_firstname.valid && order_cc2_lastname.valid" class="notice">
                  This e-mail address is not on file. A new contact will be created.
                </div>
              </span>
              <span *ngIf="model.found.order_cc2 != undefined">
                <b>Name:</b>&nbsp;
                <input id="order_cc2_firstname" type="text" size="25" [(ngModel)]="model.found.order_cc2.firstname"
                  name="order_cc2_firstname" disabled="disabled">&nbsp;
                <input id="order_cc2_lastname" type="text" size="25" [(ngModel)]="model.found.order_cc2.lastname"
                  name="order_cc2_lastname" disabled="disabled">
              </span>
              <span *ngIf="model.duplicateContact.order_cc2 != undefined">
                <b>Name:</b>&nbsp;
                <input id="order_cc2_firstname" type="text" size="25" [(ngModel)]="model.duplicateContact.order_cc2_firstname"
                  name="order_cc2_firstname" disabled="disabled">&nbsp;
                <input id="order_cc2_lastname" type="text" size="25" [(ngModel)]="model.duplicateContact.order_cc2_lastname"
                  name="order_cc2_lastname" disabled="disabled">
              </span>
            </td>
          </tr>

          <tr><!-- BILL TO COMPANY -->
            <td colspan="2" class="heading">
              Payer Information
            </td>
          </tr>
          <tr>
            <td><b>Company:</b></td>
            <td><input id="billto_company" type="text" size="40" [(ngModel)]='model.billto_company'
              name="billto_company" disabled="disabled">
            </td>
          </tr>
          <tr>
            <td><b>Billing Address:</b></td>
            <td><input id="billto_address" type="text" size="40" [(ngModel)]='model.billto_address'
              name="billto_address" disabled="disabled">
            </td>
          </tr>
          <tr>
            <td><b>&nbsp;</b></td>
            <td><input id="billto_address2" type="text" size="40" [(ngModel)]='model.billto_address2'
              name="billto_address2" disabled="disabled">
            </td>
          </tr>
          <tr>
            <td><b>&nbsp;</b></td>
            <td><input id="billto_address3" type="text" size="40" [(ngModel)]='model.billto_address3'
              name="billto_address3" disabled="disabled">
            </td>
          </tr>
          <tr>
            <td><b>&nbsp;</b></td>
            <td><input id="billto_address4" type="text" size="40" [(ngModel)]='model.billto_address4'
              name="billto_address4" disabled="disabled">
            </td>
          </tr>
          <tr>
            <td><b>Country:</b></td>
            <!--
            <td><select id="billto_country" class="form-control" name="billto_country" #billto_country="ngModel"
                [(ngModel)]="model.billto_country" disabled="disabled">
              <option *ngFor="let c of countries" [value]="c.id">{{c.name}}</option>
            </select></td>
            -->
            <td><input id="billto_country" name="billto_country" #billto_country="ngModel"
                  [(ngModel)]="model.billto_country" [value]="model.user.account.country" size="40" disabled="disabled" />
            </td>
          </tr>
          <tr>
            <td><b>Email Invoice to<b class="red">*</b>:</b></td>
            <td><input id="billto_email" type="text" size="40" [(ngModel)]='model.billto_email'
              name="billto_email" value="" email externalEmail required #billto_email="ngModel"
              (blur)="checkContact($event)" (keyup)="updateContact($event)">
              <span *ngIf="billto_email.errors?.externalvalid">
                <div class="help-block">{{billto_email.errors.externalvalid }}</div>
              </span>
              <span *ngIf="!billto_email.valid && (billto_email.dirty
                || billto_email.touched || f.submitted) && !billto_email.errors?.externalvalid">
                <div class="help-block">To continue, please enter a valid e-mail address.</div>
              </span>
              <span *ngIf="billto_email.valid && model.notfound.billto_email && !model.duplicateContact.billto_email">
                <b>Name: (*)</b>&nbsp;
                <input id="billto_email_firstname" name="billto_email_firstname"
                  type="text" size="25" [(ngModel)]="model.billto_email_firstname"
                  #billto_email_firstname="ngModel"
                  placeholder="Enter new contact first name" required>&nbsp;
                <input id="billto_email_lastname" name="billto_email_lastname"
                  type="text" size="25" [(ngModel)]="model.billto_email_lastname"
                  #billto_email_lastname="ngModel"
                  placeholder="Enter new contact last name" required>
                <div *ngIf="!billto_email_firstname.valid || !billto_email_lastname.valid" class="help-block">
                  This e-mail address is not on file.
                  To continue, please enter a name for this new contact.
                </div>
                <div *ngIf="billto_email_firstname.valid && billto_email_lastname.valid" class="notice">
                  This e-mail address is not on file. A new contact will be created.
                </div>
              </span>
              <span *ngIf="model.found.billto_email != undefined">
                <b>Name:</b>&nbsp;
                <input id="billto_email_firstname" name="billto_email_firstname"
                  type="text" size="25" [(ngModel)]="model.found.billto_email.firstname"
                  disabled="disabled">&nbsp;
                <input id="billto_email_lastname" name="billto_email_lastname"
                  type="text" size="25" [(ngModel)]="model.found.billto_email.lastname"
                  disabled="disabled">
              </span>
              <span *ngIf="model.duplicateContact.billto_email != undefined">
                <b>Name:</b>&nbsp;
                <input id="billto_email_firstname" name="billto_email_firstname"
                  type="text" size="25" [(ngModel)]="model.duplicateContact.billto_email_firstname"
                  disabled="disabled">&nbsp;
                <input id="billto_email_lastname" name="billto_email_lastname"
                  type='text' size="25" [(ngModel)]="model.duplicateContact.billto_email_lastname"
                  disabled="disabled">
              </span>
            </td>
          </tr>
          <tr>
            <td colspan="2"><em>(Optional) Additional contacts to receive invoice:</em></td>
          </tr>
          <tr>
            <td>
              <b>Email&nbsp;1:</b>
            </td>
            <td>
              <input id="billto_cc1" type="text" size="40" [(ngModel)]='model.billto_cc1'
                name="billto_cc1" (blur)="checkContact($event)" (keyup)="updateContact($event)"
                email externalEmail #billto_cc1="ngModel" placeholder="E-mail address">
              <div class="help-block" *ngIf="billto_cc1.errors?.externalvalid">
                {{ billto_cc1.errors.externalvalid }}
              </div>
              <div class="help-block" *ngIf="!billto_cc1.valid && (billto_cc1.dirty
                || billto_cc1.touched || f.submitted) && !billto_cc1.errors?.externalvalid">
                To continue, please enter a valid e-mail address.
              </div>
              <span *ngIf="billto_cc1.valid && model.notfound.billto_cc1 && !model.duplicateContact.billto_cc1">
                <b>Name<b class="red">*</b>:</b>&nbsp;
                <input id="billto_cc1_firstname" type="text" size="25" name="billto_cc1_firstname"
                  [(ngModel)]="model.billto_cc1_firstname" #billto_cc1_firstname="ngModel" required
                  placeholder="Enter new contact first name">&nbsp;
                <input id="billto_cc1_lastname" type="text" size="25" name="billto_cc1_lastname"
                  [(ngModel)]="model.billto_cc1_lastname" #billto_cc2_lastname="ngModel" required
                  placeholder="Enter new contact last name">
                <br/>
                <div *ngIf="!billto_cc1_firstname.valid || !billto_cc2_lastname.valid" class="help-block">
                  This e-mail address is not on file. To continue, please enter a name for this new contact.
                </div>
                <div *ngIf="billto_cc1_firstname.valid && billto_cc2_lastname.valid" class="notice">
                  This e-mail address is not on file. A new contact will be created.
                </div>
              </span>
              <span *ngIf="model.found.billto_cc1 != undefined">
                <b>Name:</b>&nbsp;
                <input id="billto_cc1_firstname" name="billto_cc1_firstname" type="text" size="25"
                  [(ngModel)]="model.found.billto_cc1.firstname" disabled="disabled">&nbsp;
                <input id="billto_cc1_lastname" name="billto_cc1_lastname" type="text" size="25"
                  [(ngModel)]="model.found.billto_cc1.lastname" disabled="disabled">
              </span>
              <span *ngIf="model.duplicateContact.billto_cc1 != undefined">
                <b>Name:</b>&nbsp;
                <input id="billto_cc1_firstname" name="billto_cc1_firstname" type="text" size="25"
                  [(ngModel)]="model.duplicateContact.billto_cc1_firstname" disabled="disabled">&nbsp;
                <input id="billto_cc1_lastname" name="billto_cc1_lastname" type="text" size="25"
                  [(ngModel)]="model.duplicateContact.billto_cc1_lastname" disabled="disabled">
              </span>
            </td>
          </tr>
          <tr>
            <td><b>Email&nbsp;2:</b></td>
            <td>
              <input id="billto_cc2" type="text" size="40" [(ngModel)]='model.billto_cc2'
                name="billto_cc2" (blur)="checkContact($event)" (keyup)="updateContact($event)"
                email externalEmail #billto_cc2="ngModel" placeholder="E-mail address">
              <div class="help-block" *ngIf="billto_cc2.errors?.externalvalid">
                {{ billto_cc2.errors.externalvalid }}
              </div>
              <div class="help-block" *ngIf="!billto_cc2.valid && (billto_cc2.dirty
                || billto_cc2.touched || f.submitted) && !billto_cc2.errors?.externalvalid">
                To continue, please enter a valid e-mail address.
              </div>
              <span *ngIf="billto_cc2.valid && model.notfound.billto_cc2 && !model.duplicateContact.billto_cc2">
                <b>Name<b class="red">*</b>:</b>&nbsp;
                <input id="billto_cc2_firstname" name="billto_cc2_firstname" type="text" size="25"
                  [(ngModel)]="model.billto_cc2_firstname" #billto_cc2_firstname="ngModel" required
                  placeholder="Enter new contact first name">&nbsp;
                <input id="billto_cc2_lastname" name="billto_cc2_lastname" type="text" size="25"
                  [(ngModel)]="model.billto_cc2_lastname" #billto_cc2_lastname="ngModel" required
                  placeholder="Enter new contact last name">
                <br/>
                <div *ngIf="!billto_cc2_firstname.valid || !billto_cc2_lastname.valid" class="help-block">
                  This e-mail address is not on file. To continue, please enter a name for this new contact.
                </div>
                <div *ngIf="billto_cc2_firstname.valid && billto_cc2_lastname.valid" class="notice">
                  This e-mail address is not on file. A new contact will be created.
                </div>
              </span>
              <span *ngIf="model.found.billto_cc2 != undefined">
                <b>Name:</b>&nbsp;
                <input id="billto_cc2_firstname" name="billto_cc2_firstname" type="text" size="25"
                  [(ngModel)]="model.found.billto_cc2.firstname" disabled="disabled">&nbsp;
                <input id="billto_cc2_lastname" name="billto_cc2_lastname" type="text" size="25"
                  [(ngModel)]="model.found.billto_cc2.lastname" disabled="disabled">
              </span>
              <span *ngIf="model.duplicateContact.billto_cc2 != undefined">
                <b>Name:</b>&nbsp;
                <input id="billto_cc2_firstname" name="billto_cc2_firstname" type="text" size="25"
                  [(ngModel)]="model.duplicateContact.billto_cc2_firstname" disabled="disabled">&nbsp;
                <input id="billto_cc2_lastname" name="billto_cc2_lastname" type="text" size="25"
                  [(ngModel)]="model.duplicateContact.billto_cc2_lastname" disabled="disabled">
              </span>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="heading">Payment Information</td>
          </tr>
          <tr>
            <td><b>Payment Option<b class="red">*</b>:</b></td>
            <td>
              <select id="payment_method" name="payment_method" #payment_method="ngModel"
                class="form-control" [(ngModel)]='model.payment_method'
                required>
                <option value="" selected disabled> - Choose One - </option>
                <option value="Credit Card">Credit Card</option>
                <option value="Prepaid Wire Transfer">Prepaid Wire Transfer</option>
                <option value="Payment Terms">Payment Terms</option>
                <option value="Prepaid Check">Prepaid Check</option>
                <option value="Other">Other</option>
              </select>
              <div *ngIf="!payment_method.valid && (payment_method.dirty || payment_method.touched
                || f.submitted)" class="help-block">
                To continue, please select a payment method.
              </div>
            </td>
          </tr>
          <tr *ngIf="model.payment_method=='Prepaid Wire Transfer'">
            <td><b>Estimated Deposit Date<b class="red">*</b>:</b></td>
            <td>
              <input id="wirexfer_date" [firstDayOfWeek]="7" type="text" size="40"
                [(ngModel)]='model.wirexfer_date' ngbDatepicker name="wirexfer_date"
                #wirexfer_date="ngbDatepicker" [minDate]="model.order_date" required placeholder="YYYY-mm-dd"
                (focus)="wirexfer_date.open()" (blur)="wirexfer_date.dirty=true" readonly>
              <div *ngIf="!model.wirexfer_date && (wirexfer_date.dirty || wirexfer_date.touched || f.submitted)"
                class="help-block">
                To continue, please enter a valid date.
              </div>
            </td>
          </tr>
          <tr *ngIf="model.payment_method=='Prepaid Wire Transfer'">
            <td><b>Wire Transfer Information:</b></td>
            <td>
              <p>Prepaid wire transfer orders are processed one business day after
                funds are deposited.
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>Bank Name:</td>
                    <td>Wells Fargo Bank, N.A.</td>
                  </tr>
                  <tr>
                    <td>Bank Address:</td>
                    <td>420 Montgomery Street<br/>
                      San Francisco, CA  94014<br/>
                      United States
                    </td>
                  </tr>
                  <tr>
                    <td>Bank Telephone:</td>
                    <td>+1.888.384.8400</td>
                  </tr>
                  <tr>
                    <td>Beneficiary:</td>
                    <td>GraphOn Corporation<br/>
                      189 North Main Street, Suite 102<br/>
                      Concord, NH  03301<br/>
                      United States
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>+1.603.225.3525</td>
                  </tr>
                  <tr>
                    <td>US Federal Tax ID:</td>
                    <td>46-0899097</td>
                  </tr>
                  <tr>
                    <td>Bank Account Name:</td>
                    <td>GraphOn Corporation</td>
                  </tr>
                  <tr>
                    <td>Bank Account Number:</td>
                    <td>8242019761</td>
                  </tr>
                  <tr>
                    <td>Domestic USA Wires:</td>
                    <td>ABA RTN 121000248</td>
                  </tr>
                  <tr>
                    <td>International Wires:</td>
                    <td>SWIFT Code WFBIUS6S</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr *ngIf="model.payment_method=='Prepaid Check'">
            <td>&nbsp;</td>
            <td>Orders paid for4 by check will be processed one business day
              after we deposit your check.  Checks must be written from a
              United Sates bank and made payable to "GraphOn Corporation".
              Send your check via United States Postal Service to:
            </td>
          </tr>
          <tr *ngIf="model.payment_method=='Prepaid Check'">
            <td><b>Address:</b></td>
            <td>Lockbox Service - #0138682<br/>
              GraphOn Corporation<br/>
              P.O. Box 888682<br/>
              Los Angeles, CA  90088-8682
            </td>
          </tr>
          <tr *ngIf="model.payment_method=='Payment Terms'">
            <td colspan="2" style="text-align: center;">
              <span style="font-size: 18px; color: green;">!!</span>
              <b>Account Credit Approval Required In Advance.</b>
              <span style="font-size: 18px; color: green;">!!</span>
            </td>
          </tr>
          <tr *ngIf="model.payment_method=='Payment Terms' && model.mode=='maint_renew'">
            <td colspan="2" style="text-align: center;">
              <span style="font-size: 18px; color: green;">!!</span>
              <b>Official Purchase Order must be attached to order.</b>
              <span style="font-size: 18px; color: green;">!!</span>
            </td>
          </tr>
          <tr *ngIf="model.payment_method=='Other'">
            <td>
              <b>Please provide specifics<b class="red">*</b>:</b>
            </td>
            <td>
              <textarea id="payment_terms" name="payment_terms" #payment_terms="ngModel"
                [(ngModel)]="model.payment_terms" rows="5" cols="40" required>
              </textarea>
              <div *ngIf="!payment_terms.valid && (payment_terms.dirty || payment_terms.touched || f.submitted)" class="help-block">
                To continue, please provide specific details.
              </div>
            </td>
          </tr>
          <tr *ngIf="model.payment_method=='Credit Card'">
            <td><b>Card Type<b class="red">*</b>:</b></td>
            <td>
              <select id="cc_type" name="cc_type" #cc_type="ngModel"
                class="form-control" [(ngModel)]='model.cc_type'
                required>
                <option selected disabled value=""> - Choose One - </option>
                <option value="Visa">Visa</option>
                <option value="MasterCard">MasterCard</option>
                <option value="American Express">American Express</option>
                <option value="Discover">Discover</option>
              </select>
              <div *ngIf="model.cc_type==''" class="help-block">
                To continue, please select a card type.
              </div>
            </td>
          </tr>
          <tr *ngIf="model.payment_method=='Credit Card' && model.cc_type!=''">
            <td colspan="2">
              <span style="font-size: 18px;" class="notice">!! <b>After you submit your
                order, you will receive an email with a link to a secure website for credit
                card submission and payment processing.</b> !!</span>
            </td>
          </tr>
          <tr *ngIf="model.mode!='maint_renew'">
            <td colspan="2" class="heading">
              Buyer Information
            </td>
          </tr>
          <tr *ngIf="model.mode!='maint_renew'">
            <td><b>Who is the ultimate GO-Global<br/>user for this order?<br/>Please select one<b class="red">*</b>:</b></td>
            <td>
              <div class="form-check">
                <label class="form-check-label">
                  <input id="partner_reseller" type="radio" class="form-check-input" [(ngModel)]="model.partner_type"
                    name="partner_type" value="enduser" [attr.checked]="model.partner_type" required>
                  End User Company buying direct
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input id="partner_reseller" type="radio" class="form-check-input" [(ngModel)]="model.partner_type"
                    name="partner_type" value="reseller" [attr.checked]="model.partner_type" required>
                  GraphOn Partner selling directly to End User Company
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input id="partner_dist" type="radio" class="form-check-input" [(ngModel)]="model.partner_type"
                    name="partner_type" value="distributor" [attr.checked]="model.partner_type" required>
                  GraphOn Partner selling to Reseller, who is selling to End User Company
                </label>
              </div>
            </td>
          </tr>
          <tr *ngIf="model.partner_type!='reseller'" >
            <td colspan="2" class="heading">
              Reseller Information
            </td>
          </tr>
          <tr *ngIf="model.partner_type!='reseller'" >
            <td><b>Company Name<b class="red">*</b>:</b></td>
            <td>
              <input id="reseller_company" type="text" size="40" [(ngModel)]='model.reseller_company'
                name="reseller_company" #reseller_company="ngModel" required>
              <div *ngIf="!reseller_company.valid && (reseller_company.dirty || reseller_company.touched || f.submitted)" class="help-block">
                To continue, please enter a Company name.
              </div>
            </td>
          </tr>
          <tr *ngIf="model.partner_type!='reseller'" >
            <td><b>Main Contact Email<b class="red">*</b>:</b></td>
            <td><input id="reseller_email" type="text" size="40" [(ngModel)]='model.reseller_email'
              name="reseller_email" #reseller_email="ngModel" externalEmail required email #reseller_email="ngModel"
              (blur)="checkContact($event)" (keyup)="updateContact($event)">
              <span *ngIf="reseller_email.errors?.externalvalid">
                <div class="help-block">{{ reseller_email.errors.externalvalid }}</div>
              </span>
              <span *ngIf="!reseller_email.valid && (reseller_email.dirty
                || reseller_email.touched || f.submitted) && !reseller_email.errors?.externalvalid">
                <div class="help-block">To continue, please enter a valid e-mail address.</div>
              </span>
              <span *ngIf="reseller_email.valid && model.notfound.reseller_email && !model.duplicateContact.reseller_email">
                <b>Name<b class="red">*:</b>&nbsp;</b>
                <input id="reseller_email_firstname" name="reseller_email_firstname"
                  type="text" size="25" [(ngModel)]="model.reseller_email_firstname"
                  #reseller_email_firstname="ngModel"
                  placeholder="Enter new contact first name" required>&nbsp;
                <input id="reseller_email_lastname" name="reseller_email_lastname"
                  type="text" size="25" [(ngModel)]="model.reseller_email_lastname"
                  #reseller_email_lastname="ngModel"
                  placeholder="Enter new contact last name" required>
                <br/>
                <div *ngIf="!reseller_email_firstname.valid || !reseller_email_lastname.valid" class="help-block">
                  This e-mail address is not on file.
                  To continue, please enter a name for this new contact.
                </div>
                <div *ngIf="reseller_email_firstname.valid && reseller_email_lastname.valid" class="notice">
                  This e-mail address is not on file. A new contact will be created.
                </div>
              </span>
              <span *ngIf="model.found.reseller_email != undefined">
                <b>Name:</b>&nbsp;
                <input id="reseller_email_firstname" name="reseller_email_firstname"
                  type="text" size="25" [(ngModel)]="model.found.reseller_email.firstname"
                  #reseller_email_firstname="ngModel" required
                  disabled="disabled">&nbsp;
                <input id="reseller_email_lastname" name="reseller_email_lastname"
                  type="text" size="25" [(ngModel)]="model.found.reseller_email.lastname"
                  #reseller_email_lastname="ngModel" required
                  disabled="disabled">
              </span>
              <span *ngIf="model.duplicateContact.reseller_email != undefined">
                <b>Name:</b>&nbsp;
                <input id="reseller_email_firstname" name="reseller_email_firstname"
                  type="text" size="25" [(ngModel)]="model.duplicateContact.reseller_email_firstname"
                  #reseller_email_firstname="ngModel" disabled="disabled">&nbsp;
                <input id="reseller_mail_lastname" name="reseller_email_lastname"
                  type="text" size="25" [(ngModel)]="model.duplicateContact.reseller_email_lastname"
                  #reseller_email_lastname="ngModel" disabled="disabled">
              </span>
            </td>
          </tr>
          <tr *ngIf="model.partner_type!='reseller'" >
            <td><b>Address<b class="red">*</b>:</b></td>
            <td><input id="reseller_address1" type="text" size="40" [(ngModel)]='model.reseller_address1'
              name="reseller_address1" #reseller_address1="ngModel" value="" required>
              <div *ngIf="!reseller_address1.valid && (reseller_address1.dirty || reseller_address1.touched
                || f.submitted)" class="help-block">
                To continue, please enter a valid address.
              </div>
            </td>
          </tr>
          <tr *ngIf="model.partner_type!='reseller'" >
            <td><b>&nbsp;</b></td>
            <td><input id="reseller_address2" type="text" size="40" [(ngModel)]='model.reseller_address2'
              name="reseller_address2" value="">
            </td>
          </tr>
          <tr *ngIf="model.partner_type!='reseller'" >
            <td><b>&nbsp;</b></td>
            <td><input id="reseller_address3" type="text" size="40" [(ngModel)]='model.reseller_address3'
              name="reseller_address3" value="">
            </td>
          </tr>
          <tr *ngIf="model.partner_type!='reseller'" >
            <td><b>&nbsp;</b></td>
            <td><input id="reseller_address4" type="text" size="40" [(ngModel)]='model.reseller_address4'
              name="reseller_address4" value="">
            </td>
          </tr>
          <tr *ngIf="model.partner_type!='reseller'" >
            <td><b>Postal Code<b class="red">*</b>:</b></td>
            <td><input id="reseller_zipcode" type="text" size="40" [(ngModel)]='model.reseller_zipcode'
              name="reseller_zipcode" value="" #reseller_zipcode="ngModel" required>
              <div *ngIf="!reseller_zipcode.valid && (reseller_zipcode.dirty || reseller_zipcode.touched
                || f.submitted)" class="help-block">
                To continue, please enter a valid postal code.
              </div>
            </td>
          </tr>
          <tr *ngIf="model.partner_type!='reseller'" >
            <td><b>Country<b class="red">*</b>:</b></td>
            <td>
              <select id="reseller_country" class="form-control" name="reseller_country" #reseller_country="ngModel"
                [(ngModel)]="model.reseller_country" required>
                <option *ngFor="let c of countries" [value]="c.id">{{c.name}}</option>
              </select>
              <div *ngIf="!reseller_country.valid && (reseller_country.dirty || reseller_country.touched
                || f.submitted)" class="help-block">
                To continue, please select a country.
              </div>
            </td>
          </tr>
          <tr *ngIf="model.mode!='maint_renew'">
            <td colspan="2" class="heading">
              End User Company Information
            </td>
          </tr>
          <tr *ngIf="model.mode!='maint_renew'">
            <td><b>Company Name<b class="red">*</b>:</b></td>
            <td>
              <input id="enduser_company" type="text" size="40" [(ngModel)]='model.enduser_company'
                name="enduser_company" #enduser_company="ngModel" required>
              <div *ngIf="!enduser_company.valid && (enduser_company.dirty || enduser_company.touched
                || f.submitted)" class="help-block">
                To continue, please enter the End-User Company Name.
              </div>
            </td>
          </tr>
          <tr *ngIf="model.mode!='maint_renew'">
            <td><b>Main Contact Email<b class="red">*</b>:</b></td>
            <td>
              <input id="enduser_email" type="text" size="40" [(ngModel)]='model.enduser_email'
                name="enduser_email" #enduser_email="ngModel" externalEmail required email #enduser_email="ngModel"
                (blur)="checkContact($event)" (keyup)="updateContact($event)">
              <span *ngIf="enduser_email.errors?.externalvalid">
                <div class="help-block">{{ enduser_email.errors.externalvalid }}</div>
              </span>
              <span *ngIf="!enduser_email.valid && (enduser_email.dirty
                || enduser_email.touched || f.submitted) && !enduser_email.errors?.externalvalid">
                <div class="help-block">To continue please enter a valid e-mail address.</div>
              </span>
              <span *ngIf="enduser_email.valid && model.notfound.enduser_email && !model.duplicateContact.enduser_email">
                <b>Name<b class="red">*</b>:</b>&nbsp;
                <input id="enduser_email_firstname" name="enduser_email_firstname"
                  type="text" size="25" [(ngModel)]="model.enduser_email_firstname"
                  #enduser_email_firstname="ngModel"
                  placeholder="Enter new contact first name" required>&nbsp;
                <input id="enduser_email_lastname" name="enduser_email_lastname"
                  type="text" size="25" [(ngModel)]="model.enduser_email_lastname"
                  #enduser_email_lastname="ngModel"
                  placeholder="Enter new contact last name" required>
                <div *ngIf="!enduser_email_firstname.valid || !enduser_email_lastname.valid" class="help-block">
                  This e-mail address is not on file.
                  To continue, please enter a name for this new contact.
                </div>
                <div *ngIf="enduser_email_firstname.value && enduser_email_lastname.valid" class="notice">
                  This e-mail address is not on file. A new contact will be created.
                </div>
              </span>
              <span *ngIf="model.found.enduser_email != undefined">
                &nbsp;<b>Name:</b>&nbsp;
                <input id="enduser_email_firstname" name="enduser_email_firstname"
                  type="text" size="25" [(ngModel)]="model.found.enduser_email.firstname"
                  disabled="disabled">&nbsp;
                <input id="enduser_email_lastname" name="enduser_email_lastname"
                  type="text" size="25" [(ngModel)]="model.found.enduser_email.lastname"
                  disabled="disabled">
              </span>
              <span *ngIf="model.duplicateContact.enduser_email != undefined">
                <b>Name:</b>&nbsp;
                <input id="enduser_email_firstname" name="enduser_email_firstname"
                  type="text" size="25" [(ngModel)]="model.duplicateContact.enduser_email_firstname"
                  disabled="disabled">&nbsp;
                <input id="enduser_email_lastname" name="enduser_email_lastname"
                  type="text" size="25" [(ngModel)]="model.duplicateContact.enduser_email_lastname"
                  disabled="disabled">
              </span>
            </td>
          </tr>
          <tr *ngIf="model.mode!='maint_renew'">
            <td><b>Company Address<b class="red">*</b>:</b></td>
            <td><input id="enduser_address1" type="text" size="40" [(ngModel)]='model.enduser_address1'
              name="enduser_address1" #enduser_address1="ngModel" value="" required>
              <div *ngIf="!enduser_address1.valid && (enduser_address1.dirty || enduser_address1.touched
                || f.submitted)" class="help-block">
                To continue, please enter the company address.
              </div>
            </td>
          </tr>
          <tr *ngIf="model.mode!='maint_renew'">
            <td><b>&nbsp;</b></td>
            <td><input id="enduser_address2" type="text" size="40" [(ngModel)]='model.enduser_address2'
              name="enduser_address2" value="">
            </td>
          </tr>
          <tr *ngIf="model.mode!='maint_renew'">
            <td><b>&nbsp;</b></td>
            <td><input id="enduser_address3" type="text" size="40" [(ngModel)]='model.enduser_address3'
              name="enduser_address3" value="">
            </td>
          </tr>
          <tr *ngIf="model.mode!='maint_renew'">
            <td><b>&nbsp;</b></td>
            <td><input id="enduser_addres4" type="text" size="40" [(ngModel)]='model.enduser_address4'
              name="enduser_address4" value="">
            </td>
          </tr>
          <tr *ngIf="model.mode!='maint_renew'">
            <td><b>Postal Code<b class="red">*</b>:</b></td>
            <td><input id="enduser_zipcode" type="text" size="40" [(ngModel)]='model.enduser_zipcode'
              name="enduser_zipcode" #enduser_zipcode="ngModel" value="" required>
              <div *ngIf="!enduser_zipcode.valid && (enduser_zipcode.dirty || enduser_zipcode.touched ||
                f.submitted)" class="help-block">
                To continue, please enter a valid Postal Code.
              </div>
            </td>
          </tr>
          <tr *ngIf="model.mode!='maint_renew'">
            <td><b>Country<b class="red">*</b>:</b></td>
            <td>
              <select id="enduser_country" class="form-control" name="enduser_country" #enduser_country="ngModel"
                [(ngModel)]="model.enduser_country" required>
                <option *ngFor="let c of countries" [value]="c.id">{{c.name}}</option>
              </select>
              <div *ngIf="!enduser_country.valid && (enduser_country.dirty || enduser_country.touched
                || f.submitted)" class="help-block">
                To continue, please select a Country.
              </div>
            </td>
          </tr>
          <tr [hidden]="model.mode!='subscription'">
            <td class="heading" colspan="2">
              Subscription License Details
            </td>
          </tr>
          <tr [hidden]="model.mode!='subscription'">
            <td><b>License Type<b class="red">*</b>:</b></td>
            <td>
              <select id="sub_type" name="sub_type" #sub_type="ngModel"
                class="form-control" [(ngModel)]="model.sub_type"
                (change)="resetSubscriptionType()" required>
                <option value="" selected disabled> - Choose One - </option>
                <option value="new">New Subscription</option>
                <option value="addon">Add Users to existing Subscription</option>
              </select>
              <div *ngIf="!sub_type.valid && (sub_type.dirty || sub_type.touched || f.submitted)" class="help-block">
                To continue, please select a subscription type.
              </div>
            </td>
          </tr>
          <tr [hidden]="model.sub_type!='new'">
            <td><b>Tenancy<b class="red">*</b>:</b></td>
            <td>
              <select class="form-control" id="sub_tenancy" name="sub_tenancy" #sub_tenancy="ngModel"
                [(ngModel)]="model.sub_tenancy" (change)="onTenancyChange()" required>
                <option value="" selected disabled> - Choose One - </option>
                <option value="cloud">Cloud</option>
                <option value="onprem">On-Premises</option>
              </select>
              <div *ngIf="!sub_tenancy.valid && (sub_tenancy.dirty || sub_tenancy.touched || f.submitted)" class="help-block">
                To continue, please select a tenancy option.
              </div>
            </td>
          </tr>
          <!--  disabled to move into line item
          <tr [hidden]="model.sub_type!='new' || model.sub_tenancy!='onprem'">
            <td><b>Duration: (*)</b></td>
            <td>
              <select *ngIf="model.sub_tenancy=='cloud'" class="form-control" id="sub_dur" name="sub_dur" #sub_dur="ngModel"
                [(ngModel)]="model.sub_dur" required>
                <option value="" selected disabled> - Choose One - </option>
                <option value="monthly">Monthly Renewal</option>
                <option value="annual">Annual Renewal</option>
                <option value="annual-monthly">Annual Renewal, paid in monthly installments</option>
                <option value="multi">3-year Renewal</option>
                <option value="multi-monthly">3-year Renewal, paid in monthly installments</option>
              </select>
              <select *ngIf="model.sub_tenance=='onprem'" class="form-control" id="sub_dur" name="sub_dur" #sub_dur="ngModel"
                [(ngModel)]="model.sub_dur" required>
                <option value="" selected disabled> - Choose One - </option>
                <option value="monthly">Monthly Renewal</option>
                <option value="monthly-2months">Monthly Renewal, paid every 2 months</option>
                <option value="monthly-3months">Monthly Renewal, paid every 3 months</option>
              </select>
              <div *ngIf="!sub_dur.valid && (sub_dur.dirty || sub_dur.touched || f.submitted)" class="help-block">
                Subscription duration must be selected.
              </div>
            </td>
          </tr>
          -->
          <tr [hidden]="model.sub_type!='new' || model.sub_tenancy!='cloud'">
            <td><b>License Origin<b class="red">*</b>:</b></td>
            <td>
              <select class="form-control" id="sub_origin" name="sub_origin" #sub_origin="ngModel"
                [(ngModel)]="model.sub_origin" (change)="onOriginChange()" required>
                <option value="" selected disabled> - Choose One - </option>
                <option value="new">Create New License Master</option>
                <option value="existing">Convert Existing Cloud Trial License</option>
              </select>
              <div *ngIf="!sub_origin.valid && (sub_origin.dirty || sub_origin.touched || f.submitted)" class="help-block">
                To continue, please specify license origin.
              </div>
            </td>
          </tr>
          <tr [hidden]="model.sub_type!='new' || model.sub_tenancy!='cloud' || model.sub_origin!='existing'">
            <td><b>Trial License Information<b class="red">*</b>:</b></td>
            <td>
              <input id="sub_existing_license" name="sub_existing_license" type="text" size="10"
                #sub_existing_license="ngModel" [(ngModel)]="model.sub_existing_license" required
                (blur)="lookupExistingTrial()" (change)="resetExistingLicense()"
                placeholder="LIC-000000">&nbsp;
              <input id="sub_existing_siteadmin" name="sub_existing_siteadmin" type="text" size="25"
                #sub_existing_siteadmin="ngModel" [(ngModel)]="model.sub_existing_siteadmin" required
                placeholder="E-mail address of a site administrator on the license"
                (blur)="lookupExistingTrial()">
              <div *ngIf="model.sub_existing_trial_license_error" class="help-block">{{ model.sub_existing_trial_license_error }}</div>
              <div *ngIf="!sub_existing_license.valid && (sub_existing_license.dirty || sub_existing_license.touched || f.submitted)" class="help-block">
                To continue, please sepcify the existing trial license.
              </div>
              <div *ngIf="!sub_existing_siteadmin.valid && (sub_existing_siteadmin.dirty || sub_existing_siteadmin.touched || f.submitted)" class="help-block">
                To continue, please specify the existing site administrator for the license.
              </div>
            </td>
          </tr>
          <tr [hidden]="model.sub_type!='addon'">
            <td><b>Existing License<b class="red">*</b>:</b></td>
            <td>
              <input id="sub_addon_existing" name="sub_addon_existing" type="text" size="10"
                #sub_addon_existing="ngModel" [(ngModel)]="model.sub_addon_existing" required
                placeholder="LIC-000000" (blur)="lookupExistingLicense()">&nbsp;
              <input id="sub_addon_siteadmin" name="sub_addon_siteadmin" type="text" size="25"
                #sub_addon_siteadmin="ngModel" [(ngModel)]="model.sub_addon_siteadmin" required
                placeholder="E-mail address of a site administrator on the license"
                (blur)="lookupExistingLicense()">
              <div *ngIf="model.sub_addon_license_error" class="help-block">{{ model.sub_addon_license_error }}</div>
              <div *ngIf="model.sub_addon_existing==undefined" class="help-block">
                To continue, please specify the existing license.
              </div>
              <div *ngIf="model.sub_addon_siteadmin==undefined" class="help-block">
                To continue, please specify the existing site administrator for the license.
              </div>
            </td>
          </tr>
          <tr *ngIf="model.sub_type == 'addon' && model.sub_addon_license.description != undefined">
            <td><b>License Description:</b></td>
            <td>
              <div *ngIf="model.sub_addon_license">{{ model.sub_addon_license.description }} ({{ model.sub_addon_license.account.name }})</div>
            </td>
          </tr>
          <tr *ngIf="model.sub_type == 'addon' && model.sub_addon_license.expiration != undefined">
            <td><b>Current Term Expiration:</b></td>
            <td>
              <div *ngIf="model.sub_addon_license">{{ model.sub_addon_license.expiration.replace(" 00:00:00.0","") }}</div>
            </td>
          </tr>
          <tr *ngIf="model.sub_type == 'addon' && model.sub_addon_license.expiration != undefined &&
                    addonIsExpired==true">
            <td>&nbsp;</td>
            <div class="help-block">
              This license is expired.  Contact <a href="mailto:orders@graphon.com">orders@graphon.com</a> for assistance.
            </div>
          </tr>
          <tr *ngIf="model.sub_type == 'new' && model.sub_existing_trial_license.description != undefined">
            <td><b>License Description:</b></td>
            <td>
              <div *ngIf="model.sub_existing_trial_license">{{ model.sub_existing_trial_license.description }}</div>
            </td>
          </tr>

          <tr><!-- product detail rows -->
            <td colspan="2">
              <table width="100%" class="product_rows">
                <tbody>
                  <tr>
                    <!-- <td class="heading" *ngIf="model.mode=='subscription'">License #</td> -->
                    <td *ngIf="model.mode=='standard' || (model.mode=='subscription'&&model.sub_type!='addon')" class="heading"># Users</td>
                    <td *ngIf="model.mode=='subscription' && model.sub_type=='addon'" class="heading">Current User #</td>
                    <td *ngIf="model.mode=='subscription' && model.sub_type=='addon'" class="heading">Addt'l Users</td>
                    <td *ngIf="model.mode=='subscription' && model.sub_type=='addon'" class="heading">Total</td>
                    <td class="heading">
                      <span *ngIf="model.mode=='subscription'">License Description<b class="red">*</b></span>
                      <span *ngIf="model.mode=='standard'">Part Number<b class="red">*</b></span>
                      <span *ngIf="model.mode=='maint_renew'">Description</span>
                    </td>
                    <td class="heading" [hidden]="model.mode=='maint_renew' || (model.mode=='subscription'&&model.sub_type=='addon')">Release #<font color="#ff0000">*</font></td>
                    <td *ngIf="model.mode=='maint_renew'" class="heading">$ Total from Worksheet<font color="#ff0000">*</font></td>
                    <td *ngIf="model.mode=='standard'" class="heading">Unit Price</td>
                    <td *ngIf="model.mode=='subscription'" class="heading">$/User<font color="#ff0000">*</font></td>
                    <td class="heading">USD Total</td>
                  </tr>
                  <!-- row displayed before license information is chosen -->
                  <tr [hidden]="model.mode!='subscription' || (model.sub_type!='' && model.sub_type!=undefined)">
                    <td>
                      <i>Options available after subscription type selected.</i>
                    </td>
                  </tr>
                  <tr [hidden]="model.mode!='subscription' || model.sub_type!='new'">
                    <!-- new subscription license line item -->
                    <td>
                      <input id="sub_qty" name="sub_qty" type="text" size="5" [(ngModel)]="model.sub_qty"
                        #sub_qty="ngModel" pattern="[0-9]*" maxlength="5" class="number" required placeholder="seats">
                    </td>
                    <td [hidden]="model.sub_tenancy!='cloud'">
                      <!--
                      <select class="form-control"
                        id="sub_cloud_desc" name="sub_cloud_desc" #sub_cloud_desc="ngModel"
                        [(ngModel)]="model.sub_cloud_desc" required (change)="onSubCloudDescChange()">
                        <option value="" selected disabled> - Choose One - </option>
                        <option value="monthly">Subscription - 1 Month Term</option>
                        <option value="annual">Subscription - Annual Term</option>
                        <option value="monthlysso">Subscription + SSO - 1 Month Term</option>
                        <option value="annualsso">Subscription + SSO - Annual Term</option>
                        <option value="annual3">Annual Renewal - 3 Years</option>
                      </select>
                      -->
                      <select class="form-control" id="sub_cloud_desc" name="sub_cloud_desc" #sub_cloud_desc="ngModel"
                        [(ngModel)]="model.sub_cloud_desc" required (change)="onSubCloudDescChange()">
                        <option value="" selected disabled> - Choose One - </option>
                        <option value="1month">Monthly Subscription</option>
                        <option value="1monthsso">Monthly Subscription - GO-Global plus SSO</option>
                        <!-- <option value="2month">Subscription Term - 2 Months</option> -->
                        <!-- <option value="3month">Subscription - 3 Month Term</option> -->
                        <option value="annual">Annual Subscription</option>
                        <option value="annualsso">Annual Subscription - GO-Global plus SSO</option>
                        <option value="1monthusagebased">Pay-as-You-Go Subscription</option>
                        <option value="1monthusagebasedsso">Pay-as-You-Go Subscription - GO-Global plus SSO</option>
                      </select>
                    </td>
                    <td [hidden]="model.sub_tenancy!='onprem'">
                      <select class="form-control" id="sub_onprem_desc" name="sub_onprem_desc" #sub_onprem_desc="ngModel"
                        [(ngModel)]="model.sub_onprem_desc" required (change)="onSubOnPremDescChange()">
                        <option value="" selected disabled> - Choose One - </option>
                        <option value="1month">Monthly Subscription</option>
                        <option value="1monthsso">Monthly Subscription - GO-Global plus SSO</option>
                        <!-- <option value="2month">Subscription Term - 2 Months</option> -->
                        <!-- <option value="3month">Subscription - 3 Month Term</option> -->
                        <option value="annual">Annual Subscription</option>
                        <!-- <option value="3monthsso">Subscription + SSO - 3 Month Term</option> -->
                        <option value="annualsso">Annual Subscription - GO-Global plus SSO</option>
                      </select>
                    </td>
                    <td [hidden]="model.sub_tenancy=='cloud' || model.sub_tenancy=='onprem'">
                      <span>
                        <i>Selection unavailable until tenancy is chosen.</i>
                      </span>
                    </td>
                    <td [hidden]='model.sub_tenancy==""'>
                      <select id="sub_ver" class="form-control" name="sub_ver_edit" #sub_ver_edit="ngModel" [(ngModel)]="model.sub_ver" required>
                          <option *ngFor="let v of versions[model.sub_tenancy]" [value]="v.id" [selected]="v.selected" [disabled]="v.disabled">{{v.name}}</option>
                      </select>
                    </td>
                    <td [hidden]='model.sub_tenancy!=""'>
                      <em>Selection unavailable until tenancy is chosen.</em>
                    </td>
                    <td *ngIf="model.mode=='subscription' && model.sub_cloud_desc?.includes('usage')">
                      <input type="text" size="10" name="sub_units" value="N/A" disabled="disabled"/>
                    </td>
                    <td *ngIf="model.mode!='subscription' || !model.sub_cloud_desc?.includes('usage')">
                      <input class="number" type="text" size="10" name="sub_units" value=""
                          [(ngModel)]="model.sub_units" #sub_units="ngModel" pattern="[0-9.]*"
                          (blur)="transformAmount($event)"
                          (focus)="untransformAmount($event)"
                      />
                      <div *ngIf="model.mode=='subscription' && model.sub_tenancy=='cloud' && model.sub_cloud_desc?.includes('annual')"
                        style="color:#008f00">
                        Enter annual<br/>per-seat rate
                      </div>
                      <div *ngIf="model.mode=='subscription' && model.sub_tenancy=='cloud' && model.sub_cloud_desc?.includes('month')"
                        style="color:#008f00">
                        Enter monthly<br/>per-seat rate
                      </div>
                      <div *ngIf="model.mode=='subscription' && model.sub_tenancy=='onprem' && model.sub_onprem_desc?.includes('month')"
                        style="color:#008f00">
                        Enter monthly<br/>per-seat rate
                      </div>
                      <div *ngIf="model.mode=='subscription' && model.sub_tenancy=='onprem' && model.sub_onprem_desc?.includes('annual')"
                        style="color:#008f00">
                        Enter annual<br/>per-seat rate
                      </div>
                    </td>
                    <td *ngIf="model.mode=='subscription' && model.sub_cloud_desc?.includes('usage')">
                      <input type="text" size="10" name="sub_ext_field" value="N/A" disabled="disabled"/>
                    </td>
                    <td *ngIf="model.mode!='subscription' || !model.sub_cloud_desc?.includes('usage')">
                      <input class="number" type="text" disabled="disabled" size="10" name="sub_ext_field"
                          value="{{ this.extPrice | currency:'USD':'symbol':'1.2' }}"
                          [(ngModel)]="model.sub_ext_field" #sub_ext_field="ngModel"
                      />
                    </td>
                  </tr>
                  <tr [hidden]="model.mode!='subscription' || model.sub_type!='addon'">
                    <!-- row for add-on seats order -->
                    <td>
                      <input id="sub_current_qty" name="sub_current_qty" size="5" [(ngModel)]="model.sub_current_qty"
                        #sub_current_qty="ngModel" pattern="[0-9]*" maxlength="5" class="number" disabled="disabled">
                    </td>
                    <td>
                      <input id="sub_qty" name="sub_qty" type="text" size="5" [(ngModel)]="model.sub_qty"
                        #sub_qty="ngModel" pattern="[0-9]*" maxlength="5" class="number" required placeholder="seats">
                    </td>
                    <td>
                      <input id="sub_new_qty" name="sub_new_qty" size="5" value="{{ model.sub_current_qty -- model.sub_qty }}"
                        disabled="disabled">
                    </td>
                    <td>
                      <input type="text" size="40" name="sub_desc" #sub_desc="ngModel" [(ngModel)]="model.sub_desc" disabled="disabled">
                    </td>
                    <!--
                    <td>
                      <input type="text" size="8" name="sub_ver" #sub_ver="ngModel" [(ngModel)]="model.sub_ver" disabled="disabled">
                    </td>
                    -->
                    <td>
                      <input class="number" type="text" size="10" name="sub_units" value=""
                          [(ngModel)]="model.sub_units" #sub_units="ngModel" pattern="[0-9.]*"
                          (blur)="transformAmount($event)"
                          (focus)="untransformAmount($event)"
                      />
                      <div *ngIf="model.mode=='subscription' && model.sub_addon_license.profile!=undefined &&
                        (parseTerm(model.sub_addon_license.profile.termLength)==true)"
                        style="color:#008f00">
                        Enter annual<br/>per-seat rate
                      </div>
                      <div *ngIf="model.mode=='subscription' && model.sub_addon_license.profile!=undefined &&
                        (parseTerm(model.sub_addon_license.profile.termLength)==false)"
                        style="color:#008f00">
                        Enter monthly<br/>per-seat rate
                      </div>
                    </td>
                    <td>
                      <input class="number" type="text" disabled="disabled" size="10" name="sub_ext_field"
                          value="{{ this.addonPrice| currency:'USD':'symbol':'1.2' }}"
                          [(ngModel)]="model.sub_ext_field" #sub_ext_field="ngModel"
                      />
                    </td>
                  </tr>
                  <tr [hidden]="model.mode!='maint_renew'">
                    <!-- do not show qty field for renewals
                    <td>
                      <input class="number" type="text" name="maint_qty" size="2" value="1" disabled="disabled" [(ngModel)]="model.maint_qty">
                    </td>
                    -->
                    <td>
                      <input type="text" size="35" name="maint_desc" value="SUPPORT CONTRACT RENEWAL" disabled="disabled">
                    </td>
                    <td>
                      <input class="number" type="text" id="maint_unit" #maint_unit="ngModel" name="maint_unit" value="" [(ngModel)]='model.maint_unit'
                        (blur)="transformAmount($event)"
                        (focus)="untransformAmount($event)"
                      />
                    </td>
                    <td>
                      <input class="number" type="text" disabled="disabled" size="10" name="maint_ext_field"
                        value="{{ extended(model.maint_qty,model.maint_unit) }}"
                        ([ngModel])="model.maint_ext_field" />
                    </td>
                  </tr>
                  <tr [hidden]="model.mode!='standard'">
                    <td><input class="number" type="text" size="5" name="qty1" #qty1="ngModel" [(ngModel)]="model.qty1"></td>
                    <td><input type="text" size="25" id="desc1" name="desc1" #desc1="ngModel" [(ngModel)]="model.desc1" required></td>
                    <td>
                      <select id="ver1" class="form-control" name="ver1_edit" #ver1_edit="ngModel" [(ngModel)]="model.ver1" required>
                        <option *ngFor="let v of versions['default']" [value]="v.id" [selected]="v.selected" [disabled]="v.disabled">{{v.name}}</option>
                      </select>
                    </td>
                    <td><input class="number" type="text" size="10" #unit1="ngModel" name="unit1" [(ngModel)]="model.unit1" required
                      (blur)="transformAmount($event)"
                      (focus)="untransformAmount($event)" />
                    </td>
                    <td><input class="number" disabled="disabled" type="text" size="10" name="ext_field_1" #ext_field_1="ngModel"
                      [(ngModel)]="model.ext_field_1"
                      value="{{ model.qty1 * model.unit1 | currency:'USD':'symbol':'1.2' }}" />
                    </td>
                  </tr>
                  <tr>
                    <td [hidden]="model.mode!='standard'" colspan="3">&nbsp;</td> <!-- standard orders 4 padding columns -->
                    <td [hidden]="model.mode!='maint_renew'" colspan="1">&nbsp;</td> <!-- maint renewal 3 padding columns -->
                    <td [hidden]="model.mode!='subscription' || model.sub_type!=''" colspan="3">&nbsp;</td> <!-- subscription but no sub type yet -->
                    <td [hidden]="model.mode!='subscription' || model.sub_type!='addon'" colspan="4">&nbsp;</td> <!-- subscription add ons 5 columns-->
                    <td [hidden]="model.mode!='subscription' || model.sub_type!='new'" colspan="3">&nbsp;</td> <!-- subscription new 3 cols -->
                    <td style="text-align: right;"><b>Total USD:</b></td>
                    <td *ngIf="model.mode=='subscription' && model.sub_cloud_desc?.includes('usage')">
                      <input class="number" type="text" disabled="disabled" size="10" name="total" value="N/A"
                        [(ngModel)]="model.total" #total="ngModel" />
                    </td>
                    <td *ngIf="model.mode!='subscription' || (!model.sub_cloud_desc?.includes('usage') || model.sub_type=='addon')">
                      <input class="number" type="text" disabled="disabled" size="10" name="total" id="total"
                        [(ngModel)]="model.total" #total="ngModel"/>
                    </td>
                    <!--
                    <td *ngIf="model.mode!='subscription'">
                      <input class="number" disabled="disabled" type="text" size="10" name="total"
                             #total="ngModel" [(ngModel)]="model.total"
                      />
                    </td>
                    -->
                  </tr>
                </tbody>
              </table>
              <!-- sub_dur is sub_desc now
              <div *ngIf="model.mode=='subscription' && model.sub_tenancy=='cloud' && !sub_dur.valid && (sub_dur.dirty || sub_dur.touched || f.submitted)" class="help-block">
                Subscription duration must be selected.
              </div>
              -->
              <div *ngIf="(model.mode=='subscription' && model.sub_qty!=undefined && isNotANumber(model.sub_qty))
                        ||(model.mode=='standard' && model.qty1!=undefined && isNotANumber(model.qty1))
                        ||(model.mode=='standard' && model.qty2!=undefined && isNotANumber(model.qty2))"
                        class="help-block">
                To continue, please specify a numeric number of users.
              </div>
              <div *ngIf="(model.mode=='subscription' && model.sub_qty=='' && (sub_qty.dirty||sub_qty.touched||f.submitted))
                        ||(model.mode=='standard' && model.qty1=='' && (qty1.dirty||qty1.touched||f.submitted))"
                        class="help-block">
                To continue, please provide the number of <span [hidden]="model.sub_type!='addon'">Added </span>users.
              </div>
              <div *ngIf="(model.mode=='subscription' && model.sub_qty!='' && model.sub_qty<=0)
                        ||(model.mode=='standard' && model.qty1!='' && model.qty1<=0)
                        ||(model.mode=='standard' && model.qty2!='' && model.qty2<=0)"
                        class="help-block">
                To continue, please specify a number of <span [hidden]="model.sub_type!='addon'">Added </span>users that is greater than zero.
              </div>
              <div *ngIf="(model.mode=='subscription' && model.sub_type=='new' && model.sub_tenancy=='cloud' && !sub_cloud_desc.valid && (sub_cloud_desc.dirty || sub_cloud_desc.touched || f.submitted))
                        ||(model.mode=='subscription' && model.sub_type=='new' && model.sub_tenancy=='onprem' && !sub_onprem_desc.valid && (sub_onprem_desc.dirty || sub_onprem_desc.touched || f.submitted))"
                        class="help-block">
                To continue, please specify a License Description.
              </div>
              <div *ngIf="(model.mode=='standard' && !desc1.valid && (desc1.dirty || desc1.touched || f.submitted))" class="help-block">
                To continue, please select the Part Number for this order.
              </div>
              <!--
              <div *ngIf="(model.mode=='subscription' && model.sub_type=='new' && !sub_dur.valid &&
                (sub_dur.dirty || sub_dur.touched || f.submitted))" class="help-block">
                Part Number/Description must be specified.
              </div>
              -->
              <div *ngIf="(model.mode=='subscription' && model.sub_type=='new' && !sub_ver_edit.valid &&
                (sub_ver_edit.dirty || sub_ver_edit.touched || f.submitted))" class="help-block">
                To continue, please select the product release number for this order.
              </div>
              <div *ngIf="(model.mode=='subscription' && model.sub_mode=='new' && !sub_ver.valid && (sub_ver.dirty || sub_ver.touched || f.submitted))"
                        class="help-block">
                To continue, please select the product release number for this order.
              </div>
              <div *ngIf="(model.mode=='standard' && !ver1_edit.valid && (ver1_edit.dirty || ver1_edit.touched || f.submitted))"
                        class="help-block">
                To continue, please sleect the product and release number for this order.
              </div>
              <div *ngIf="(model.mode=='subscription' && model.sub_units!='$0.00' && model.sub_units!=undefined && isNotANumber(model.sub_units))
                        ||(model.mode=='standard' && model.unit1!=undefined && isNotANumber(model.unit1))
                        ||(model.mode=='maint_renew' && model.maint_unit!=undefined && isNotANumber(model.maint_unit))"
                        class="help-block">
                To continue, please enter a valid unit price.
              </div>
              <div *ngIf="model.mode=='subscription' && model.sub_type=='new' && model.sub_tenancy=='cloud' && model.sub_origin=='new' &&
                        model.sub_cloud_desc.includes('annual') && (model.sub_units=='' || model.sub_units==0 || model.sub_units=='$0.00') &&
                        (sub_units.dirty||sub_units.touched||f.submitted)" class="help-block">
                To continue, please enter a valid unit price.
              </div>
              <div *ngIf="(model.mode=='subscription' && model.sub_type!='new' && model.sub_units=='' && (sub_units.dirty||sub_units.touched||f.submitted))
                        ||(model.mode=='standard' && model.unit1=='' && (unit1.dirty||unit1.touched||f.submitted))
                        ||(model.mode=='maint_renew' && model.maint_unit=='' && (maint_unit.dirty||maint_unit.touched||f.submitted))"
                        class="help-block">
                To continue, please enter a valid unit price.
              </div>
              <div *ngIf="(model.mode=='subscription' && model.sub_units!='' && (model.sub_units<=0 || model.sub_units=='$0.00') && !model.sub_cloud_desc?.includes('usage'))
                        ||(model.mode=='standard' && model.unit1!='' && model.unit1<=0)
                        ||(model.mode=='maint_renew' && model.maint_unit<=0)"
                        class="help-block">
                To continue, please enter a valid unit price.
              </div>
              <div *ngIf="model.mode=='subscription' && model.sub_cloud_desc && !model.sub_cloud_desc.includes('usage') && this.numericTotal<=0 && (sub_units.dirty||sub_units.touched||f.submitted)"
                class="help-block">
                To continue, enter the correct pricing for this order.
              </div>
              <div *ngIf="model.mode=='standard' && this.numericTotal<=0 && (unit1.touched||unit1.dirty||f.submitted)"
                class="help-block">
                To continue, enter the correct pricing for this order.
              </div>
              <div *ngIf="model.mode=='maint_renew' && this.numericTotal<=0 && (maint_unit.touched||maint_unit.dirty||f.submitted)"
                class="help-block">
                To continue, enter the correct pricing for this order.
              </div>
              <div *ngIf="model.mode=='subscription' && model.sub_type=='new' && model.sub_cloud_desc &&
                model.sub_cloud_desc.includes('usage') && +model.sub_qty<500"
                class="help-block">
                Pay-as-You-Go billing requires a minimum order of 500 seats.
              </div>
              <div *ngIf="model.mode=='subscription' && model.sub_type=='new' && model.sub_cloud_desc && model.sub_cloud_desc.includes('usage')"
                  class="notice">
                This license will be billed based on its actual usage according to the price schedule.<br/>
                Enter a value for 'SEATS' that reflects the initial maximum usage allowed.
              </div>
              <div *ngIf="model.mode=='maint_renew'" class="notice">
                Please attach your renewal worksheet or PO below.
              </div>
              <div *ngIf="model.mode=='subscription' && model.sub_addon_license.profile!=undefined &&
                          !addonIsExpired" class="notice">
                Estimated order total, pro-rated {{ model.sub_addon_license.months_left }} months
                to the end of term, is {{ this.addonPrice }}.  GraphOn will confirm before processing.
              </div>
              <div *ngIf="model.mode=='subscription' && model.sub_addon_license.profile!=undefined && addonIsExpired"
                  class="help-block">
                This license is expired.  Contact <a href="orders@graphon.com">orders@graphon.com</a> for assistance.
              </div>
            </td>
          </tr>
          <tr *ngIf="model.mode == 'subscription'">
            <td colspan="2" class="heading">
              Site Administrators
            </td>
          </tr>
          <tr *ngIf="model.mode=='subscription' && model.sub_type == '' || (model.sub_type == 'new' && model.sub_origin=='existing' &&
            (model.sub_existing_license=='' || model.sub_existing_siteadmin=='' || model.sub_existing_trial_license.name == undefined))
            || (model.sub_type=='addon' && (model.sub_addon_existing=='' || model.sub_addon_siteadmin == '' ||
            model.sub_addon_license == undefined || model.sub_addon_license_error != ''))">
            <td colspan="2">
              <div>
                <em>Please complete the fields above to enable this section.</em>
              </div>
            </td>
          </tr>
          <tr *ngIf="model.sub_addon_license.siteadmin1 != '' && model.sub_addon_license.siteadmin1 != undefined">
            <td><b>Site Admin 1:</b></td>
            <td>
              <input id="siteadmin1" name="siteadmin1" type="text" size="40"
                [(ngModel)]="model.sub_addon_license.siteadmin1.email"
                #siteadmin1="ngModel" disabled="disabled">
              <br/>
              <b class="indent">Name:</b>&nbsp;
              <input id="siteadmin1_firstname" name="siteadmin1_firstname" type="text" size="25"
                [(ngModel)]="model.sub_addon_license.siteadmin1.firstname"
                #siteadmin1_firstname="ngModel" disabled="disabled">
              &nbsp;
              <input id="siteadmin1_lastname" name="siteadmin1_lastname" type="text" size="25"
                [(ngModel)]="model.sub_addon_license.siteadmin1.lastname"
                #siteadmin1_lastname="ngModel" disabled="disabled">
            </td>
          </tr>
          <tr *ngIf="(model.sub_existing_trial_license.siteadmin1 != '' && model.sub_existing_trial_license.siteadmin1 != undefined)">
            <td><b>Site Admin 1:</b></td>
            <td>
              <input id="siteadmin1" name="siteadmin1" type="text" size="40"
                [(ngModel)]="model.sub_existing_trial_license.siteadmin1.email"
                #siteadmin1="ngModel" disabled="disabled">
              <br/>
              <b class="indent">Name:</b>&nbsp;
              <input id="siteadmin1_firstname" name="siteadmin1_firstname" type="text" size="25"
                [(ngModel)]="model.sub_existing_trial_license.siteadmin1.firstname"
                #siteadmin1_firstname="ngModel" disabled="disabled">
              &nbsp;
              <input id="siteadmin1_lastname" name="siteadmin1_lastname" type="text" size="25"
                [(ngModel)]="model.sub_existing_trial_license.siteadmin1.lastname"
                #siteadmin1_lastname="ngModel" disabled="disabled">
            </td>
          </tr>
          <tr *ngIf="(model.sub_type=='addon' && model.sub_addon_license.name != undefined && (model.sub_addon_license.siteadmin1 == undefined || model.sub_addon_license.siteadmin1 == ''))
            || (model.sub_type=='new' && model.sub_origin=='existing' && (model.sub_existing_trial_license.siteadmin1 == undefined || model.sub_existing_license.siteadmin1 == ''))
            || (model.sub_type=='new' && model.sub_origin != 'existing')">
            <td><b>Site Admin 1<b class="red">*</b>:</b></td>
            <td>
              <input id="siteadmin1" name="siteadmin1" type="text" size="40"
                [(ngModel)]="model.siteadmin1" email externalEmail required
                #siteadmin1="ngModel" (blur)="checkContact($event)"
                (keyup)="updateContact($event)" placeholder="E-mail address">
              <br />
              <span *ngIf="siteadmin1.valid && model.notfound.siteadmin1 && !model.duplicateContact.siteadmin1">
                <b class="indent">Name<b class="red">*</b>:</b>
                <input id="siteadmin1_firstname" name="siteadmin1_firstname"
                  type="text" size="25" [(ngModel)]="model.siteadmin1_firstname"
                  #siteadmin1_firstname="ngModel"
                  placeholder="Enter new contact first name" required>&nbsp;
                <input id="siteadmin1_lastname" name="siteadmin1_lastname"
                  type="text" size="25" [(ngModel)]="model.siteadmin1_lastname"
                  #siteadmin1_lastname="ngModel"
                  placeholder="Enter new contact last name" required>
                  <div class="help-block" *ngIf="siteadmin1.valid && model.notfound.siteadmin1 &&
                    (!siteadmin1_firstname.valid || !siteadmin1_lastname.valid)">
                    This e-mail address is not on file.
                    To continue, please enter a name for this new contact.
                  </div>
                  <div *ngIf="siteadmin1.valid && model.notfound.siteadmin1 && siteadmin1_firstname.valid && siteadmin1_lastname.valid" class="notice">
                    This e-mail address is not on file. A new contact will be created.
                  </div>
              </span>
              <span *ngIf="siteadmin1.valid && model.found.siteadmin1 != undefined">
                <b class="indent">Name:</b>&nbsp;
                <input *ngIf="siteadmin1.valid && model.found.siteadmin1 != undefined"
                  id="siteadmin1_firstname" name="siteadmin1_firstname" type="text" size="25"
                  [(ngModel)]="model.found.siteadmin1.firstname" disabled="disabled">
                &nbsp;
                <input *ngIf="siteadmin1.valid && model.found.siteadmin1 != undefined"
                  id="siteadmin1_lastname" name="siteadmin1_lastname" type="text" size="25"
                  [(ngModel)]="model.found.siteadmin1.lastname" disabled="disabled">
              </span>
              <span *ngIf="model.duplicateContact.siteadmin1 != undefined">
                <b class="indent">Name:</b>&nbsp;
                <input id="siteadmin1_firstname" name="siteadmin1_firstname" type="text" size="25"
                  [(ngModel)]="model.duplicateContact.siteadmin1_firstname" disabled="disabled">&nbsp;
                <input id="siteadmin2_lastname" name="siteadmin1_lastname" type="text" size="25"
                  [(ngModel)]="model.duplicateContact.siteadmin1_lastname" disabled="disabled">
              </span>
              <div class="help-block" *ngIf="siteadmin1.errors?.externalvalid">
                {{ siteadmin1.errors.externalvalid }}
              </div>
              <div class="help-block" *ngIf="!siteadmin1.valid && (siteadmin1.dirty
                || siteadmin1.touched || f.submitted) && !siteadmin1.errors?.externalvalid">
                To continue, please enter a valid e-mail address.
              </div>
              <div class="help-block" *ngIf="siteadmin1.valid && model.found.siteadmin1 &&
                ((model.sub_type=='addon' && model.sub_addon_license.account != undefined && model.sub_addon_license.account.id!=model.found.siteadmin1.account.id)
                || (model.sub_type=='new' && model.sub_existing_trial_license?.account != undefined && model.sub_existing_trial_license.account.id!=model.found.siteadmin1.account.id))">
                The requested site administrator's account ({{ model.found.siteadmin1.account.name }})
                is not the<br/>specified license's account (<span *ngIf="model.sub_type=='addon'">{{ model.sub_addon_license.account.name }}</span>
                <span *ngIf="model.sub_type=='new'">{{ model.sub_existing_trial_license.account.name }}</span>).
              </div>

            </td>
          </tr>
          <tr *ngIf="model.sub_addon_license.siteadmin2 != '' && model.sub_addon_license.siteadmin2 != undefined">
            <td><b>Site Admin 2:</b></td>
            <td>
              <input id="siteadmin2" name="siteadmin2" type="text" size="40"
                [(ngModel)]="model.sub_addon_license.siteadmin2.email"
                #siteadmin2="ngModel" disabled="disabled">
              <br />
              <b class="indent">Name:</b>&nbsp;
              <input id="siteadmin2_firstname" name="siteadmin2_firstname" type="text" size="25"
                [(ngModel)]="model.sub_addon_license.siteadmin2.firstname"
                #siteadmin2_firstname="ngModel" disabled="disabled">
              &nbsp;
              <input id="siteadmin2_lastname" name="siteadmin2_lastname" type="text" size="25"
                [(ngModel)]="model.sub_addon_license.siteadmin2.lastname"
                #siteadmin2_lastname="ngModel" disabled="disabled">
            </td>
          </tr>
          <tr *ngIf="(model.sub_existing_trial_license.siteadmin2 != '' && model.sub_existing_trial_license.siteadmin2 != undefined)">
            <td><b>Site Admin 2:</b></td>
            <td>
              <input id="siteadmin2" name="siteadmin2" type="text" size="40"
                [(ngModel)]="model.sub_existing_trial_license.siteadmin2.email"
                #siteadmin2="ngModel" disabled="disabled">
              <br />
              <b class="indent">Name:</b>&nbsp;
              <input id="siteadmin2_firstname" name="siteadmin2_firstname" type="text" size="25"
                [(ngModel)]="model.sub_existing_trial_license.siteadmin2.firstname"
                #siteadmin2_firstname="ngModel" disabled="disabled">
              &nbsp;
              <input id="siteadmin2_lastname" name="siteadmin2_lastname" type="text" size="25"
                [(ngModel)]="model.sub_existing_trial_license.siteadmin2.lastname"
                #siteadmin2_lastname="ngModel" disabled="disabled">
            </td>
          </tr>
          <tr *ngIf="(model.sub_type=='addon' && model.sub_addon_license.name != undefined && (model.sub_addon_license.siteadmin2 == undefined || model.sub_addon_license.siteadmin2 == ''))
            || (model.sub_type=='new' && model.sub_origin=='existing' && (model.sub_existing_trial_license.siteadmin2 == undefined || model.sub_existing_license.siteadmin2 == ''))
            || (model.sub_type=='new' && model.sub_origin != 'existing')">
            <td><b>Site Admin 2:</b></td>
            <td>
              <input id="siteadmin2" name="siteadmin2" type="text" size="40"
                [(ngModel)]="model.siteadmin2" email externalEmail
                #siteadmin2="ngModel" (blur)="checkContact($event)"
                (keyup)="updateContact($event)" placeholder="E-mail address">
              <br/>
              <span *ngIf="siteadmin2.valid && model.found.siteadmin2 != undefined">
                <b class="indent">Name:</b>&nbsp;
                <input *ngIf="siteadmin2.valid && model.found.siteadmin2 != undefined"
                  id="siteadmin2_firstname" name="siteadmin2_firstname" type="text" size="25"
                  [(ngModel)]="model.found.siteadmin2.firstname" disabled="disabled">
                &nbsp;
                <input *ngIf="siteadmin2.valid && model.found.siteadmin2 != undefined"
                  id="siteadmin2_lastname" name="siteadmin2_lastname" type="text" size="25"
                  [(ngModel)]="model.found.siteadmin2.lastname" disabled="disabled">
              </span>
              <span *ngIf="siteadmin2.valid && model.notfound.siteadmin2 && !model.duplicateContact.siteadmin2">
                <b class="indent">Name<b class="red">*</b>:</b>&nbsp;
                <input id="siteadmin2_firstname" name="siteadmin2_firstname"
                  type="text" size="25" [(ngModel)]="model.siteadmin2_firstname"
                  #siteadmin2_firstname="ngModel"
                  placeholder="Enter new contact first name" required>&nbsp;
                <input id="siteadmin2_lastname" name="siteadmin2_lastname"
                  type="text" size="25" [(ngModel)]="model.siteadmin2_lastname"
                  #siteadmin2_lastname="ngModel"
                  placeholder="Enter new contact last name" required>
                <div class="help-block" *ngIf="siteadmin2.valid && model.notfound.siteadmin2 && (!siteadmin2_firstname.valid || !siteadmin2_lastname.valid)">
                  This e-mail address is not on file.
                  To continue, please enter a name for this new contact.
                </div>
                <div class="notice" *ngIf="siteadmin2.valid && model.notfound.siteadmin2 && siteadmin2_firstname.valid && siteadmin2_lastname.valid">
                  This e-mail address is not on file. A new contact will be created.
                </div>
              </span>
              <span *ngIf="siteadmin2.valid && model.duplicateContact.siteadmin2 != undefined">
                <b class="indent">Name:</b>&nbsp;
                <input id="siteadmin2_firstname" name="siteadmin2_firstname"
                  type="text" size="25" [(ngModel)]="model.duplicateContact.siteadmin2_firstname"
                  disabled="disabled">&nbsp;
                <input id="siteadmin2_lastname" name="siteadmin2_lastname"
                  type="text" size="25" [(ngModel)]="model.duplicateContact.siteadmin2_lastname"
                  disabled="disabled">
              </span>
              <div *ngIf="siteadmin2.errors?.externalvalid" class="help-block">
                {{ siteadmin2.errors.externalvalid }}
              </div>
              <div *ngIf="!siteadmin2.valid && (siteadmin2.dirty
                || siteadmin2.touched || f.submitted) && !siteadmin2.errors?.externalvalid"
                class="help-block">
                A valid e-mail address is required.
              </div>
              <div class="help-block" *ngIf="siteadmin2.valid && model.found.siteadmin2 &&
                ((model.sub_type=='addon' && model.found.siteadmin2.account.name!='GO-GLOBAL TRIALS' &&
                model.sub_addon_license.account.id!=model.found.siteadmin2.account.id)
                || (model.sub_type=='new' && model.sub_origin=='existing' && model.found.siteadmin2.account.name!='GO-GLOBAL TRIALS' &&
                 model.sub_existing_trial_license.account.id!=model.found.siteadmin2.account.id))">
                The requested site administrator's account ({{ model.found.siteadmin2.account.name }})
                is not the<br/>specified license's account (<span *ngIf="model.sub_type=='addon'">{{ model.sub_addon_license.account.name }}</span>
                <span *ngIf="model.sub_type=='new'">{{ model.sub_existing_trial_license.account.name }}</span>).<br />
                Please contact <a href="mailto:licenses@graphon.com">licenses@graphon.com</a> to add this site administrator.
              </div>
            </td>
          </tr>
          <tr *ngIf="model.sub_addon_license.siteadmin3 != '' && model.sub_addon_license.siteadmin3 != undefined">
            <td><b>Site Admin 3:</b></td>
            <td>
              <input id="siteadmin3" name="siteadmin3" type="text" size="40"
                [(ngModel)]="model.sub_addon_license.siteadmin3"
                #siteadmin3="ngModel" disabled="disabled">
              <br/>
              <b class="indent">Name:</b>&nbsp;
              <input id="siteadmin3_firstname" name="siteadmin3_firstname" type="text" size="25"
                [(ngModel)]="model.sub_addon_license.siteadmin3.firstname"
                #siteadmin3_firstname="ngModel" disabled="disabled">
              &nbsp;
              <input id="siteadmin3_lastname" name="siteadmin3_lastname" type="text" size="25"
                [(ngModel)]="model.sub_addon_license.siteadmin3.lastname"
                #siteadmin3_lastname="ngModel" disabled="disabled">
            </td>
          </tr>
          <tr *ngIf="model.sub_existing_trial_license.siteadmin3 != '' && model.sub_existing_trial_license.siteadmin3 != undefined">
            <td><b>Site Admin 3:</b></td>
            <td>
              <input id="siteadmin3" name="siteadmin3" type="text" size="40"
                [(ngModel)]="model.sub_existing_trial_license.siteadmin3.email"
                #siteadmin3="ngModel" disabled="disabled">
              <br/>
              <b class="indent">Name:</b>&nbsp;
              <input id="siteadmin3_firstname" name="siteadmin3_firstname" type="text" size="25"
                [(ngModel)]="model.sub_existing_trial_license.siteadmin3.firstname"
                #siteadmin3_firstname="ngModel" disabled="disabled">
              &nbsp;
              <input id="siteadmin3_lastname" name="siteadmin3_lastname" type="text" size="25"
                [(ngModel)]="model.sub_existing_trial_license.siteadmin3.lastname"
                #siteadmin3_lastname="ngModel" disabled="disabled">
            </td>
          </tr>
          <tr *ngIf="(model.sub_type=='addon' && model.sub_addon_license.name != undefined && (model.sub_addon_license.siteadmin3 == undefined || model.sub_addon_license.siteadmin3 == ''))
            || (model.sub_type=='new' && model.sub_origin=='existing' && (model.sub_existing_trial_license.siteadmin3 == undefined || model.sub_existing_license.siteadmin3 == ''))
            || (model.sub_type=='new' && model.sub_origin != 'existing')">
            <td><b>Site Admin 3:</b></td>
            <td>
              <input id="siteadmin3" name="siteadmin3" type="text" size="40"
                [(ngModel)]="model.siteadmin3" email externalEmail
                #siteadmin3="ngModel" (blur)="checkContact($event)"
                (keyup)="updateContact($event)" placeholder="E-mail address">
              <br/>
              <span *ngIf="siteadmin3.valid && model.notfound.siteadmin3">
                <b class="indent">Name<b class="red">*</b>:</b>&nbsp;
                <input id="siteadmin3_firstname" name="siteadmin3_firstname"
                  type="text" size="25" [(ngModel)]="model.siteadmin3_firstname"
                  #siteadmin3_firstname="ngModel"
                  placeholder="Enter new contact first name" required>&nbsp;
                <input id="siteadmin3_lastname" name="siteadmin3_lastname"
                  type="text" size="25" [(ngModel)]="model.siteadmin3_lastname"
                  #siteadmin3_lastname="ngModel"
                  placeholder="Enter new contact last name required">
                <div class="help-block" *ngIf="siteadmin3.valid && model.notfound.siteadmin3 && (!siteadmin3_firstname.valid || !siteadmin3_lastname.valid)">
                  This e-mail address is not on file.
                  To continue, please enter a name for this new contact.
                </div>
                <div class="notice" *ngIf="siteadmin3.valid && model.notfound.siteadmin3 && siteadmin3_firstname.valid && siteadmin3_lastname.valid">
                  This e-mail address is not on file. A new contact will be created.
                </div>
              </span>
              <span *ngIf="siteadmin3.valid && model.found.siteadmin3 != undefined && !model.duplicateContact.siteadmin3">
                <b class="indent">Name:</b>&nbsp;
                <input *ngIf="siteadmin3.valid && model.found.siteadmin3 != undefined"
                  id="siteadmin3_firstname" name="siteadmin3_firstname" type="text" size="25"
                  [(ngModel)]="model.found.siteadmin3.firstname" disabled="disabled">
                &nbsp;
                <input *ngIf="siteadmin3.valid && model.found.siteadmin3 != undefined"
                  id="siteadmin3_lastname" name="siteadmin3_lastname" type="text" size="25"
                  [(ngModel)]="model.found.siteadmin3.lastname" disabled="disabled">
              </span>
              <span *ngIf="siteadmin3.valid && model.duplicateContact.siteadmin3 != undefined">
                <b class="indent">Name:</b>&nbsp;
                <input id="siteadmin3_firstname" name="siteadmin3_firstanem" type="text" size="25"
                  [(ngModel)]="model.duplicateContact.siteadmin3_firstname" disabled="disabled">&nbsp;
                <input id="siteadmin3_lastname" name="siteadmin3_lastname" type="text" size="25"
                  [(ngModel)]="model.duplicateContact.siteadmin3_lastname" disabled="disabled">
              </span>
              <div *ngIf="siteadmin3.errors?.externalvalid" class="help-block">
                {{ siteadmin3.errors.externalvalid }}
              </div>
              <div class="help-block" *ngIf="!siteadmin3.valid && (siteadmin3.dirty
                || siteadmin3.touched || f.submitted) && !siteadmin3.errors?.externalvalid">
                A valid e-mail address is required.
              </div>
              <div class="help-block" *ngIf="siteadmin3.valid && model.found.siteadmin3 &&
                ((model.sub_type=='addon' && model.found.siteadmin3.account.name!='GO-GLOBAL TRIALS' &&
                model.sub_addon_license.account.id!=model.found.siteadmin3.account.id)
                || (model.sub_type=='new' && model.sub_origin=='existing' && model.found.siteadmin3.account.name!='GO-GLOBAL TRIALS' &&
                 model.sub_existing_trial_license.account.id!=model.found.siteadmin3.account.id))">
                The requested site administrator's account ({{ model.found.siteadmin3.account.name }})
                is not the<br/>specified license's account (<span *ngIf="model.sub_type=='addon'">{{ model.sub_addon_license.account.name }}</span>
                <span *ngIf="model.sub_type=='new'">{{ model.sub_existing_trial_license.account.name }}</span>).<br />
                Please contact <a href="mailto:licenses@graphon.com">licenses@graphon.com</a> to add this site administrator.
              </div>
            </td>
          </tr>
          <tr [hidden]="model.mode!='standard'">
            <td colspan="2" class="heading">
              Product Code Delivery
            </td>
          </tr>
          <tr [hidden]="model.mode!='standard'">
            <td colspan="2">
              <i>In addition to the Purchasing Contact, e-mail product code to:</i>
            </td>
          </tr>
          <tr [hidden]="model.mode!='subscription'">
            <td colspan="2" class="heading">License Notification Delivery</td>
          </tr>
          <tr [hidden]="model.mode!='subscription'">
            <td colspan="2">
              <i>In addition to the Purchasing Contact
                <span [hidden]="model.mode!='subscription'">
                   and Site Administrators
                </span>, e-mail license order notification to:
              </i>
            </td>
          </tr>
          <tr [hidden]="model.mode=='maint_renew'">
            <td><b>CC&nbsp;1:</b>
            <td>
              <input id="pcode_cc1" name="pcode_cc1" type="text" size="40"
                [(ngModel)]="model.pcode_cc1" (blur)="checkContact($event)" (keyup)="updateContact($event)"
                email externalEmail #pcode_cc1="ngModel" placeholder="E-mail address">
              <br />
              <div chass="help-block" *ngIf="pcode_cc1.errors?.externalvalid">
                {{ pcode_cc1.errors.externalvalid }}
              </div>
              <div class="help-block" *ngIf="!pcode_cc1.valid && (pcode_cc1.dirty
                || pcode_cc1.touched || f.submitted) && !pcode_cc1.errors?.externalvalid">
                A valid e-mail address is required.
              </div>
              <span *ngIf="pcode_cc1.valid && model.notfound.pcode_cc1 && !model.duplicateContact.pcode_cc1">
                <b class="indent">Name<b class="red">*</b>:</b>&nbsp;
                <input id="pcode_cc1_firstname" name="pcode_cc1_firstname" type="text" size="25"
                  [(ngModel)]="model.pcode_cc1_firstname" placeholder="Enter new contact first name"
                  #pcode_cc1_firstname="ngModel" required>&nbsp;
                <input id="pcode_cc1_lastname" name="pcode_cc1_lastname" type="text" size="25"
                  [(ngModel)]="model.pcode_cc1_lastname" placeholder="Enter new contact last name"
                  #pcode_cc1_lastname="ngModel" required>
                <br/>
                <div *ngIf="!pcode_cc1_firstname.valid || !pcode_cc1_lastname.valid" class="help-block">
                  This e-mail address is not on file. Please enter a name for this new contact.
                </div>
                <div *ngIf="pcode_cc1_firstname.valid && pcode_cc1_lastname.valid" class="notice">
                  This e-mail address is not on file. A new contact will be created.
                </div>
              </span>
              <span *ngIf="model.found.pcode_cc1 != undefined">
                <b class="indent">Name:</b>&nbsp;
                <input id="pcode_cc1_firstname" name="pcode_cc1_firstname" type="text" size="25"
                  [(ngModel)]="model.found.pcode_cc1.firstname" disabled="disabled">&nbsp;
                <input id="pcode_cc1_lastname" name="pcode_cc1_lastname" type="text" size="25"
                  [(ngModel)]="model.found.pcode_cc1.lastname" disabled="disabled">
              </span>
              <span *ngIf="model.duplicateContact.pcode_cc1 != undefined">
                <b class="indent">Name:</b>&nbsp;
                <input id="pcode_cc1_firstname" name="pcdoe_cc1_firstname" type="text" size="25"
                  [(ngModel)]="model.duplicateContact.pcode_cc1_firstname" disabled="disabled">&nbsp;
                <input id="pcode_cc1_lastname" name="pcode_cc1_lastname" type="text" size="25"
                  [(ngModel)]="model.duplicateContact.pcode_cc1_lastname" disabled="disabled">
              </span>
            </td>
          </tr>
          <tr [hidden]="model.mode=='maint_renew'">
            <td><b>CC&nbsp;2:</b></td>
            <td>
              <input id="pcode_cc2" name="pcode_cc2" type="text" size="40"
                [(ngModel)]="model.pcode_cc2" (blur)="checkContact($event)" (keyup)="updateContact($event)"
                email externalEmail #pcode_cc2="ngModel" placeholder="E-mail address">
              <br/>
              <div class="help-block" *ngIf="pcode_cc2.errors?.externalvalid">
                {{ pcode_cc2.errors.externalvalid }}
              </div>
              <div class="help-block" *ngIf="!pcode_cc2.valid && (pcode_cc2.dirty
                || pcode_cc2.touched || f.submitted) && !pcode_cc2.errors?.externalvalid">
                A valid e-mail address is required.
              </div>
              <span *ngIf="pcode_cc2.valid && model.notfound.pcode_cc2 && !model.duplicateContact.pcode_cc2">
                <b class="indent">Name<b class="red">*</b>:</b>&nbsp;
                <input id="pcode_cc2_firstname" name="pcode_cc2_firstname" type="text" size="25"
                  [(ngModel)]="model.pcode_cc2_firstname" placeholder="Enter new contact first name"
                  #pcode_cc2_firstname="ngModel" required>&nbsp;
                <input id="pcode_cc2_lastname" name="pcode_cc2_lastname" type="text" size="25"
                  [(ngModel)]="model.pcode_cc2_lastname" placeholder="Enter new contact last name"
                  #pcode_cc2_lastname="ngModel" required>
                <br/>
                <div *ngIf="!pcode_cc2_firstname.valid || !pcode_cc2_lastname.valid" class="help-block">
                  This e-mail address is not on file. Please enter a name for this new contact.
                </div>
                <div *ngIf="pcode_cc2_firstname.valid && pcode_cc2_lastname.valid" class="notice">
                  This e-mail address is not on file. A new contact will be created.
                </div>
              </span>
              <span *ngIf="model.found.pcode_cc2 != undefined">
                <b class="indent">Name:</b>&nbsp;
                <input id="pcode_cc2_firstname" name="pcode_cc2_firstname" type="text" size="25"
                  [(ngModel)]="model.found.pcode_cc2.firstname" disabled="disabled">&nbsp;
                <input id="pcode_cc2_lastname" name="pcode_cc2_lastname" type="text" size="25"
                  [(ngModel)]="model.found.pcode_cc2.lastname" disabled="disabled">
              </span>
              <span *ngIf="model.duplicateContact.pcode_cc2 != undefined">
                <b class="indent">Name:</b>&nbsp;
                <input id="pcode_cc2_firstname" name="pcode_cc2_firstname" type="text" size="25"
                  [(ngModel)]="model.duplicateContact.pcode_cc2_firstname" disabled="disabled">&nbsp;
                <input id="pcode_cc2_lastname" name="pcode_cc2_lastname" type="text" size="25"
                  [(ngModel)]="model.duplicateContact.pcode_cc2_lastname" disabled="disabled">
              </span>
            </td>
          </tr>
          <tr [hidden]="model.mode=='maint_renew'">
            <td><b>CC&nbsp;3:</b></td>
            <td>
              <input id="pcode_cc3" name="pcode_cc3" type="text" size="40"
                [(ngModel)]="model.pcode_cc3" (blur)="checkContact($event)" (keyup)="updateContact($event)"
                email externalEmail #pcode_cc3="ngModel" placeholder="E-mail address">
              <br/>
              <div class="help-block" *ngIf="pcode_cc3.errors?.externalvalid">
                {{ pcode_cc3.errors.externalvalid }}
              </div>
              <div class="help-block" *ngIf="!pcode_cc3.valid && (pcode_cc3.dirty
                || pcode_cc3.touched || f.submitted) && !pcode_cc3.errors?.externalvalid">
                A valid e-mail address is required.
              </div>
              <span *ngIf="pcode_cc3.valid && model.notfound.pcode_cc3 && !model.duplicateContact.pcode_cc3">
                <b class="indent">Name<b class="red">*</b>:</b>&nbsp;
                <input id="pcode_cc3_firstname" name="pcode_cc3_firstname" type="text" size="25"
                  [(ngModel)]="model.pcode_cc3_firstname" placeholder="Enter new contact first name"
                  #pcode_cc3_firstname="ngModel" required>&nbsp;
                <input id="pcode_cc3_lastname" name="pcode_cc3_lastname" type="text" size="25"
                  [(ngModel)]="model.pcode_cc3_lastname" placeholder="Enter new contact last name"
                  #pcode_cc3_lastname="ngModel" required>
                <br/>
                <div *ngIf="!pcode_cc3_firstname.valid || !pcode_cc3_lastname.valid" class="help-block">
                  This e-mail address is not on file. Please enter a name for this new contact.
                </div>
                <div *ngIf="pcode_cc3_firstname.valid && pcode_cc3_lastname.valid" class="notice">
                  This e-mail address is not on file. A new contact will be created.
                </div>
              </span>
              <span *ngIf="model.found.pcode_cc3 != undefined">
                <b class="indent">Name:</b>&nbsp;
                <input id="pcode_cc3_firstname" name="pcode_cc3_firstname" type="text" size="25"
                  [(ngModel)]="model.found.pcode_cc3.firstname" disabled="disabled">&nbsp;
                <input id="pcode_cc3_lastname" name="pcode_cc3_lastname" type="text" size="25"
                  [(ngModel)]="model.found.pcode_cc3.lastname" disabled="disabled">
              </span>
              <span *ngIf="model.duplicateContact.pcode_cc3 != undefined">
                <b class="indent">Name:</b>&nbsp;
                <input id="pcode_cc3_firstname" name="pcode_cc3_firstname" type="text" size="25"
                  [(ngModel)]="model.duplicateContact.pcode_cc3_firstname" disabled="disabled">&nbsp;
                <input id="pcode_cc3_lastname" name="pcode_cc3_lastname" type="text" size="25"
                  [(ngModel)]="model.duplicateContact.pcode_cc3_lastname" disabled="disabled">
              </span>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="heading">
              Special Instructions
            </td>
          </tr>
          <tr>
            <td colspan="2" style="width: 100%; text-align: center;">
              <textarea name="special_instructions" cols="80" rows="10" [(ngModel)]="model.special_instructions"></textarea>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="heading">File Attachments</td>
          </tr>
          <tr>
            <td colspan="2">
              <i>Attach any supporting documents (purchase orders, etc.) before submitting your order.</i>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <div class="dropzone" appDnd (fileDropped)="onFileDropped($event)">
                <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
                <i class="fas fa-upload"></i>
                <h3>Drag and drop file(s) here</h3>
                <h3>or</h3>
                <label for="fileDropRef">Browse for file</label>
              </div>
              <div class="files-list">
                <div class="single-file" *ngFor="let file of model.files; let i = index">
                  <i class="fas fa-regular fa-file-export"></i>
                  <span class="upload-info">
                    <span class="upload-name">
                      {{ file?.name }}
                    </span>
                    <span class="upload-size">
                      {{ formatBytes(file?.size) }}
                    </span>
                    <span *ngIf="file.progress==100" style="cursor: pointer" (click)="deleteFile(i)"><i class="fas fa-trash-alt"></i></span>
                    <app-progress [progress]="file?.progress" [status]="file?.status"></app-progress>
                    <span *ngIf="file.error" style="max-width: 75%; white-space: normal;">
                      {{ file.error }}
                    </span>
                  </span>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="heading">Order Authorization and Submission</td>
          </tr>
          <tr>
            <td colspan="2">
              <div style="font-weight: bold;" id="authorize">
                Order Authorized and Submitted By {{model.billto_contact_name || "(not entered yet)"}}.
                US{{model.total||"0.00"}}.
                Payment Method: {{model.payment_method || "(not selected yet)"}}.
              </div>
              <div style="font-weight: bold;">
                Order details and pricing will be confirmed by GraphOn
                before processing.<br/>
                Discrepancies will be reported to Purchasing Contact.
              </div>
              <br/>
              <input style="height: 42px !important; font-size: 16px; padding: 0 30px !important;" type="submit" name="submit" value="Submit Order">
            </td>
          </tr>
        </tbody>
      </table>
    </form>

  </div>
</div>

<div style="display: none; width: 100%; text-align: right;">
  <button style="line-height: 24px;" (click)="toggleDebug()">Debug</button>
  <pre *ngIf="model.debug==true" style="background:#f0f000; border: solid 1px #800000; white-space: pre-wrap; font-size: 10px; text-align: left;">
Debugging:
{{this.diagnostic}}
  </pre>
</div>
