<div class="wizard">	
	<div class="overlay" *ngIf="loading"></div>
	
	<div class='container' style="width: 100vw; padding: 5px; border-bottom: 1px solid #4472c4;">
		<div class='row'>
			<div class="col-xs-12" style="text-align: center">
				<a href="javascript:void(0)" (click)="goback()" class="backbutton navigation">&larr;</a>
				<img src="logo.png" width="150" />
				
			</div>
		</div>
	</div>
	
	<alert></alert>

	<div class="wrapper">
		<div class="container">
			<div class="row vertical-align">
				<div class="col-xs-6" style="min-width: 400px;">
					<h2>Waiting for account activation.</h2>
					<p>An e-mail has been sent to you with an activation link. Please open that link to	activate your account.</p>
					<div class="form-group text-right buttons">	
						<button class="btn btn-primary" (click)="goback();">Sign in</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>