import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { Observable, interval } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AlertService, PortalService } from '../_services/index';

@Component({
    moduleId: module.id,
    templateUrl: 'awaitActivation.component.html'
})

export class AwaitActivationComponent implements OnDestroy {
    gatewayUrl: string;
    model: any = {};
    subscription: any;
    loading: Boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private portalService: PortalService,
        private alertService: AlertService) {
        this.gatewayUrl = environment.gatewayUrl;
        this.route.params.subscribe((params) => {
            this.model.username = params['username'];
        });
        if ( this.model.username == undefined ) {
            let params = new URLSearchParams(window.location.search);
            console.log('params is %o', params);
            this.model.username = params.paramsMap.get('?username');
            console.log('username initialized to ' + this.model.username);
        }

        if ( this.model.username == undefined ) {
            console.log("no username; not polling");
            return;
        }

        this.model.username = decodeURIComponent(this.model.username);
        console.log("decoded username = ", this.model.username);

        try {
            this.subscription = interval(10000).pipe(startWith(0),
                switchMap(() => {
                    return portalService.queryAccountActivation(this.model.username).map((response) => response);
                })).subscribe((response: string) => {
                    console.log("awaitActivationComponent got resonse: %o", response);
                    if ( response == "true" ) {
                        this.alertService.success('Your account has been activated. You may now sign into your account.', true);
                        this.router.navigate(['/signin']);
                    } else if ( response == "false " ) {
                        // do nothing, this isn't activated yet...
                    } else {
                        // an exception
                        if ( response == "user.not.found" ) {
                            this.router.navigate(['/signup']);
                            this.alertService.error('Your account was not found. Please register to continue.');
                        }
                    }
                });
            } catch (e) {
                console.log("exception checking account activation: " + e);
            }
    }

    resendActivation() {
        if ( this.model.username == undefined ) {
            alert("FIXME: your username was not detected, no activation link could be sent.")
        } else {
            this.portalService.resendActivationLink(this.model.username)
                .subscribe(
                    data => {
                        if ( data ) {
                            this.alertService.success('Your activation link has been re-sent.', true);
                        }
                    },
                    error => {
                        this.alertService.error(error);
                    }
                );
        }
    }

    ngOnDestroy() {
        console.log('unsubscribe from subscription');
        if ( this.subscription ) this.subscription.unsubscribe();
    }
}
