<div *ngIf="loading == true">
  <div style="position: absolute; top: 0; left: 0; width: 100%; height:100%; background: #000000; opacity: 0.8; z-index:9998;">
    <div style="position: absolute; top: 50%; left: 50%; margin: -50px 0px 0px -50px; opacity: 1.0;">
      <figure style="overflow: visible" id="spinner"><div class="spinner"></div></figure>
      <h2 style="color:#ffffff; margin: 0px 0px 0px 35px; font-family: 'Quicksand', Arial, Helvetica, 'Numbus Sans L', sans-serif;">PROCESSING...</h2>
    </div>
  </div>
</div>

<div *ngIf="model.nodes.length == 0 && loading == true">
  <p>Please wait while initializing...</p>
</div>

<div *ngIf="model.nodes.length == 0 && loading == false">
  <p>No experience nodes defined</p>
</div>

<div *ngIf="model.adding">
  <h3>
    <span *ngIf="model.editing==true">Editing </span>
    <span *ngIf="model.editing==false">Adding </span>
    Node
  </h3>
  <form class="form-inline" (ngSubmit)="addNode()">
    <div style="display: grid; grid-template-columns: 5vw auto; gap: 5px; padding: 25px 0 0 25px;">
      <ng-container *ngIf="model.editing == true">
        <div style="grid-column: 1; grid-row: 1;">Id:</div>
        <div style="grid-column: 2; grid-row: 1;">
          <input class="form-control" name="nodeid" #nodeid="ngModel" [(ngModel)]="model.nodeid" (readonly)="model.editing" />
        </div>  
      </ng-container>
      <div style="grid-row: 2; grid-column: 1;">Type:</div>
      <div style="grid-row: 2; grid-column: 2;">
        <select id="nodetype" class="form-control" name="nodetype" #nodetype="ngModel" [(ngModel)]="model.nodetype">
          <option *ngFor="let t of nodeTypes" [value]="t.name">{{ t.name }}</option>]
        </select>
      </div>
      <div style="grid-row: 3; grid-column: 1;">Title:</div>
      <div style="grid-row: 3; grid-column: 2;">
        <input class="form-control" name="nodetitle" #nodetitle="ngModel" [(ngModel)]="model.nodetitle" />
      </div>
      <div style="grid-row: 4; grid-column: 1;">Body:</div>
      <div style="grid-row: 4; grid-column: 2;">
        <textarea id="nodebody" name="nodebody" #nodebody="ngModel" [(ngModel)]="model.nodebody" rows="10" cols="80">
        </textarea>
      </div>
      <div style="grid-row: 5; grid-column: 1;">Extras:</div>
      <div style="grid-row: 5; grid-column: 2;">
        <textarea id="nodeextras" name="nodeextras" #nodeextras="ngModel" [(ngModel)]="model.nodeextras" rows="20" cols="80">
        </textarea>
      </div>
      <div style="grid-row: 6; grid-column: 1;">Keywords:</div>
      <div style="grid-row: 6; grid-column: 2;">
        <input class="form-control" name="nodekeywords" #nodekeywords="ngModel"
          [(ngModel)]="model.nodekeywords" />
      </div>
      <div style="grid-row: 7; grid-column: 2;">
        <button type="submit"><span *ngIf="model.editing == true">Edit</span><span *ngIf="model.editing == false">Create</span> Node</button>
        <button (click)="model.adding = false;">Cancel</button>
      </div>
    </div>
  </form>
</div>

<br/>
<br/>

<div *ngIf="model.nodes.length != 0 && loading == false">
  {{ model.nodes.length }} Nodes:
  <form class="form-inline">
    <table class="form-table">
      <tr>
        <th>Node ID</th>
        <th>Node Type</th>
        <th>Title</th>
        <th>Extras</th>
        <th>Keywords</th>
      </tr>
      <ng-container *ngFor="let n of model.nodes">
        <tr>
          <td>{{ n.id }}</td>
          <td>{{ n.type }}</td>
          <td>{{ n.title }}</td>
          <td>{{ n.extras.substring(0, 100) }}</td>
          <td>{{ n.keywords }}</td>
          <td>
            <button (click)="edit(n.id)">Edit</button>
            <button (click)="delete(n.id)">Delete</button>
          </td>
        </tr>
      </ng-container>
    </table>
  </form>
  
</div>

<button *ngIf="!model.adding" (click)="showAdd()">Add New Node</button>

<div style="display:none;">
<pre>
  {{ this.diagnostic }}
</pre>
</div>