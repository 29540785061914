<div class="container">
    <div class="row">
        <div class="col-xs-2" style="height: 97vh; border-right: 1px solid #4472c4;">
            <img src="icon.png" style="width: 48px;" />
            <ul class="leftmenu">
                <li class="active"><a [routerLink]="['/portal']">Home</a></li>
                <li *ngIf="currentUser.canManageLicenses == 'true'"><a [routerLink]="['/portal/licenses']">Licenses</a></li>
                <li *ngIf="currentUser.canManageComputers == 'true'"><a [routerLink]="['/portal/computers']">Computers</a></li>
                <li *ngIf="currentUser.canRunApplications == 'true'"><a [routerLink]="['/portal/applications']">Applications</a></li>
                <li *ngIf="currentUser.canDownload == 'true'">Downloads</li>
                <li>Support</li>
            </ul>
            <div class="bottom-align-text">
                <a href="https://www.facebook.com/pages/GraphOn/111510415583369" class="soc socicon-facebook" style="background-color: #3e5b98; color: #ffffff;" title="Facebook"></a>
                <a href="https://twitter.com/Go__Global" class="soc socicon-twitter" style="background-color: #4da7de; color: #ffffff;" title="Twitter"></a>
            </div>
        </div>

        <div class="col-xs-10">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xs-9">
                        <breadcrumb></breadcrumb>
                    </div>
                    <div class="col-xs-3 text-right">
                        <a [routerLink]="['/signin']">Sign out</a>
                    </div>
                </div>
            </div>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
