<div class="wizard">	
	<div class="overlay" *ngIf="loading"></div>
	
	<div class='container' style="width: 100vw; padding: 5px; border-bottom: 1px solid #4472c4;">
		<div class='row'>
			<div class="col-xs-12" style="text-align: center">
				<img src="logo.png" width="150" />
				
			</div>
		</div>
	</div>
	
	<alert></alert>

	<div class="wrapper">
		<div class="container">
			<div class="row vertical-align">
				<div class="col-xs-12">			
					<h2>{{heading}}</h2>
					<h4 class="red">{{warningMessage}}</h4>
				</div>   
			</div>   

			<div class="row vertical-align">
				<div class="col-xs-12" *ngIf="instructionMessage != 'communication.error'">						
					<h4>{{instructionMessage}}</h4>
				</div>
				
				<div class="col-xs-12" *ngIf="instructionMessage == 'communication.error'">						
					<p>
						GO-Global is unable to communicate with GraphOn's online license service. Your network may have a proxy server, firewall or other device that restricts communication with sites on the Internet. To run the GO-Global Trial on this computer, you will need an on-premises trial license file. You can request one by filling out the form at <a href="javascript:void(0)" (click)="externalLink('https://www.graphon.com/contact')">https://www.graphon.com/contact</a> and selecting 'GO-Global Trial' as the Topic.
						<br/><br/>
						Click Close to finish installing GO-Global. You will be able to configure GO-Global and publish applications without a license, but users will not be able to access published applications until a license file is installed.
						<br/><br/>
						If you are able to resolve the issue that is preventing GO-Global from communicating with GraphOn's online license service, you can activate GO-Global later by running the GO-Global Activation Wizard (click Start | GraphOn GO-Global | Activation Wizard).
					</p>
				</div>
			</div>

			<form name="form" (ngSubmit)="f.form.valid && submitRequest()" #f="ngForm" novalidate>
				<div class="form-group" [ngClass]="{ 'has-error': f.submitted }">

				</div>
				<div class="form-group text-right buttons" *ngIf="exitOnly != 'true'">						
					<button [disabled]="loading" class="btn btn-primary">Next</button>
					<a href="javascript:void(0)" (click)="closeapp()" class="btn btn-link">Cancel</a>
				</div>
				
				<div class="form-group text-right buttons" *ngIf="exitOnly == 'true'">						
					<button [disabled]="loading" (click)="closeapp()" class="btn btn-primary">Close</button>
				</div>
			</form>
		</div>
	</div>
</div>
