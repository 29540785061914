<div *ngIf="loading == true">
  <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background:#000000; opacity: 0.8; z-index: 9998;">
    <div style="position: absolute; top: 50%; left: 50%; margin: -50px 0 0 -50px; opacity: 1.0;">
      <figure style="overflow: visible;" id="spinner"><div class="spinner"></div></figure>
      <h2 style="color: #ffffff; margin: 0 0 0 35px; font-family: 'Work Sans', Arial, Helvetica, sans-serif;">PROCESSING...</h2>
    </div>
  </div>
</div>

<div class="menu" style="text-align: center;">
  <div class="second-row" style="flex-grow: unset; width: 32vw; margin: auto;">
    <h1 style="color: #0f3b80;">Activate your GraphOn.com account.</h1>
    <p>This account has not been activated yet. Enter the activation key that was sent to you in e-mail to activate this account.</p>
    <form name="form" (ngSubmit)="f.form.valid && activate()" #f="ngForm" novalidate>
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !key.valid }">
            <!-- <label for="key">Activation Key</label> -->
            <input type="text" class="form-control" name="key" [(ngModel)]="model.key" #key="ngModel" autofocus required placeholder="Activation Key" />
            <div *ngIf="f.submitted && !key.valid" class="help-block">Activation Key is required</div>
        </div>
        <div class="form-group" style="margin-top: 10px;">
            <button [disabled]="loading" class="btn btn-primary">ACTIVATE ACCOUNT</button>
            <a [routerLink]="['/login']"><button type="button" class="btn" style="margin-left: 10px;">CANCEL</button></a>
        </div>
    </form>
  </div>
</div>
