import { Component, Renderer2, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { URLSearchParams } from '@angular/http';

import { AlertService, PortalService } from '../_services/index';

@Component({
    moduleId: module.id,
    templateUrl: 'activateAccount.component.html'
})

export class ActivateAccountComponent implements OnInit {
    model: any = {};
    loading: Boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private portalService: PortalService,
        private alertService: AlertService,
        private renderer2: Renderer2) {
            this.route.params.subscribe((params) => {
                this.model.key = params['key'];
                console.log("subscription updated key to " + this.model.key);
            });
            let params = new URLSearchParams(window.location.search);
            if ( params.paramsMap.get('?ak') ) {
                this.model.key = params.paramsMap.get('?ak');
                console.log('activation key initialized to ' + this.model.key);
            }
        }

    ngOnInit() {
      let autofocus = this.renderer2.selectRootElement('[autofocus]');
      autofocus.focus();
    }

    activate() {
        this.loading = true;
        console.log("activating using %o", this.model);
        this.portalService.activateAccount(this.model.key)
            .subscribe(
                data => {
                    if ( data ) {
                        this.alertService.success('Account Activated.', true);
                        this.router.navigate(['/signin']);
                    }
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
}
