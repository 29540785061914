import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { AlertService, PortalService, ElectronIPC } from '../_services/index';

@Component({
    moduleId: module.id,
    templateUrl: 'wizardShowAccounts.component.html'
})

export class WizardShowAccounts {
	public accounts: string[]; 
    model: any = {};
    loading = false;
	heading: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private portalService: PortalService,
        private alertService: AlertService,
        private ipc: ElectronIPC)	{
			this.heading = localStorage.getItem('currentHeading');
			
			this.portalService.getAccounts()
			.subscribe(
				data => {
					console.log("ShowAccounts:register() got data = %o", data);
					if ( data ) {
						console.log("ShowAccounts: %o", data);
						
						if ( !Array.isArray(data.account) ) {
							// only one account was returned, select it and move on.
							localStorage.setItem('currentAccount', JSON.stringify(data.account));
							console.log("ShowAccounts: coerce to array");
							data.account = [data.account];
							this.router.navigate(['/wizard/showLicenses']);
						}
						
						this.accounts = data.account;
					}
				},
				error => {
					this.alertService.error(error);
					this.loading = false;
				});	
			}

    selectAccount(account: string) {		
		localStorage.setItem('currentAccount', JSON.stringify(account));
        this.loading = true;
		this.router.navigate(['/wizard/showLicenses']);
    }
	
	goback() {
		this.portalService.logout();	
		this.router.navigate(['/wizard/prewelcome']);
	}
	
	closeapp() {
		this.portalService.logout();		
		this.ipc.send('closeApp');
	}
}
