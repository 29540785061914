<div class="wizard">
	<div class="overlay" *ngIf="loading"></div>
	
	<div class='container' style="width: 100vw; padding: 5px; border-bottom: 1px solid #4472c4;">
		<div class='row'>
			<div class="col-xs-2" style="text-align: center">
			
			</div>
			<div class="col-xs-8" style="text-align: center">
				<a href="javascript:void(0)" (click)="goback()" class="backbutton navigation">&larr;</a>
				<img src="logo.png" width="150" />
				
			</div>
		</div>
	</div>
	
	<alert></alert>

	<div class="wrapper">
		<div class="container">
			<div class="row vertical-align">
				<div class="col-xs-12">			
					<h2>{{heading}}</h2>
					<h4>Select a GraphOn account:</h4>
				</div>     
			</div>
				
			<div class="form-group text-right accountgrid">						
				<div *ngFor="let account of accounts" class="tile account" (click)="selectAccount(account)">
					<h5>{{account.__text}}</h5>
				</div>	
			</div>					

			<div class="form-group text-right buttons">
				<a href="javascript:void(0)" (click)="closeapp()" class="btn btn-link">Cancel</a>
			</div>
		</div>   
	</div>
</div>

