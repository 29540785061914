<div class="menu">

  <div class="left">
    <div class="left-item {{ this.model.group == null ? 'selected' : '' }}" [routerLink]="['/resources']">
      <img class="icon" src="https://cdn.graphon.com/portal_resources/Icons/Home.png" />
      <a [routerLink]="['/resources']">Home</a>
    </div>

    <div class="left-item {{ this.model.group == group.value ? 'selected' : '' }}" *ngFor="let group of this.model.groups | keyvalue" [routerLink]="['/resources/' + group.value ]">
      <img class="icon" src="https://cdn.graphon.com/portal_resources/Icons/{{ group.value }}.png"
        (error)="missingIcon($event)" />
      <a [routerLink]="['/resources/' + group.value ]">{{ group.value }}</a>
    </div>
  </div>

  <!-- <breadcrumb></breadcrumb> -->

  <div class="right" *ngIf="this.model.group == null && this.model.resource == null">
    <div class="right-item" *ngFor="let group of this.model.groups | keyvalue">
      <div class="right-item-header" *ngIf="this.getResourcesForGroup(group.value).length > 0" [routerLink]="['/resources/' + group.value ]">
        <img class="icon" src="https://cdn.graphon.com/portal_resources/Icons/{{group.value}}.png"
          (error)="missingIcon($event)" />
        <a [routerLink]="['/resources/' + group.value ]">{{ group.value }}</a>
      </div>
      <div class="right-item-list">
        <ul>
          <li *ngFor="let resource of this.getResourcesForGroup(group.value)">
            <a *ngIf="resource.openas=='INTERNAL'" [routerLink]="[ resource.url ]">{{ resource.name }}</a>
            <a *ngIf="resource.openas!='INTERNAL'" [routerLink]="['/resources/' + group.value + '/' + resource]">{{ resource }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="right" *ngIf="this.model.group != null && this.model.resource == null">
    <div class="right-item">
      <div class="right-item-header">
        <img class="icon" src="https://cdn.graphon.com/portal_resources/Icons/{{this.model.group}}.png"
          (error)="missingIcon($event)" />
        <a [routerLink]="['/resources/' + this.model.group ]">{{ this.model.group }}</a>
      </div>
      <div class="right-item-list">
        <ul>
          <li *ngFor="let resource of this.model.resources">
            <a *ngIf="resource.openas=='INTERNAL'" [routerLink]="[ resource.url ]">{{ resource.name }}</a>
            <a *ngIf="resource.openas!='INTERNAL'" [routerLink]="['/resources/' + resource.group + '/' + resource.name]">{{ resource.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <iframe [src]="getResourceUrl()" *ngIf="this.model.resource != null" class="second-row">
  </iframe>
</div>

<div style="clear: both; display: none;">
<pre>{{this.diagnostic}}</pre>
</div>
