import { ChangeDetectorRef, Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EventBusService, EventData } from 'portal/_services';

@Component({
    moduleId: module.id,
    templateUrl: 'resources.component.html',
    styles: [':host { display: flex; flex-grow: 1; }']
})

export class ResourcesComponent implements OnInit {
    model: any;
    currentUser: any;

    constructor(
        private route: ActivatedRoute,
        public sanitizer: DomSanitizer,
        private cd: ChangeDetectorRef,
        private bus: EventBusService
    )
    {
        this.model = {};
        this.model.groups = new Map();
        this.model.indexresources = new Map();
        this.route = route;
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        console.log("resources display: current user = %o", this.currentUser);
    }

    navigated() {
        if ( this.model.resource ) {
            let resource = this.getResource();
            console.log("selected resource %o", resource);
            if ( resource._openas == 'NEWTAB' ) {
                window.open(resource.url, '_blank');
                this.model.resource = undefined;
                this.model.group = undefined;
            } else if ( resource._openas == 'PAGE' ) {
                window.open(resource.url);
                this.model.resource = undefined;
                this.model.group = undefined;
            } else if ( resource._openas == 'INTERNAL' ) {
                this.model.resource = undefined;
            }// else rendered by *ngIf elements in template iframe
        } else {
          if ( this.model.group != undefined ) {
            this.model.resources = [];
            for(let resource of this.currentUser.resources.resource) {
                if ( resource.group.__text == this.model.group ) {
                    this.model.resources.push({
                        name: resource.name,
                        url: resource.url,
                        sort: resource._sort,
                        group: resource.group.__text,
                        openas: resource._openas});
                }
            }
            this.model.resources.sort((a:any,b:any) => {
                var c = parseInt(a.sort, 10);
                var d = parseInt(b.sort, 10);
                return c > d ? 1 : c < d ? -1 : 0;
            })
            console.log("resource = %o", this.model.resources);
          }
        }
        this.bus.emit(new EventData('navigate', {group: this.model.group, resource: this.model.resource}));
        console.log("navigated");
        window.scrollTo(0,0);
    }

    ngOnInit()
    {
        this.route.params.subscribe((params) => {
            this.model.group = params['group'];
            this.model.resource = params['resource'];
            this.navigated();
        });
        if ( this.model.group == undefined ) {
            let params = new URLSearchParams(window.location.search);
            this.model.group = params.paramsMap.get('?group');
            this.model.resource = params.paramsMap.get('?resource');
        }

        if ( this.model.group != undefined ) {
            this.model.resources = [];
            for(let resource of this.currentUser.resources.resource) {
                if ( resource.group.__text == this.model.group ) {
                    this.model.resources.push({
                        name: resource.name,
                        url: resource.url,
                        sort: resource._sort,
                        group: resource.group.__text,
                        openas: resource._openas});
                }
            }
            this.model.resources.sort((a:any,b:any) => {
                var c = parseInt(a.sort, 10);
                var d = parseInt(b.sort, 10);
                return c > d ? 1 : c < d ? -1 : 0;
            })
            console.log("resource = %o", this.model.resources);
        }
        this.model.groups.clear();
        if(this.currentUser.resources.resource) {
            for(let resource of this.currentUser.resources.resource) {
                this.model.groups.set(resource.group._sort, resource.group.__text);
            }
        }
        console.log("currentuser = %o\ngrouplist = %o", this.currentUser, this.model.groups);
    }
    ngAfterViewInit() {
        this.cd.detectChanges();
    }

    getResource() : any {
        for(let resource of this.currentUser.resources.resource) {
            if ( resource.name == this.model.resource ) {
                return resource;
            }
        }
        return null;
    }

    getResourceUrl() {
        for(let resource of this.currentUser.resources.resource) {
            if ( resource.name == this.model.resource ) {
                return this.sanitizer.bypassSecurityTrustResourceUrl(resource.url);
            }
        }
        return "";
    }

    public getResourcesForGroup(group: String) {
        var ret = [];
        for(let resource of this.currentUser.resources.resource) {
            if ( resource._displayinhomescreen == "true" && resource.group.__text == group ) {
                ret.push(resource.name);
            }
        }
        console.log("got resources for group: " + group + ": %o", ret);
        return ret;
    }
    get diagnostic() { return JSON.stringify(this.model, null, 2); }

    public missingIcon(event: Event) {
        const errorIconUrl = 'https://cdn.graphon.com/portal_resources/Icons/default.png';
        var target: HTMLImageElement = (event.target as HTMLImageElement);
        if ( target.src != errorIconUrl ) {
            target.src = errorIconUrl;
        } else {
            target.style.display = 'none';
        }
    }
}
