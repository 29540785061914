<div class="wizard">	
	<div class="overlay" *ngIf="loading"></div>
	
	<div class='container' style="width: 100vw; padding: 5px; border-bottom: 1px solid #4472c4;">
		<div class='row'>
			<div class="col-xs-2" style="text-align: center">
			
			</div>
			<div class="col-xs-8" style="text-align: center">
				<a href="javascript:void(0)" (click)="goback()" class="backbutton navigation">&larr;</a>
				<img src="logo.png" width="150" />
				
			</div>
		</div>
	</div>
	
	<alert></alert>
	
	<div class="wrapper">
		<div class="container">
			<div class="row vertical-align">
				<div class="col-xs-6" style="min-width: 400px;">
					<h2>Sign in to your GraphOn account or <a [routerLink]="['/wizard/wizardRegister']">create an account</a></h2>
					
					<form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
						<div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
							<input type="text" class="form-control" name="username" [(ngModel)]="model.username" #username="ngModel" required placeholder="E-mail address" />
							<div *ngIf="f.submitted && !username.valid" class="help-block">Username is required</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
							<input type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" required placeholder="Password" />
							<div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
							<small><a [routerLink]="['/wizard/wizardForgotPassword', model.username]">Forgot your password?</a></small>
						</div>
						<div style="text-align: right;" class="form-group text-right buttons">							
							<button [disabled]="loading" class="btn btn-primary">Sign in</button>
							<a href="javascript:void(0)" (click)="closeapp()" class="btn btn-link">Cancel</a>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
