import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[equal][formControlName],[equal][formControl],[equal][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualValidator), multi: true }
    ]
})

export class EqualValidator implements Validator {
    constructor ( @Attribute('equal') public equal: string) {}

    validate(c: AbstractControl): { [key: string]: any } {
        // self value
        let v = c.value;
        // control value
        let e = c.root.get(this.equal);

        if ( e && e.value && v === e.value ) {
            delete e.errors['equal'];
            if ( !Object.keys(e.errors).length) e.setErrors(null);
            return null;
        }

        if ( e ) { e.setErrors({ equal: false }); }
        return {
            equal: false
        };
    }
}
