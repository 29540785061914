import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PortalService } from '../_services/index';
import { throwDeprecation } from 'process';
import { MaxLengthValidator } from '@angular/forms';

@Component({
  moduleId: module.id,
  templateUrl: 'supportFormEditor.component.html'
})

export class SupportFormEditorComponent implements OnInit {
  model: any = {
    nodes: [],
    adding: false,
    editing: false,
  };
  currentUser: any = {};
  loading: boolean = false;
  nodeTypes: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private portalService: PortalService) 
  {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let msie = /msie\s|trident\//i.test(window.navigator.userAgent);
    if ( msie ) {
      console.log("unsupported browser");
      this.router.navigate(["/unsupported"]);
    }
    if ( !this.currentUser.email.includes("@graphon.com") ) {
      console.log("unsupported user model");
      this.router.navigate(['/']);
    }
    this.loading = true;
    this.nodeTypes = [
      {id: 'NODE_MESSAGE', name: "message"},
      {id: 'NODE_CHOICE', name: "choice"},
      {id: 'NODE_DROPDOWN', name: "dropdown"},
      {id: 'NODE_FINAL', name: "final"},
    ];
  }
  
  updateNodes() {
    try {
      this.portalService.getAllExperienceNodes().subscribe(data => {
        console.log("getAllExperienceNodes() got data = %o", data);
        if ( data ) {
          if ( data.node == '' ) { 
            data.node = [];
          } else if ( !Array.isArray(data.node) ) {
            data.node = [ data.node ];
          }
          this.model.nodes = data.node.sort((a:any,b:any) => {
            let ai = parseInt(a['id']);
            let bi = parseInt(b['id']);
            return ai > bi ? 1 : ai == bi ? 0 : -1;
          });
          console.log("model: %o", this.model.nodes);
        }
        this.loading = false;
      }, error => {
        console.log("warning: getAllExperienceNodes() returned error: " + error);
        this.loading = false;
      });
    } catch( error ) {
      console.log("getAllExperienceNodes() exception: " + error);
    }
  }

  ngOnInit() {
    this.updateNodes();
  }

  showAdd() {
    this.model.nodeid = '';
    this.model.nodetype = '';
    this.model.nodetitle = '';
    this.model.nodebody = '';
    this.model.nodeextras = '';
    this.model.nodekeywords = '';
    this.model.adding = true;
    this.model.editing = false;
  }

  edit(nodeid: string) {
    this.model.nodeid = '';
    let itemCount = 0;
    this.model.nodes.forEach((n: any, index: number, array: Array<any>) => {
      if ( n.id == nodeid ) {
        this.model.nodeid = n.id;
        this.model.nodetype = n.type;
        this.model.nodetitle = n.title;
        this.model.nodebody = n.body;
        this.model.nodeextras = n.extras;
        this.model.nodekeywords = n.keywords;
      }
      itemCount++;
      if ( itemCount === array.length ) {
        if ( this.model.nodeid == '' ) {
          alert(`Could not find node ID ${nodeid}`);
        } else {
          this.model.adding = true;
          this.model.editing = true;
        }
      }
    });
  }

  delete(nodeid: string) {
    this.loading = true;
    try {
      this.portalService.deleteExperienceNode({
        'id': nodeid
      }).subscribe(data => {
        console.log(`deleted node:P ${data}`);
        this.updateNodes();
      })
    } catch (err) {
      console.log(`deleteExperienceNode failed: ${err}`);
    }
  }

  addNode() {
    this.loading = true;
    this.model.nodebody = this.model.nodebody.replace(/(\r\n|\n|\r)/gm, "");
    if ( this.model.editing == true ) {
      try { 
        this.portalService.updateExperienceNode({
          'id': this.model.nodeid,
          'type': this.model.nodetype,
          'title': this.model.nodetitle,
          'body': this.model.nodebody,
          'extras': this.model.nodeextras,
          'keywords': this.model.nodekeywords,
        }).subscribe(data=> {
          console.log('edited node: %o', data);
          this.model.adding = false;
          this.model.editing = false;
          this.updateNodes();
        })
      } catch (err) {
        console.log('updateExperienceNode failed: ' + err);
      }
    } else {
      try {
        this.portalService.addExperienceNode({
          'type': this.model.nodetype,
          'title': this.model.nodetitle,
          'body': this.model.nodebody,
          'extras': this.model.nodeextras,
          'keywords': this.model.keywords,
        }).subscribe(data=>{
          console.log(`added node: ${data}`);
          this.model.adding = false;
          this.model.editing = false;
          this.updateNodes();
        })
      } catch (err) {
        console.log("addExperienceNode failed: " + err);
      }
    }
  }
  get diagnostic() { return JSON.stringify(this.model, null, 2); }
}