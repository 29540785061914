<div class="menu">
  <div class="second-row" style="flex-grow: unset; width: 100%; padding-right: 0; font-weight: 500;">
    <h2 style="margin-bottom: 0;">GO-Global Cloud License Administration</h2>
    <div *ngIf="loading == true">
      <div style="position: absolute; top: 0; left: 0; width: 100%; height:100%; background: #000000; opacity: 0.8; z-index:9998;">
        <div style="position: absolute; top: 50%; left: 50%; margin: -50px 0px 0px -50px; opacity: 1.0;">
          <figure style="overflow: visible" id="spinner"><div class="spinner"></div></figure>
          <h2 style="color:#ffffff; margin: 0px 0px 0px 35px; font-family: 'Quicksand', Arial, Helvetica, 'Numbus Sans L', sans-serif;">PROCESSING...</h2>
        </div>
      </div>
    </div>

    <div *ngIf="licenses.length == 0 && loading == true">
      <p>Please wait while the application initializes...</p>
    </div>

    <div *ngIf="licenses.length == 0 && loading == false">
      <p>This portal account is not currently configured as a site administrator on any cloud license.</p>
      <p>Please contact <a href="mailto:licenses@graphon.com">licenses@graphon.com</a> for further inquiries.</p>
    </div>

    <div *ngIf="model.selectedLicense == null && licenses.length > 0">
      <form class="form-inline">
        <table class="form-table">
          <tr>
            <td>
              <div class="form-group input-group">
                Show: <select style="width: 455px; flex: 0;" class="form-control" name="licenseType" #licenseType="ngModel" [(ngModel)]="model.licenseType" required>
                  <option *ngFor="let t of licenseTypes" [value]="t">{{t}}</option>
                </select>
              </div>
            </td>
            </tr>
            <tr>
              <td>
                <table>
                  <tr>
                    <th>License Master</th>
                    <th>Description</th>
                    <th>Account Name</th>
                    <th>Partner Account</th>
                    <th>Seats</th>
                    <th>Status</th>
                  </tr>

                  <ng-container *ngFor="let license of licenses">
                    <tr *ngIf="model.licenseType=='All' || license.profile.name == model.licenseType">
                      <td><a (click)="selectLicense(license)" style="cursor: pointer">{{ license.name }}</a></td>
                      <td>{{ license.description }}</td>
                      <td>{{ license.account }}</td>
                      <td>{{ license.channelPartner }}</td>
                      <td>{{ license.concurrent_use_limit }}</td>
                      <td>{{ license.status }}</td>
                    </tr>
                  </ng-container>

                </table>
            </td>
          </tr>
        </table>
      </form>
    </div>

    <div *ngIf="model.selectedLicense != null">
      <!--<button (click)="unselectLicense()">Back</button> -->
      <a (click)="unselectLicense()" style="cursor: pointer;">⬅&nbsp;Back to Licenses</a>
      <h3>License Master {{ model.selectedLicense.name }} Details</h3>
      <table class="detail-table">
        <tr>
          <td>Status:</td>
          <td>{{ model.selectedLicense.status }}</td>
        </tr>
        <tr *ngIf="model.editingDescription==false">
          <td>Description:</td>
          <td>{{ model.selectedLicense.description }}&nbsp;&nbsp;<button (click)="editDescription()" style="line-height: 18px;">Edit</button></td>
        </tr>
        <tr *ngIf="model.editingDescription==true" style="width: 100%;">
          <td style="vertical-align: top;">Description:</td>
          <td>
            <input class="form-control" name="description" #description="ngModel" [(ngModel)]="model.description" style="width:100%"/>
            <button (click)="saveDescription();" style="line-height: 18px;">Save</button>
            <button (click)="cancelEditDescription();" style="line-height: 18px;">Cancel</button>
          </td>
        </tr>
        <tr>
          <td>Account name:</td>
          <td>{{ model.selectedLicense.account }}</td>
        </tr>
        <tr *ngIf="model.selectedLicense.channelPartner != null && model.selectedLicense.channelPartner != ''">
          <td>Partner account:</td>
          <td>{{ model.selectedLicense.channelPartner }}</td>
        </tr>
        <tr>
          <td style="vertical-align: top">Site administrator(s):</td>
          <td>{{ model.selectedLicense.primary_admin.__text }} &lt;{{ model.selectedLicense.primary_admin._username }}&gt;<br/>
            <span *ngIf="model.selectedLicense.secondary_admin">
              {{ model.selectedLicense.secondary_admin.__text }} &lt;{{ model.selectedLicense.secondary_admin._username }}&gt;<br/>
            </span>
            <span *ngIf="model.selectedLicense.tertiary_admin">
              {{ model.selectedLicense.tertiary_admin.__text }} &lt;{{ model.selectedLicense.tertiary_admin._username }}&gt;
            </span>
          </td>
        </tr>
        <tr>
          <td>Profile:</td>
          <td>{{ model.selectedLicense.profile.name }}</td>
        </tr>
        <tr *ngIf="model.editingUsers==false">
          <td><span *ngIf="model.selectedLicense?.profile?.billingBasis=='Usage'">Max </span>Seats:</td>
          <td>{{ model.selectedLicense.concurrent_use_limit }}&nbsp;&nbsp;<button *ngIf="model.selectedLicense.profile.billingBasis=='Usage'" (click)="editUsers()" style="line-height: 18px;">Edit</button></td>
        </tr>
        <tr *ngIf="model.editingUsers==true && model.users%1!=0">
          <td></td><td>
            <div class="form-group"><div class="input-group"><div class="help-block">
              Number of users must be a number that is not a decimal.
            </div></div></div>
          </td>
        </tr>
        <tr *ngIf="model.editingUsers==true && model.users!='' && (+model.users <= 0 || +model.users >=100000)">
          <td></td><td>
            <div class="form-group"><div class="input-group"><div class="help-block">
              Number of users must be greater than zero and less than 100,000.
            </div></div></div>
          </td>
        </tr>
        <tr *ngIf="model.editingUsers==true">
          <td style="vertical-align: top">Max Seats:</td>
          <td>
            <input class="form-control" name="users" #users="ngModel" [(ngModel)]="model.users" style="width: 100%" />
            <button [disabled]="model.users=='' || +model.users < 0 || +model.users>=100000 || +model.users%1!=0"(click)="saveUsers();" style="line-height: 18px">Save</button>
            <button (click)="cancelEditUsers();" style="line-height: 18px">Cancel</button>
          </td>
        </tr>
        <tr *ngIf="model.selectedLicense.profile.billingBasis=='Usage'">
          <td style="padding-right: 12px;">Term High Point Usage:</td>
          <td>{{ model.selectedLicense.max_usage }} <span *ngIf="model.selectedLicense.max_usage!=0">
            on {{ model.selectedLicense.max_usage_time.split(':').slice(0,2).join(':') }} UTC</span></td>
        </tr>
        <!--
        <tr *ngIf="model.selectedLicense?.latestTermStart != null && model.selectedLicense?.profile?.session =='expires'">
          <td>Term Start:</td>
          <td>{{ model.selectedLicense.latestTermStart }}</td>
        </tr>
        -->
        <tr *ngIf="model.selectedLicense?.profile?.session == 'expires'">
          <td>Subscription Expiration:</td>
          <td>{{ model.selectedLicense.latestTermEnd }}</td>
        </tr>
        <tr *ngIf="model.selectedLicense?.profile?.session != 'expires'">
          <td>Support Contract Expiration:</td>
          <td>{{ getSupportExpiration(model.selectedLicense) }}</td>
        </tr>
        <!--
        <tr>
          <td>License expiration:</td>
          <td>{{ getLicenseExpiration(model.selectedLicense) }}</td>
        </tr>
        <tr *ngIf="model.selectedLicense?.profile?.session != 'expires'">
          <td>Support Contract Expiration:</td>
          <td>{{ getSupportExpiration(model.selectedLicense) }}</td>
        </tr>
        -->
        <tr>
          <td>Product code:</td>
          <td>{{ model.selectedLicense.product_code }}</td>
        </tr>
      </table>

      <h3 *ngIf="hosts != null">
        Hosts Using This License
      </h3>

      <table *ngIf="hosts != null" class="host-table">
        <tr>
          <th>&nbsp;</th>
          <th>ID</th>
          <th>Hostname</th>
          <th>Operating System</th>
          <th>GO-Global Version</th>
          <th>Seats Reserved</th>
          <!--<th>Last Start</th>-->
          <th>Host ID</th>
          <th>State</th>
        </tr>

        <ng-container *ngFor="let host of hosts; let i = index">
          <tr>
            <td><input type="checkbox" id="hostCheck{{i}}" name="host{{i}}" [(ngModel)]="host.checked" (ngModelChange)="updateCheckedHosts()" /></td>
            <td>{{ host.id }}</td>
            <td>{{ host.name }}</td>
            <td>{{ host.os.replace("undefined", "") }}</td>
            <td>{{ host.version == "null" ? "" : host.version }}</td>
            <td *ngIf="host.seats_reserved!=null">{{ host.seats_reserved }} on {{ host.seats_reserved_time.split(':').slice(0,2).join(':') }} UTC</td>
            <td *ngIf="host.seats_reserved==null">None</td>
            <!--
            <td *ngIf="host.last_start!=null">{{ host.last_start.split(':').slice(0,2).join(':') }}</td>
            <td *ngIf="host.last_start==null">None</td>
            -->
            <td>{{ host.hardwareid }}</td>
            <td>{{ host.state }}</td>
          </tr>
        </ng-container>
      </table>

      <button (click)="deactivateHosts()" [disabled]="checkedHosts==0">Deactivate Host(s)</button>
      <app-confirm-dialog></app-confirm-dialog>

    </div>
  </div>
</div>
