<h3>Welcome, {{currentUser.firstName}}!</h3>

<h4>What would you like to do?</h4>

<ul>
    <li *ngIf="currentUser.canManageLicenses=='true'">Manage licenses
        <ul>
            <li>Purchase or renew licenses</li>
            <li>Add seats to a license</li>
            <li>View the status of licenses</li>
        </ul>
    </li>
    <li *ngIf="currentUser.canManageComputers=='true'">Manage computers
        <ul>
            <li>Activate GO-Global on a computer</li>
            <li>Change a computer's license</li>
            <li>Get a link to a computer</li>
            <li>Publish applications from a computer</li>
            <li>View and manage a computer's sessions</li>
            <li>Change a computer's settings</li>
        </ul>
    </li>
    <li *ngIf="currentUser.canRunApplications=='true'">Run applications</li>
    <li *ngIf="currentUser.canDownload=='true'">Download software
        <ul>
            <li>Download the latest version of GO-Global</li>
            <li>Download previous versions of GO-Global</li>
        </ul>
    </li>
    <li>Get Help
        <ul>
            <li>Read product documentation</li>
            <li>Submit a customer support request</li>
        </ul>
    </li>
</ul>

<!--
<h3>All registered users:</h3>
<ul>
    <li *ngFor="let user of users">
        {{user.username}} ({{user.firstName}} {{user.lastName}} <{{user.email}}>)
        - <a (click)="deleteUser(user.id)">Delete</a>
    </li>
</ul>
<p><a [routerLink]="['/signin']">Logout</a></p>


<div class="col-md-6 col-md-offset-3">
    <h1>Hi {{currentUser.firstName}}!</h1>
    <p>You're logged in with Angular 2!!</p>
    <h3>All registered users:</h3>
    <ul>
        <li *ngFor="let user of users">
            {{user.username}} ({{user.firstName}} {{user.lastName}})
            - <a (click)="deleteUser(user.id)">Delete</a>
        </li>
    </ul>
    <p><a [routerLink]="['/signin']">Sign Out</a></p>
</div>
-->