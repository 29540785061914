<div *ngIf="loading == true">
  <div style="position: absolute; top: 0; left: 0; width: 100%; height:100%; background: #000000; opacity: 0.8; z-index:9998;">
    <div style="position: absolute; top: 50%; left: 50%; margin: -50px 0px 0px -50px; opacity: 1.0;">
      <figure style="overflow: visible" id="spinner"><div class="spinner"></div></figure>
      <h2 style="color:#ffffff; margin: 0px 0px 0px 35px; font-family: 'Work Sans', Arial, Helvetica, sans-serif;">PROCESSING...</h2>
    </div>
  </div>
</div>

<div class="menu" style="text-align: center">

  <div class="second-row" style="flex-grow: unset; width:32vw; margin: auto;">
    <h1 style="color: #0f3c80;">Sign in</h1><div style="font-size: 1em; margin-bottom: 10px;">or <a [routerLink]="['/signup']">create a GraphOn account</a></div>
    <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
      <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
        <input type="text" autofocus class="form-control" name="username" [(ngModel)]="model.username" #username="ngModel" required placeholder="Email address" />
        <div *ngIf="f.submitted && !username.valid" class="help-block">To continue, please enter an email address.</div>
      </div>
      <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
        <input type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" required placeholder="Password" />
        <div *ngIf="f.submitted && !password.valid" class="help-block">To continue, please enter a password.</div>
      </div>
      <div style="text-align: center; margin-top: 10px;" class="form-group text-right">
        <button [disabled]="loading" class="btn btn-primary" style="width: 15vw;">SIGN IN</button>
        <a href="https://www.graphon.com/"><button type="button" style="margin-left: 1vw; width: 15vw;" class="btn">CANCEL</button></a>
        <br />
        <div style="text-align: center; margin-top: 20px;">
          <small><a [routerLink]="['/forgotPassword', model.username == undefined ? '' : model.username]">Forgot your password?</a></small>
        </div>
      </div>
      <!-- FIXME add remember me -->
    </form>
  </div>
</div>
﻿
