/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./manageLicenses.component";
var styles_ManageLicensesComponent = [];
var RenderType_ManageLicensesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ManageLicensesComponent, data: {} });
export { RenderType_ManageLicensesComponent as RenderType_ManageLicensesComponent };
export function View_ManageLicensesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["placeholder for manage licenses"]))], null, null); }
export function View_ManageLicensesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ManageLicensesComponent_0, RenderType_ManageLicensesComponent)), i0.ɵdid(1, 49152, null, 0, i1.ManageLicensesComponent, [], null, null)], null, null); }
var ManageLicensesComponentNgFactory = i0.ɵccf("ng-component", i1.ManageLicensesComponent, View_ManageLicensesComponent_Host_0, {}, {}, []);
export { ManageLicensesComponentNgFactory as ManageLicensesComponentNgFactory };
