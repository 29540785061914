import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { AlertService, PortalService, ElectronIPC } from '../_services/index';

@Component({
    moduleId: module.id,
    templateUrl: 'wizardForgotPassword.component.html'
})

export class WizardForgotPassword {
    model: any = {};
    loading = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private portalService: PortalService,
        private alertService: AlertService,
        private ipc: ElectronIPC) {
            this.route.params.subscribe((params) => {
                this.model.email=this.route.snapshot.queryParams.username;
                console.log('email initialized to ' + this.model.email);
                console.log('params = %o', this.route.snapshot.params);    
            });
            let params = new URLSearchParams(window.location.search);
            this.model.email=this.route.snapshot.queryParams.username;
            console.log('url search params is %o', params);
            console.log('email initialized to ' + this.model.email);
        }

    submitRequest() {
        this.loading = true;
        console.log("sending password reset: model = %o", this.model);
        this.portalService.sendPasswordReset(this.model.email)
            .subscribe(
                data => {
                    console.log("RegisterComponent:register() got data = %o", data);
                    if ( data ) {
						localStorage.setItem('currentWarning', 'An e-mail has been sent to ' + this.model.email + ' with a link that will allow you to reset your password.');
                        this.router.navigate(['/wizard/welcome']);
                    }
                },
                error => {
                    this.loading = false;
					if ( error.toString().includes("Error: null") ) {
                        this.alertService.error('E-mail address not found.');
					}
					else {
						this.alertService.error(error);
					}
                });
    }
	
	goback() {
		this.portalService.logout();	
		this.router.navigate(['/wizard/wizardLogin']);
	}
	
	closeapp() {
		this.portalService.logout();		
		this.ipc.send('closeApp');
	}
}
