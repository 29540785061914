<div class="wizard">	
	<div class="overlay" *ngIf="loading"></div>
	
	<div class='container' style="width: 100vw; padding: 5px; border-bottom: 1px solid #4472c4;">
		<div class='row'>
			<div class="col-xs-12" style="text-align: center">
				<a href="javascript:void(0)" (click)="goback()" class="backbutton navigation">&larr;</a>
				<img src="logo.png" width="150" />
				
			</div>
		</div>
	</div>
	
	<alert></alert>

	<div class="wrapper">
		<div class="container">
			<div class="row vertical-align">
				<div class="col-xs-12">			
					<h2>{{heading}}</h2>
					<h4>Click Activate to enable GO-Global on this computer.</h4>
					<br/>
                    <form name="form" (ngSubmit)="f.form.valid && submitRequest()" #f="ngForm" novalidate>
						<div class="form-group text-right buttons">	
							<button [disabled]="loading" class="btn btn-primary">Activate</button>
							<a href="javascript:void(0)" (click)="closeapp()" class="btn btn-link">Cancel</a>
						</div>
                    </form>
				</div>     
			</div>
			
			<div class="dialogOuter" *ngIf="dialogWait">
				<div class="dialogInner" style="cursor: wait;">
					<h4>Activating GO-Global... (this may take a minute)</h4>					
				</div>
			</div>
		</div>
	</div>
</div>

