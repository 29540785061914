import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { AlertService, PortalService, ElectronIPC } from '../_services/index';
	
@Component({
    moduleId: module.id,
    templateUrl: 'wizardWelcome.component.html'
})

export class WizardWelcome implements OnInit {
    model: any = {};
    loading = false;
    warningMessage: string;
	instructionMessage: string;
	exitOnly = "false";
	heading: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private portalService: PortalService,
        private alertService: AlertService,
        private ipc: ElectronIPC) {
			this.warningMessage = localStorage.getItem('currentWarning');
			this.heading = localStorage.getItem('currentHeading');
			this.exitOnly = localStorage.getItem('exitOnly');
			this.instructionMessage = localStorage.getItem('currentInstruction');
			
			if (this.instructionMessage == undefined) {
				this.instructionMessage = "Click Next to enable GO-Global on this computer."
			}
        }

    ngOnInit() {
	}
	
	externalLink(url: string) {
		this.ipc.send('openExternalLink', url);
	}
	
    submitRequest() {
		this.loading = true;
		this.router.navigate(['/wizard/showAccounts']);
    }
	
	closeapp() {
		this.portalService.logout();		
		this.ipc.send('closeApp');
	}
}
