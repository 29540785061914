/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./unsupported_browser.component";
var styles_UnsupportedBrowserComponent = [];
var RenderType_UnsupportedBrowserComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UnsupportedBrowserComponent, data: {} });
export { RenderType_UnsupportedBrowserComponent as RenderType_UnsupportedBrowserComponent };
export function View_UnsupportedBrowserComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "menu"], ["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "second-row"], ["style", "flex-grow: unset; width: 32vw; margin: auto;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h2", [["style", "margin-bottom: 36px;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Unsupported Browser"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["The GraphOn Customer Portal does not support Internet Explorer at this time. Please use another modern browser, such as Chrome, Firefox, Safari, or Microsoft Edge to access the portal. "]))], null, null); }
export function View_UnsupportedBrowserComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_UnsupportedBrowserComponent_0, RenderType_UnsupportedBrowserComponent)), i0.ɵdid(1, 49152, null, 0, i1.UnsupportedBrowserComponent, [], null, null)], null, null); }
var UnsupportedBrowserComponentNgFactory = i0.ɵccf("ng-component", i1.UnsupportedBrowserComponent, View_UnsupportedBrowserComponent_Host_0, {}, {}, []);
export { UnsupportedBrowserComponentNgFactory as UnsupportedBrowserComponentNgFactory };
