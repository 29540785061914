<div *ngIf="loading == true">
  <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background:#000000; opacity: 0.8; z-index: 9998;">
    <div style="position: absolute; top: 50%; left: 50%; margin: -50px 0 0 -50px; opacity: 1.0;">
      <figure style="overflow: visible;" id="spinner"><div class="spinner"></div></figure>
      <h2 style="color: #ffffff; margin: 0 0 0 35px; font-family: 'Work Sans', Arial, Helvetica, sans-serif;">PROCESSING...</h2>
    </div>
  </div>
</div>

<div class="menu" style="text-align: center">
  <div class="second-row" style="flex-grow: unset; width: 32vw; margin: auto;">
    <h1 style="color: #0f3b80;">Set / Reset Password</h1>
    <form name="form" (ngSubmit)="f.form.valid && submitRequest()" #f="ngForm" novalidate>
      <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !key.valid }">
          <!-- <label for="key">E-Mail Address</label> -->
          <input type="text" class="form-control" name="email" [(ngModel)]="model.email" #key="ngModel" autofocus required email placeholder="Email Address *" />
          <div *ngIf="f.submitted && !key.valid" class="help-block">To continue, please enter a valid email address.</div>
      </div>
      <div style="margin-top: 10px;" class="form-group text-right">
          <button [disabled]="loading" class="btn btn-primary">SET / RESET PASSWORD</button>
          <a [routerLink]="['/login']"><button type="button" class="btn" style="margin-left: 10px;">CANCEL</button></a>
      </div>
    </form>
  </div>
</div>
