/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./manageComputers.component";
var styles_ManageComputersComponent = [];
var RenderType_ManageComputersComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ManageComputersComponent, data: {} });
export { RenderType_ManageComputersComponent as RenderType_ManageComputersComponent };
export function View_ManageComputersComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(4, 1), (_l()(), i0.ɵted(-1, null, ["Activate GO-Global on a computer"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Change a computer's license"])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Get a link to a computer"])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Publish applications from a computer"])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["View and manage a computer's sessions"])), (_l()(), i0.ɵeld(14, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Change a computer's settings"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 4, 0, "/portal/computers/activate"); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_ManageComputersComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ManageComputersComponent_0, RenderType_ManageComputersComponent)), i0.ɵdid(1, 49152, null, 0, i3.ManageComputersComponent, [], null, null)], null, null); }
var ManageComputersComponentNgFactory = i0.ɵccf("ng-component", i3.ManageComputersComponent, View_ManageComputersComponent_Host_0, {}, {}, []);
export { ManageComputersComponentNgFactory as ManageComputersComponentNgFactory };
