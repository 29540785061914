import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { AlertService, PortalService, ElectronIPC } from '../_services/index';

@Component({
    moduleId: module.id,
    templateUrl: 'wizardPreWelcome.component.html'
})

export class WizardPreWelcome implements OnInit {
    model: any = {};
    loading = false;	
    dialogWait = false;
    warningMessage: string;
    instructionMessage: string;
	heading: string;   

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private portalService: PortalService,
        private alertService: AlertService,
        private ipc: ElectronIPC,
		private ngzone: NgZone) {	
			this.portalService.logout();			
			
			localStorage.removeItem('currentHeading');
			localStorage.removeItem('currentWarning');	
			localStorage.removeItem('currentInstruction')
			
			this.heading = "GO-Global Activation";
			localStorage.setItem('currentHeading', this.heading);
        }

    ngOnInit() {		
		this.loading = true;
		this.dialogWait = true;
			
		this.ipc.on('getConnectionStatusCallback', (function(event: any, code: string, path: string) {

			localStorage.setItem('currentConnection', code);
							
			if (code != "0")
			{
				this.instructionMessage = "communication.error";				
				localStorage.setItem('currentInstruction', this.instructionMessage);
				localStorage.setItem('currentConnectionPath', path);
				localStorage.setItem('currentWarning', "");
				localStorage.setItem('exitOnly', "true");
			}
				
			this.ngzone.run(()=>{
				this.router.navigate(['/wizard/welcome']);
			});
		}).bind(this));	
		
		this.ipc.on('testConfigCallback', (function(event: any, message: Uint8Array, error: any) {
			console.log("testConfigCallback: " + message);
			
			if ((error) && (typeof error === 'object')) {	
				var errorMessage = "Error reading configuration file: ";
				
				if (error.syscall != undefined) {
					errorMessage += error.syscall + " ";
				}
				
				if (error.path != undefined) {
					errorMessage += error.path + " ";
				}
				
				if (error.code != undefined) {
					errorMessage += error.code + " ";
				}
				
				if (error.errno != undefined) {
					errorMessage += error.errno + " ";
				}						
				
				this.warningMessage = errorMessage;
				localStorage.setItem('currentWarning', this.warningMessage);
				localStorage.setItem('currentInstruction', "");
				localStorage.setItem('exitOnly', "true");	
				
				// Skip the remaining checks
				this.ngzone.run(()=>{
					this.router.navigate(['/wizard/welcome']);
				});
			}
			else if (message == null) {
				this.warningMessage = "Error reading configuration file: Please run this application on a GO-Global host.";
				localStorage.setItem('currentWarning', this.warningMessage);
				localStorage.setItem('currentInstruction', "");
				localStorage.setItem('exitOnly', "true");
				
				// Skip the remaining checks
				this.ngzone.run(()=>{
					this.router.navigate(['/wizard/welcome']);
				});
			}
			else
			{
				var X2JS = require('../../node_modules/node-x2js/dist/X2JS.min.js');
				var x2js = new X2JS();
			  
				var decoded = new TextDecoder("utf-8").decode(message);				
				var json = x2js.xml_str2json(decoded);
				
				if (json && json.config && json.config.host && json.config.host.licensemanager && json.config.host.licensemanager == "on-premises")
				{
					this.instructionMessage = "GO-Global is configured to use its on-premises license server. If you would like to configure GO-Global to use a cloud license, remove the on-premises license(s), restart the Application Publishing Service, and then re-run this Activation Wizard.";			
					localStorage.setItem('currentInstruction', this.instructionMessage);	
					localStorage.setItem('currentWarning', "");
					localStorage.setItem('exitOnly', "true");
					
					// Skip the remaining checks
					this.ngzone.run(()=>{
						this.router.navigate(['/wizard/welcome']);
					});
				}
				else if (json && json.config && json.config.cloudservers && json.config.cloudservers.cloudserver && json.config.cloudservers.cloudserver.uuid)
				{
					this.warningMessage = "GO-Global is already configured to use an existing license. Click Next if you would like to configure GO-Global to use a different license.";
					localStorage.setItem('currentWarning', this.warningMessage);
					
					this.ipc.send('getConnectionStatus');
				}
				else
				{
					this.ipc.send('getConnectionStatus');
				}
			}
		}).bind(this));	
		
		this.ipc.on('getArgsCallback', (function(event: any, mode: string) {
			localStorage.setItem('currentMode', mode);
							
			if (mode == "cloudTrial") {			
				this.heading = "GO-Global Activation";	
				localStorage.setItem('currentHeading', this.heading);
			}
			
			if (mode == "onPremises")
			{
				this.instructionMessage = "To activate GO-Global, copy your license file(s) to the GO-Global\\Licensing directory or configure GO-Global to use a license server (see Administrator Guide). You can do this now or later. If you do it later, you must restart the GO-Global License Manager Service and the GO-Global Application Publishing Service after you copy the license file(s).";
				localStorage.setItem('currentInstruction', this.instructionMessage);
				localStorage.setItem('currentWarning', "");
				localStorage.setItem('exitOnly', "true");
				
				// Skip the remaining checks
				this.ngzone.run(()=>{
					this.router.navigate(['/wizard/welcome']);
				});
			}
			else
			{			
				this.ipc.send('testConfig', 'config.xml');
			}
		}).bind(this));	
		
		this.ipc.send('getArgs');
    }
	
    submitRequest() {
		this.router.navigate(['/wizard/welcome']);
    }
	
	closeapp() {
		this.portalService.logout();		
		this.ipc.send('closeApp');
	}
}
