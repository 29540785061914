/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./activateHost.component";
var styles_ActivateHostComponent = [];
var RenderType_ActivateHostComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ActivateHostComponent, data: {} });
export { RenderType_ActivateHostComponent as RenderType_ActivateHostComponent };
export function View_ActivateHostComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["placeholder for host activation"]))], null, null); }
export function View_ActivateHostComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ActivateHostComponent_0, RenderType_ActivateHostComponent)), i0.ɵdid(1, 49152, null, 0, i1.ActivateHostComponent, [], null, null)], null, null); }
var ActivateHostComponentNgFactory = i0.ɵccf("ng-component", i1.ActivateHostComponent, View_ActivateHostComponent_Host_0, {}, {}, []);
export { ActivateHostComponentNgFactory as ActivateHostComponentNgFactory };
