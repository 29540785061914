<div class="wizard">	
	<div class="overlay" *ngIf="loading"></div>
	
	<div class='container' style="width: 100vw; padding: 5px; border-bottom: 1px solid #4472c4;">
		<div class='row'>
			<div class="col-xs-2" style="text-align: center">
			
			</div>
			<div class="col-xs-8" style="text-align: center">
				<a href="javascript:void(0)" (click)="goback()" class="backbutton navigation">&larr;</a>
				<img src="logo.png" width="150" />
				
			</div>
		</div>
	</div>
	
	<alert></alert>
	
	<div class="wrapper">
		<div class="container">
			<div class="row vertical-align">
	 
				<div class="col-xs-6" style="min-width: 400px;">
					<h2>Set/Reset Password</h2>
					<p>Your password has not been set. Please set your password.</p>
					<br/>
					<form name="form" (ngSubmit)="f.form.valid && submitRequest()" #f="ngForm" novalidate>
						<div class="form-group" [ngClass]="{ 'has-error': f.submitted && !key.valid }">
							<label for="email">E-Mail Address:</label>
							<input type="text" class="form-control" name="email" [(ngModel)]="model.email" #key="ngModel" required email placeholder="E-Mail Address" />
							<div *ngIf="f.submitted && !key.valid" class="help-block">A valid E-mail address is required.</div>
						</div>
						<div class="form-group text-right">
							<button [disabled]="loading" class="btn btn-primary">Set/Reset Password</button>&nbsp;
							<a [routerLink]="['/wizard/wizardLogin']" class="btn btn-link">Cancel</a>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
