<div *ngIf="loading == true">
  <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background:#000000; opacity: 0.8; z-index: 9998;">
    <div style="position: absolute; top: 50%; left: 50%; margin: -50px 0 0 -50px; opacity: 1.0;">
      <figure style="overflow: visible;" id="spinner"><div class="spinner"></div></figure>
      <h2 style="color: #ffffff; margin: 0 0 0 35px; font-family: 'Work Sans', Arial, Helvetica, sans-serif;">PROCESSING...</h2>
    </div>
  </div>
</div>

<div class="menu" style="text-align: center;">
  <div class="second-row" style="flex-grow: unset; width: 32vw; margin: auto;">
    <h1 style="color: #0f3b80;">Reset password.</h1>
    <p>Enter your new password below.</p>
    <form name="form" (ngSubmit)="f.form.valid && changePassword()" #f="ngForm" novalidate>
      <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || password.dirty || passwordConfirm.dirty) && !password.valid }">
          <!-- <label for="password">Password</label> -->
          <input type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" autofocus required equal="passwordConfirm" placeholder="Password" />
          <div *ngIf="(password.dirty || passwordConfirm.dirty || f.submitted) && !password.valid" class="help-block">To continue, please enter matching passwords.</div>
      </div>
      <div class="form-group" [ngClass]="{ 'has-error': (f.submitted || password.dirty || passwordConfirm.dirty) && !passwordConfirm.valid }">
          <!-- label for="passwordConfirm">Confirm password</label> -->
          <input type="password" class="form-control" name="passwordConfirm" [(ngModel)]="model.passwordConfirm" #passwordConfirm="ngModel" required equal="password" placeholder="Confirm password" />
          <div *ngIf="(password.dirty || passwordConfirm.dirty || f.submitted) && !passwordConfirm.valid" class="help-block">To continue, please enter matching passwords.</div>
      </div>
      <div style="text-align: center; margin-top: 10px;" class="form-group text-right">
          <button [disabled]="loading" class="btn btn-primary">RESET PASSWORD</button>
          <a [routerLink]="['/login']"><button type="button" class="btn" style="margin-left: 10px;">CANCEL</button></a>
      </div>
    </form>
  </div>
</div>
