import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { AlertService, PortalService, ElectronIPC } from '../_services/index';

@Component({
    moduleId: module.id,
    templateUrl: 'wizardComplete.component.html'
})

export class WizardComplete {
    model: any = {};
    loading = false;
	heading: string;
    
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private portalService: PortalService,
        private alertService: AlertService,
        private ipc: ElectronIPC) {
			this.heading = localStorage.getItem('currentHeading');
        }

    submitRequest() {
		this.loading = true;	
    }
	
	closeapp() {
		this.portalService.logout();		
		this.ipc.send('closeApp');
	}
}
