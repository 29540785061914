﻿import { Component, ChangeDetectorRef } from '@angular/core';
import { EventBusService, EventData } from './_services';

@Component({
    moduleId: module.id,
    selector: 'app',
    templateUrl: 'app.component.html'
})

export class AppComponent {
  public footerVisible : Boolean = true;
  public bottomPadding : number = 250;
  public inIFrame = false;

  constructor(bus:EventBusService, private cd:ChangeDetectorRef) {
    if ( window != top ) {
      this.inIFrame = true;
    }
    bus.on('navigate', (val: any) => {
      console.log("navigate: %o", val);
      if ( val.group == undefined || val.resource === undefined ) {
        this.footerVisible = true;
        this.bottomPadding = 250;
      } else {
        this.footerVisible = false;
        this.bottomPadding = 0;
      }
      this.cd.detectChanges();
      console.log("footerVisible = ", this.footerVisible);
    });
  }


}
