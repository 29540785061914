import { Injectable } from '@angular/core';
import { IpcRenderer } from 'electron';

@Injectable()
export class ElectronIPC {
    private _ipc : IpcRenderer | undefined = void 0;
    
    constructor() {
      if ( (<any>window).require ) {
        try {
          this._ipc = (<any>window).require('electron').ipcRenderer; 
          console.log("Configured Electron IPC Renderer");
        } catch(e) {
          throw e;
        }
      } else {
        console.warn("Electron's IPC was not loaded");
      }
    }

    public send(channel:string, ...args : any[]) : void {
      console.log("sending message on channel '" + channel + "'");
      if ( !this._ipc ) {
        console.log("no IPC Renderer configured");
        return;
      }
      this._ipc.send(channel, ...args);
    }

    public on(channel:string, listener: Function): void {
      console.log("set handler for messages on channel '" + channel + "'");
      if ( !this._ipc ) {
        console.log("no IPC Renderer configured");
        return;
      }
      this._ipc.on(channel, listener);
    }
  }