<div class="wizard">
	<div class="overlay" *ngIf="loading"></div>
	
	<div class='container' style="width: 100vw; padding: 5px; border-bottom: 1px solid #4472c4;">
		<div class='row'>
			<div class="col-xs-12" style="text-align: center">
				<img src="logo.png" width="150" />
				
			</div>
		</div>
	</div>
	
	<alert></alert>

	<div class="wrapper">
		<div class="container">
			<div class="row vertical-align">
				<div class="col-xs-10">
					<h2>{{heading}}</h2>
					<h4>GO-Global is now activated on this computer.</h4>
				</div>        
				<div class="col-xs-2">			
				</div>        
			</div>				

			<div class="form-group text-right buttons">
				<a href="javascript:void(0)" (click)="closeapp()" class="btn btn-primary">Exit</a>
			</div>
		</div>
	</div>
</div>

