import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { AlertService, PortalService, ElectronIPC } from '../_services/index';
import { License } from '../_models';
import { TextDecoder } from 'text-encoding';

@Component({
    moduleId: module.id,
    templateUrl: 'wizardShowLicenses.component.html'
})

export class WizardShowLicenses {
	public licenses: License[];
	public triallicenses: License[];
	public invalidlicenses: License[];
	public account: string;
	model: any = {};
	loading = false;
	dialogSelection = false;
	dialogDescription = false;
	dialogLicenseInvalid = false;
	dialogNoValidLicenses = false;
	licenseDescription: string;
	licenseName: string;
	heading: string;
	showValidLicenses = false;
	invalidLicense: License;
	trialEligible = true;
	showResults = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private portalService: PortalService,
        private alertService: AlertService,
        private ipc: ElectronIPC)	{			
			this.heading = localStorage.getItem('currentHeading');
			console.log("ShowLicenses: model = %o", this.model);
			
			var accountInfo = JSON.parse(localStorage.getItem('currentAccount'));
			
			this.account = accountInfo.__text;
			
			var configData = localStorage.getItem('currentConfig');
			var hostData = localStorage.getItem('currentHost');
			var hardwareData = localStorage.getItem('currentHardware');
			
			if ((configData == undefined) || (hostData == undefined) || (hardwareData == undefined)) {			
				this.ipc.on('readConfigCallback', (function(event: any, message: Uint8Array, error: any) {
					if ((error) && (typeof error === 'object')) {	
						var errorMessage = "Error reading configuration file: ";
						
						if (error.syscall != undefined) {
							errorMessage += error.syscall + " ";
						}
						
						if (error.path != undefined) {
							errorMessage += error.path + " ";
						}
						
						if (error.code != undefined) {
							errorMessage += error.code + " ";
						}
						
						if (error.errno != undefined) {
							errorMessage += error.errno + " ";
						}						
						
						this.alertService.error(errorMessage);
						this.loading = false;
					}
					
					console.log("readConfigCallback: " + message);
					
					var X2JS = require('../../node_modules/node-x2js/dist/X2JS.min.js');
					var x2js = new X2JS();
				  
					var decoded = new TextDecoder("utf-8").decode(message);				
					var json = x2js.xml_str2json(decoded);
					
					localStorage.setItem('currentConfig', JSON.stringify(json));
					localStorage.setItem('currentHost', json.config.host.hostaddress);
					localStorage.setItem('currentHardware', json.config.host.hardwareid);
					
					if ((json.config.host.computer_uuid == undefined) || (json.config.host.computer_uuid == "")) {
						this.alertService.error("The config.xml file is not valid.");
					}
					
					localStorage.setItem('currentUUID', json.config.host.computer_uuid);
					localStorage.setItem('currentOS', json.config.host.osname);
					
					if ((json.config.cloudservers) && (json.config.cloudservers.cloudserver) && (json.config.cloudservers.cloudserver.license))
					{
						localStorage.setItem('existingLicenseName', json.config.cloudservers.cloudserver.license);
						this.licenseName = json.config.cloudservers.cloudserver.license;
					}
					
				}).bind(this));		
				
				this.ipc.send('readConfig', 'config.xml');
			}
			
			this.portalService.getTrialLicenseTemplates()
				.subscribe(
					data => {
						console.log("ShowTrialLicenses:register() got data = %o", data);	
						
						if ( !Array.isArray(data.license) ) {
							// coerce to array so that ngFor in html template will be able
							// to iterate over a (single-member) array.
							console.log("ShowTrialLicenses: coerce to array");
							data.license = [data.license];
						}
						
						if ( data ) {
							this.triallicenses = data.license;
							var i = 0;
							console.log("Trial licenses returned: " + data.license.length);
							
							for (i = 0; i < data.license.length; i++) {							
								this.portalService.canActivateLicense(data.license[i].id, data.license[i].account._id)
								.subscribe(
									data2 => {
										var licenseID = data2[0];
										var response = data2[1];
										
										if (response.canActivate != "true") {
											this.trialEligible = false;
											
											var j = 0;
											for (j = 0; j < this.triallicenses.length; j++) {
												if (this.triallicenses[j].id == licenseID) {	
													if (response.canActivate == "expired") {
														this.triallicenses[j].reason = "Expired";															
													}
													else if (response.canActivate == "revoked") {
														this.triallicenses[j].reason = "Revoked";															
													}
													else if (response.canActivate.includes("previous_trial")) {
														this.triallicenses[j].otherLicense = response.canActivate.replace("previous_trial:", "");
														this.triallicenses[j].reason = "Ineligible";															
													}
													else {
														this.triallicenses[j].reason = response.canActivate;															
													}
					
													break;
												}
											}
										}											
									},
									error => {
										this.alertService.error(error);
										this.loading = false;
										this.showResults = true;	
									});	
							}
						}
						
						this.portalService.getLicenses()
						.subscribe(
							data => {
								console.log("getLicenses() got data = %o", data);
								if ( data ) {
									if ( !Array.isArray(data.license) ) {
										// coerce to array so that ngFor in html template will be able
										// to iterate over a (single-member) array.
										console.log("ShowTrialLicenses: coerce to array");
										data.license = [data.license];
									}
									
									if ( data ) {
										this.licenseName = localStorage.getItem('existingLicenseName');
										this.licenses = data.license;
										var i = 0;
										
										for (i = 0; i < data.license.length; i++) {							
											this.portalService.canActivateLicense(data.license[i].id, data.license[i].account._id)
											.subscribe(
												data2 => {
													var licenseID = data2[0];
													var response = data2[1];
													
													if (response.canActivate != "true") {
														var j = 0;
														for (j = 0; j < this.licenses.length; j++) {
															if (this.licenses[j].id == licenseID) {					
																this.licenses.splice(j, 1);
																break;
															}
														}
													}
												},
												error => {
													this.alertService.error(error);
													this.loading = false;
													this.showResults = true;	
												});
										}
									}							
								}	

								this.portalService.getLicenses()
								.subscribe(
									data => {
										console.log("getLicenses() got data = %o", data);
										if ( data ) {
											if ( !Array.isArray(data.license) ) {
												// coerce to array so that ngFor in html template will be able
												// to iterate over a (single-member) array.
												console.log("ShowTrialLicenses: coerce to array");
												data.license = [data.license];
											}
											
											if ( data ) {
												this.licenseName = localStorage.getItem('existingLicenseName');
												this.invalidlicenses = data.license;
												var i = 0;
												
												for (i = 0; i < data.license.length; i++) {							
													this.portalService.canActivateLicense(data.license[i].id, data.license[i].account._id)
													.subscribe(
														data2 => {
															var licenseID = data2[0];
															var response = data2[1];
															
															if (response.canActivate == "true") {
																var j = 0;
																for (j = 0; j < this.invalidlicenses.length; j++) {
																	if (this.invalidlicenses[j].id == licenseID) {					
																		this.invalidlicenses.splice(j, 1);
																		break;
																	}
																}
															}
															else {
																var j = 0;
																for (j = 0; j < this.invalidlicenses.length; j++) {
																	if (this.invalidlicenses[j].id == licenseID) {	
																		if (response.canActivate == "expired") {
																			this.invalidlicenses[j].reason = "Expired";															
																		}
																		else if (response.canActivate == "revoked") {
																			this.invalidlicenses[j].reason = "Revoked";															
																		}
																		else if (response.canActivate.includes("previous_trial")) {
																			this.invalidlicenses[j].otherLicense = response.canActivate.replace("previous_trial:", "");
																			this.invalidlicenses[j].reason = "Ineligible";															
																		}
																		else {
																			this.invalidlicenses[j].reason = response.canActivate;															
																		}
										
																		break;
																	}
																}
															}
														},
														error => {
															this.alertService.error(error);
															this.loading = false;
															this.showResults = true;	
														});
												}
											}
										}	
										
										this.showResults = true;		
										this.loading = false;	
										
										if ((this.licenses.length == 0) && (this.trialEligible == false)) {
											this.dialogNoValidLicenses = true;
										}
									},
									error => {
										this.alertService.error(error);
										this.loading = false;
										this.showResults = true;	
									});	
								
							},
							error => {
								this.alertService.error(error);
								this.loading = false;
								this.showResults = true;	
							});	
								
					},
					error => {
						this.alertService.error(error);
						this.loading = false;
						this.showResults = true;	
					});		
		}
	
	activateRequest(licenseID: string, licenseName: string, licenseName2: string) {
		if (licenseName != licenseName2) {
			this.loading = true;	
			localStorage.setItem('currentLicense', licenseID);
			localStorage.setItem('currentLicenseName', licenseName);
			this.router.navigate(['/wizard/licenseAgreement']);
		}
		else {
			this.dialogSelection = true;
		}
	}
	
	licenseSelectedDialog() {
		this.dialogSelection = true;
	}
	
	trialRequest(licenseID: string, accountID: string, licenseName: string) {
		localStorage.setItem('currentLicense', licenseID);
		localStorage.setItem('currentLicenseName', licenseName);
		
		var userInfo = JSON.parse(localStorage.getItem('currentUser'));		
		var userName = userInfo.token._name;
		this.licenseDescription = userName + "'s GO-Global Trial";
		
		this.dialogDescription = true;
	}
	
	trialRequestDialog() {
		this.dialogDescription = false;
		
		var licenseID = localStorage.getItem('currentLicense');
		var accountInfo = JSON.parse(localStorage.getItem('currentAccount'));		
		var account = accountInfo._id;
		
		this.redeemRequest(licenseID, account, this.licenseDescription);
	}	
	
	closeDialog() {
		this.dialogSelection = false;
		this.dialogDescription = false;
		this.dialogLicenseInvalid = false;
		this.dialogNoValidLicenses = false;
	}
	
	redeemRequest(licenseID: string, accountID: string, description: string) {
		this.loading = true;	
				
		this.portalService.redeemTrialLicense(licenseID, accountID, description)
		.subscribe(
			data => {
				//console.log("redeemTrialLicense:register() got data = %o", data);
				if ( data != 0 ) {					
					this.router.navigate(['/wizard/licenseAgreement']);							
				}
			},
			error => {
				this.alertService.error(error);
				this.loading = false;
			});	
	}
	
	showInvalidLicenseInfo(license: License) {
		this.invalidLicense = license;
		this.dialogLicenseInvalid = true;
	}

	goback() {
		this.router.navigate(['/wizard/showAccounts']);
	}
	
	externalLink(url: string) {
		this.ipc.send('openExternalLink', url);
	}
	
	closeapp() {
		this.portalService.logout();		
		this.ipc.send('closeApp');
	}
}
