import { Component } from '@angular/core';

@Component({
    moduleId: module.id,
    templateUrl: 'unsupported_browser.component.html'
})

export class UnsupportedBrowserComponent {
  constructor() {
  }
}
