/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./wizardPreWelcome.component";
import * as i3 from "@angular/router";
import * as i4 from "../_services/portalservice";
import * as i5 from "../_services/alert.service";
import * as i6 from "../_services/electronIPC.service";
var styles_WizardPreWelcome = [];
var RenderType_WizardPreWelcome = i0.ɵcrt({ encapsulation: 2, styles: styles_WizardPreWelcome, data: {} });
export { RenderType_WizardPreWelcome as RenderType_WizardPreWelcome };
function View_WizardPreWelcome_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "overlay"], ["style", "top: 0px;"]], null, null, null, null, null))], null, null); }
function View_WizardPreWelcome_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "dialogOuter"], ["style", "top: 0px; height: 580px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "dialogInner"], ["style", "cursor: wait;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Loading..."]))], null, null); }
export function View_WizardPreWelcome_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "wizard"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_WizardPreWelcome_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "row vertical-align"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_WizardPreWelcome_2)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.dialogWait; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_WizardPreWelcome_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_WizardPreWelcome_0, RenderType_WizardPreWelcome)), i0.ɵdid(1, 114688, null, 0, i2.WizardPreWelcome, [i3.Router, i3.ActivatedRoute, i4.PortalService, i5.AlertService, i6.ElectronIPC, i0.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WizardPreWelcomeNgFactory = i0.ɵccf("ng-component", i2.WizardPreWelcome, View_WizardPreWelcome_Host_0, {}, {}, []);
export { WizardPreWelcomeNgFactory as WizardPreWelcomeNgFactory };
