import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { AlertService, PortalService, ElectronIPC } from '../_services/index';
import { TextDecoder } from 'text-encoding';

@Component({
    moduleId: module.id,
    templateUrl: 'wizardLicenseAgreement.component.html'
})

export class WizardLicenseAgreement {
    model: any = {};
    loading = false;
	heading: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private portalService: PortalService,
        private alertService: AlertService,
        private ipc: ElectronIPC) {		
			this.heading = localStorage.getItem('currentHeading');
		
		}

    submitRequest() {	
		this.loading = true;	
		this.router.navigate(['/wizard/activate']);
    }
	
	goback() {
		this.router.navigate(['/wizard/showLicenses']);
	}
	
	closeapp() {
		this.portalService.logout();		
		this.ipc.send('closeApp');
	}
}
