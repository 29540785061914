/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../_directives/alert.component.ngfactory";
import * as i3 from "../_directives/alert.component";
import * as i4 from "../_services/alert.service";
import * as i5 from "@angular/forms";
import * as i6 from "./wizardActivate.component";
import * as i7 from "@angular/router";
import * as i8 from "../_services/portalservice";
import * as i9 from "../_services/electronIPC.service";
var styles_WizardActivate = [];
var RenderType_WizardActivate = i0.ɵcrt({ encapsulation: 2, styles: styles_WizardActivate, data: {} });
export { RenderType_WizardActivate as RenderType_WizardActivate };
function View_WizardActivate_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "overlay"]], null, null, null, null, null))], null, null); }
function View_WizardActivate_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "dialogOuter"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "dialogInner"], ["style", "cursor: wait;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Activating GO-Global... (this may take a minute)"]))], null, null); }
export function View_WizardActivate_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 31, "div", [["class", "wizard"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_WizardActivate_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "container"], ["style", "width: 100vw; padding: 5px; border-bottom: 1px solid #4472c4;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "col-xs-12"], ["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "a", [["class", "backbutton navigation"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goback() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\u2190"])), (_l()(), i0.ɵeld(8, 0, null, null, 0, "img", [["src", "logo.png"], ["width", "150"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "alert", [], null, null, null, i2.View_AlertComponent_0, i2.RenderType_AlertComponent)), i0.ɵdid(10, 180224, null, 0, i3.AlertComponent, [i4.AlertService], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 20, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 19, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 16, "div", [["class", "row vertical-align"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 15, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(16, null, ["", ""])), (_l()(), i0.ɵeld(17, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Click Activate to enable GO-Global on this computer."])), (_l()(), i0.ɵeld(19, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 9, "form", [["name", "form"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 22).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 22).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = ((i0.ɵnov(_v, 22).form.valid && _co.submitRequest()) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(21, 16384, null, 0, i5.ɵangular_packages_forms_forms_bg, [], null, null), i0.ɵdid(22, 4210688, [["f", 4]], 0, i5.NgForm, [[8, null], [8, null]], null, { ngSubmit: "ngSubmit" }), i0.ɵprd(2048, null, i5.ControlContainer, null, [i5.NgForm]), i0.ɵdid(24, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), (_l()(), i0.ɵeld(25, 0, null, null, 4, "div", [["class", "form-group text-right buttons"]], null, null, null, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 1, "button", [["class", "btn btn-primary"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Activate"])), (_l()(), i0.ɵeld(28, 0, null, null, 1, "a", [["class", "btn btn-link"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeapp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_WizardActivate_2)), i0.ɵdid(31, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 2, 0, currVal_0); var currVal_10 = _co.dialogWait; _ck(_v, 31, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.heading; _ck(_v, 16, 0, currVal_1); var currVal_2 = i0.ɵnov(_v, 24).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 24).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 24).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 24).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 24).ngClassValid; var currVal_7 = i0.ɵnov(_v, 24).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 24).ngClassPending; _ck(_v, 20, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = _co.loading; _ck(_v, 26, 0, currVal_9); }); }
export function View_WizardActivate_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_WizardActivate_0, RenderType_WizardActivate)), i0.ɵdid(1, 49152, null, 0, i6.WizardActivate, [i7.Router, i7.ActivatedRoute, i8.PortalService, i4.AlertService, i9.ElectronIPC], null, null)], null, null); }
var WizardActivateNgFactory = i0.ɵccf("ng-component", i6.WizardActivate, View_WizardActivate_Host_0, {}, {}, []);
export { WizardActivateNgFactory as WizardActivateNgFactory };
