<!-- main app container -->
<div class="row-wrapper" [style.padding-bottom.px]="bottomPadding">
  <div class="row-container">
    <div *ngIf="!inIFrame" class="first-row">
      <a href="https://www.graphon.com/">
        <img src="Graphon-Logo-White-250px.png" alt="GraphOn" />
        <!-- <div style="font-size: 8px; color: #ffffff; margin-left: 140px;">(dev)</div> -->
      </a>
      <div class="signed_in_as" id="signed_in_as">
        <!-- <div class="title">Signed in as:</div> -->
        <div class="username" id="signed_in_username"></div>
      </div>
      <span class="signin" id="sign_in_section">
      </span>
    </div>
    <alert></alert>
    <router-outlet></router-outlet>
  </div>

</div>

<div *ngIf="footerVisible==true && !inIFrame" id="footer">
  <footer>
    <div class="footer">
      <img src="Graphon-Logo-White-250px.png" alt="GO-Global - Use Windows Application Anywhere from GraphOn" />
    </div>
    <span> © Graphon Corporation 2024 </span>
  </footer>
</div>
