import { Component, Renderer2, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { URLSearchParams } from '@angular/http';

import { AlertService, PortalService } from '../_services/index';

@Component({
    moduleId: module.id,
    templateUrl: 'forgotPassword.component.html'
})

export class ForgotPasswordComponent implements OnInit {
    model: any = {};
    loading: Boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private portalService: PortalService,
        private alertService: AlertService,
        private renderer2: Renderer2) {
            this.route.params.subscribe((params) => {
                this.model.email=params['username'];
                console.log('email initialized to ' + this.model.email);
                console.log('params = %o', this.route.snapshot.params);
            });
            let params = new URLSearchParams(window.location.search);
            this.model.email = params.paramsMap.get('?username');
            console.log('url search params is %o', params);
            console.log('email initialized to ' + this.model.email);
        }

    ngOnInit() {
      let autofocus = this.renderer2.selectRootElement('[autofocus]');
      autofocus.focus();
    }

    submitRequest() {
        this.loading = true;
        console.log("sending password reset: model = %o", this.model);
        this.portalService.sendPasswordReset(this.model.email)
            .subscribe(
                data => {
                    console.log("RegisterComponent:register() got data = %o", data);
                    if ( data ) {
                        this.router.navigate(['/login']);
                        this.alertService.success('An e-mail has been sent to ' + this.model.email +
                                        ' with a link that will allow you to reset your password.', false);
                    }
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
}
