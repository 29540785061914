import { Directive, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[hostname]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: HostnameValidator, multi: true}
  ]
})

export class HostnameValidator implements Validator {
  constructor ( @Attribute('hostname') public hostname: string) {}

  validateHostname(hostname: String) : String {
    let invalidGroups = hostname.match(/[^A-Za-z0-9\-_.]+/g);
    if ( invalidGroups ) {
      return "'" + hostname + "': The following characters are not valid for hostname: " + invalidGroups.join(', ');
    }
    if ( hostname.charAt(0) == "." || hostname.charAt(0) == "_" || hostname.charAt(0) == "-" ) {
      return "'" + hostname + "': Hostname cannot start with '" + hostname.charAt(0) + '"';
    }
    if ( hostname.length == 0 ) {
      return "Hostname is required.";
    }
    return "";
  }

  validate(c: AbstractControl): { [key: string]: any } {
    console.log("validing hostname: " + c.value);
    if ( c.value == null ) return;
    if ( c.value == undefined || c.value == "" ) return { "invalid" : "Hostname is required." };
    let hostnames = String(c.value).replace(/ /g, '').split("+");
    if ( hostnames.length != 1 && hostnames.length != 3 ) {
      return { "invalid": "Must specify one or three hostnames." };
    }
    let ret = "";
    hostnames.forEach((hostname) => {
      let result = this.validateHostname(hostname);
      if ( result != "" ) {
        ret += result + "\n";
      }
    });
    return ret == "" ? null : { "invalid" : ret };
  }
}
