﻿import { Component, OnInit } from '@angular/core';

import { User } from '../../_models/index';
import { UserService } from '../../_services/index';

@Component({
    moduleId: module.id,
    templateUrl: 'home.component.html'
})

export class HomeComponent {
    currentUser: any;

    constructor() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        console.log("%o", this.currentUser);
    }
}
