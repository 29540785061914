import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { Observable, interval } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { AlertService, PortalService, ElectronIPC } from '../_services/index';

@Component({
    moduleId: module.id,
    templateUrl: 'wizardAwaitActivation.component.html'
})

export class WizardAwaitActivation implements OnDestroy {
    model: any = {};
    subscription: any;
    loading = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private portalService: PortalService,
        private alertService: AlertService,
        private ipc: ElectronIPC) {
    }

    ngOnDestroy() {
        console.log('unsubscribe from subscription');
        if ( this.subscription ) this.subscription.unsubscribe();
    }
	
	goback() {
		this.portalService.logout();	
		this.router.navigate(['/wizard/wizardLogin']);
	}
	
	closeapp() {
		this.portalService.logout();		
		this.ipc.send('closeApp');
	}
}
