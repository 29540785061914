import { Component } from '@angular/core';
import { User } from '../_models/index';

@Component({
    moduleId: module.id,
    templateUrl: 'portal.component.html'
})

export class PortalComponent {
    currentUser: any;

    constructor() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }
}
