import { Component, Renderer2, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { URLSearchParams } from '@angular/http';

import { AlertService, PortalService } from '../_services/index';

@Component({
    moduleId: module.id,
    templateUrl: 'changePassword.component.html'
})

export class ChangePasswordComponent implements OnInit {
    model: any = {};
    loading = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private portalService: PortalService,
        private alertService: AlertService,
        private renderer2: Renderer2) {
            this.route.params.subscribe((params) => {
                this.model.key = params['key'];
                console.log("change password param subscriber: updated to " + this.model.key);
            });
            if ( this.model.key == undefined ) {
                let params = new URLSearchParams(window.location.search);
                this.model.key = params.paramsMap.get('?key');
                console.log('change password param search key: updated to ' + this.model.key);
            }
            if (this.model.key == undefined) {
                console.log("no key: %o", this.model);
                this.router.navigate(['/forgotPassword']);
                this.alertService.error('This page has been loaded incorrectly. Please use the link you received in your '
                    +' password reset e-mail to open the correct page, or request a new password reset below.', true);
            }
        }

    ngOnInit() {
      let autofocus = this.renderer2.selectRootElement('[autofocus]');
      autofocus.focus();
    }

    changePassword() {
        this.loading = true;
        console.log("sending password change: model = %o", this.model);
        this.portalService.changePassword(this.model.key, this.model.password)
            .subscribe(
                data => {
                    console.log("RegisterComponent:register() got data = %o", data);
                    if ( data ) {
                        this.router.navigate(['/login']);
                        this.alertService.success('Your password has been changed.', false);
                    }
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
}
