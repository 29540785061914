<div *ngIf="loading == true">
  <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background:#000000; opacity: 0.8; z-index: 9998;">
    <div style="position: absolute; top: 50%; left: 50%; margin: -50px 0 0 -50px; opacity: 1.0;">
      <figure style="overflow: visible;" id="spinner"><div class="spinner"></div></figure>
      <h2 style="color: #ffffff; margin: 0 0 0 35px; font-family: 'Work Sans', Arial, Helvetica, sans-serif;">PROCESSING...</h2>
    </div>
  </div>
</div>

<div class="menu" style="text-align: center;">
  <div class="second-row" style="flex-grow: unset; width: 32vw; margin: auto;">
    <h1 style="color: #0f3c80;">Waiting for account activation.</h1>
    <p>An e-mail from <samp>sales@graphon.com</samp> with the subject "GraphOn Customer Portal Registration Confirmation"
      has been sent to <samp>{{ this.model.username }}</samp>.  Please open the e-mail and follow the instructions it contains to activate your GraphOn account.
    </p>
    <p>If you do not receive the e-mail within 5 minutes:</p>
    <ol>
      <li>Confirm that <samp>{{ this.model.username }}</samp> is the correct address.  If it is not, <a href="{{ gatewayUrl }}/signup">register</a> using the correct address.</li>
      <li>Check your SPAM and/or Bulk mail folders.</li>
      <li>Click the <samp>Re-send activation link</samp> button below.</li>
      <li>If you need help, <a href="https://www.graphon.com/contact/">contact us</a>.</li>
    </ol>
    <button style="margin-top: 10px;" class="btn btn-primary" (click)="resendActivation();">RE-SEND ACTIVATION LINK</button>
  </div>
</div>
