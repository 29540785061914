/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../_directives/alert.component.ngfactory";
import * as i3 from "../_directives/alert.component";
import * as i4 from "../_services/alert.service";
import * as i5 from "./wizardAwaitActivation.component";
import * as i6 from "@angular/router";
import * as i7 from "../_services/portalservice";
import * as i8 from "../_services/electronIPC.service";
var styles_WizardAwaitActivation = [];
var RenderType_WizardAwaitActivation = i0.ɵcrt({ encapsulation: 2, styles: styles_WizardAwaitActivation, data: {} });
export { RenderType_WizardAwaitActivation as RenderType_WizardAwaitActivation };
function View_WizardAwaitActivation_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "overlay"]], null, null, null, null, null))], null, null); }
export function View_WizardAwaitActivation_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 21, "div", [["class", "wizard"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_WizardAwaitActivation_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "container"], ["style", "width: 100vw; padding: 5px; border-bottom: 1px solid #4472c4;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "col-xs-12"], ["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "a", [["class", "backbutton navigation"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goback() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\u2190"])), (_l()(), i0.ɵeld(8, 0, null, null, 0, "img", [["src", "logo.png"], ["width", "150"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "alert", [], null, null, null, i2.View_AlertComponent_0, i2.RenderType_AlertComponent)), i0.ɵdid(10, 180224, null, 0, i3.AlertComponent, [i4.AlertService], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 10, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 8, "div", [["class", "row vertical-align"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 7, "div", [["class", "col-xs-6"], ["style", "min-width: 400px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Waiting for account activation."])), (_l()(), i0.ɵeld(17, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["An e-mail has been sent to you with an activation link. Please open that link to\tactivate your account."])), (_l()(), i0.ɵeld(19, 0, null, null, 2, "div", [["class", "form-group text-right buttons"]], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goback() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Sign in"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_WizardAwaitActivation_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_WizardAwaitActivation_0, RenderType_WizardAwaitActivation)), i0.ɵdid(1, 180224, null, 0, i5.WizardAwaitActivation, [i6.Router, i6.ActivatedRoute, i7.PortalService, i4.AlertService, i8.ElectronIPC], null, null)], null, null); }
var WizardAwaitActivationNgFactory = i0.ɵccf("ng-component", i5.WizardAwaitActivation, View_WizardAwaitActivation_Host_0, {}, {}, []);
export { WizardAwaitActivationNgFactory as WizardAwaitActivationNgFactory };
