﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService, PortalService, ElectronIPC } from '../_services/index';

@Component({
    moduleId: module.id,
    templateUrl: 'wizardLogin.component.html'
})

export class WizardLogin implements OnInit {
    model: any = {};
    loading = false;
    returnUrl: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private portalService: PortalService,
        private alertService: AlertService,
        private ipc: ElectronIPC) {
            this.model.username = "";
            route.params.subscribe((params) => {
                this.model.username = params['username'];
            });
        }

    ngOnInit() {
        // reset login status
        this.portalService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/wizard/showAccounts';
    }

    login() {
        this.loading = true;
        this.portalService.signin(this.model.username, this.model.password)
            .subscribe(
                data => {
                    this.portalService.getGatewayResources()
                        .subscribe(
                            data => {
                                console.log("got resources during signin");
                                this.router.navigate([this.returnUrl]);
                            },
                            error => {
                                this.loading = false;
                                this.alertService.error('Unknown error: ' + error);
                            }
                        );
                },
                error => {
                    this.loading = false;
                    if ( error.toString().includes("User is disabled") ) {
                        this.router.navigate(['/wizard/wizardAwaitActivation'], { queryParams : { 'username' : this.model.username}});
                    } else if ( error.toString().includes("password.not.valid") ) {
                        this.alertService.error('Your password has not been set. Please reset your password before logging in.', true);
                        this.router.navigate(['/wizard/wizardForgotPassword'], { queryParams : { 'username' : this.model.username}});
                    } else if ( error.toString().includes("user.not.found") ) {
                        this.alertService.error('E-mail address not found, please create an account.');
                    } else if ( error.toString().includes("Bad credentials") ) {
                        this.alertService.error('Invalid password, please try again.');
                    } else {
                        this.alertService.error('Unknown error: ' + error);
                    }
                });
    }

    forgotPassword() {
        this.router.navigate(['/wizard/wizardForgotPassword'], { queryParams: {'username' : this.model.username}});
    }
	
	goback() {
		this.portalService.logout();	
		this.router.navigate(['/wizard/prewelcome']);
	}
	
	closeapp() {
		this.portalService.logout();		
		this.ipc.send('closeApp');
	}
}
