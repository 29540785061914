<div class="wizard">	
	<div class="overlay" *ngIf="loading" style="top: 0px;"></div>
	
	<div class="wrapper">
		<div class="container">
			<div class="row vertical-align">
				<div class="col-xs-12">			
				</div>   
			</div>			
			
			<div class="dialogOuter" *ngIf="dialogWait" style="top: 0px; height: 580px;">
				<div class="dialogInner" style="cursor: wait;">
					<br/>
					<h4>Loading...</h4>					
				</div>
			</div>
		</div>
	</div>
</div>
